<template>
  <div class="p-4 md:p-8">
    <h1 class="text-5xl text-gray-800 font-bold mb-2 dark:text-white">
      Drivers
    </h1>
    <grid
      :columns="columns"
      :rows="rows"
      :search="true"
      @click="selectDriver"
      placeholder="Search Drivers"
    />
    <div class="flex justify-end">
      <span
        class="text-gray-600 font-bold cursor-pointer hover:underline dark:text-white"
        @click="exportGrid"
      >
        Export Drivers
      </span>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import { useToast } from "vue-toastification";
import { useRouter } from "vue-router";

export default {
  setup() {
    const store = useStore();
    const toast = useToast();
    const router = useRouter();

    return {
      selectDriver(driverId, event) {
        const driver = store.state.driver.find(({ id }) => id === driverId);
        if (event.ctrlKey || event.metaKey) {
          const route = router.resolve({
            name: "Driver",
            params: { companyId: driver.companyId, driverId: driver.id },
          });
          window.open(route.fullPath, "_blank");
        } else {
          router.push({
            name: "Driver",
            params: { companyId: driver.companyId, driverId: driver.id },
          });
        }
      },
      columns: [
        { name: "id", hidden: true },
        { name: "entityId", hidden: true },
        { name: "Name", id: "name" },
        { name: "DL Number", id: "license" },
        { name: "State", id: "state" },
        { name: "Company", id: "company" },
      ],
      rows: computed(() => {
        return store.state.driver.map((driver) => {
          const company = store.state.company.find(
            ({ id }) => id === driver["companyId"],
          );
          return {
            ...driver,
            company: company ? company.businessLegalName : "",
            name: `${driver.firstName || ""} ${driver.lastName || ""}`,
          };
        });
      }),
      exportGrid() {
        toast("Exporting Drivers");
        store.dispatch("exportGrid", { type: "driver" });
      },
    };
  },
};
</script>
