import { createAuth0 } from "@auth0/auth0-vue";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import { decodeJwt } from "jose";

// Register this with the Vue app on startup
export const auth0 = createAuth0({
  domain: process.env["VUE_APP_AUTH0_DOMAIN"],
  clientId: process.env["VUE_APP_AUTH0_CLIENT_ID"],
  useRefreshTokens: true,
  cacheLocation: "localstorage",
  cookieDomain: process.env["VUE_APP_AUTH0_COOKIE_DOMAIN"],
  authorizationParams: {
    redirect_uri: window.location.origin,
  },
  useFormData: true,
});

const getIdentityTokenSilently = async (refresh = false) => {
  const auth0Tokens = await auth0.getAccessTokenSilently({
    detailedResponse: true,
    authorizationParams: {
      tenant_id: "8941322c-37f5-4a3f-b49a-f9e06a226122", // Hard-coded ASTER tenant id
    },
    cacheMode: refresh ? "off" : "on",
  });
  return auth0Tokens.id_token;
};

export const getIdentityToken = async () => {
  try {
    // Get the token from the cache. Even when cache-mode is 'on', this function
    // may return an expired token.
    const cachedToken = await getIdentityTokenSilently(false);

    // Check if the token is expired
    const now = Math.floor(Date.now() / 1000);
    const expiration = decodeJwt(cachedToken).exp;

    // Token is not expired, return it
    if (expiration > now) {
      return cachedToken;
    }

    // Token is expired, lets try to fetch a new using the refresh token
    return await getIdentityTokenSilently(true);
  } catch (ex) {
    console.log(`[AUTH0] Failed to get a valid identity token: ${ex.message}`);
    return null;
  }
};

export const firebaseSignInWithCustomToken = async () => {
  console.log("[AUTH0] retrieving custom firebase token");
  const auth = getAuth();
  const customTokenResponse = await fetch(
    `${process.env["VUE_APP_FIREBASE_FUNCTION_RAW_PATH"]}/auth-issueCustomToken`,
    {
      method: "POST",
      headers: {
        authorization: `Bearer ${await getIdentityToken()}`,
      },
    },
  );
  const body = await customTokenResponse.json();
  const customToken = body.token;
  console.log("[AUTH0] signing in with custom firebase token");
  const firebaseUser = await signInWithCustomToken(auth, customToken);
  console.log(
    `[AUTH0] successfully signed in with custom token as: ${firebaseUser.user.uid}`,
  );
  return firebaseUser;
};
