<template>
  <div class="p-4 md:p-8">
    <h1 class="text-5xl text-gray-800 font-bold mb-4 dark:text-white">
      Claims
    </h1>
    <div
      :class="{ hidden: claimsGridLoaded }"
      class="bg-white border rounded-md m-1 p-2"
    >
      <div class="bg-gray-100 h-64 animate-pulse" />
    </div>
    <div ref="claimsGridRef" />
  </div>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";
import { useToast } from "vue-toastification";
import { useRouter } from "vue-router";
import { computed, onMounted } from "vue";
import { ellipsisText } from "@/utils";
import { getAccessToken } from "@/firebase";
import {
  initializeShadowDom,
  fetchFrontend,
  mountFrontend,
} from "@getaddify/lula-components";
import moment from "moment";

function getFormMode(type) {
  switch (type) {
    case process.env["VUE_APP_ACCOUNT_TYPE_TRUCKING"]: {
      return "admin_trucks";
    }
    case process.env["VUE_APP_ACCOUNT_TYPE_CAR_RENTAL"]:
    default: {
      return "admin_cars";
    }
  }
}

export default {
  setup() {
    const toast = useToast();
    const store = useStore();
    const router = useRouter();

    const claims = computed(() => store.state.claim);
    const companies = computed(() => store.state.company);

    const claimsGridLoaded = ref(false);
    const claimsGridRef = ref(null);

    onMounted(async () => {
      if (!claimsGridRef.value) {
        return;
      }
      const [gridShadow, gridRoot] = initializeShadowDom(claimsGridRef.value);
      await fetchFrontend(gridShadow, process.env["VUE_APP_CLAIMS_UI_URL"]);
      mountFrontend("claims-grid-admin", gridRoot, {
        environment: process.env["VUE_APP_ENVIRONMENT"],
        launchDarklyApiKey: process.env["VUE_APP_LAUNCHDARKLY_ID"],
        userId: store.state.user?.sub,
        userEmail: store.state.user?.email,
        graphUrl: process.env["VUE_APP_CLAIMS_GRAPH"],
        graphHeaders: {
          Authorization: `Bearer ${await store.dispatch("getIdentityToken")}`,
        },
        mode: getFormMode(store.state.type),
        demo: store.state.testMode,
        onMounted() {
          claimsGridLoaded.value = true;
        },
        selectClaim(evt, claim) {
          if (evt.metaKey) {
            const urlResolve = router.resolve({
              name: "Claim",
              params: {
                tenant: claim.tenant || claim.tenantId,
                id: claim.id,
              },
            });
            open(urlResolve.fullPath, "_blank");
          } else {
            router.push({
              name: "Claim",
              params: {
                tenant: claim.tenant || claim.tenantId,
                id: claim.id,
              },
            });
          }
        },
      });
    });

    return {
      claimsGridRef,
      claimsGridLoaded,
      truckingAccountType: process.env["VUE_APP_ACCOUNT_TYPE_TRUCKING"],
      claims: computed(() => {
        return claims.value.map((claim) => {
          const company = companies.value
            .filter((company) => company.id === claim.companyId)
            .pop();
          function getDriverName(driver) {
            if (driver) {
              return `${driver.firstName || ""} ${driver.lastName || ""}`;
            }
            return "";
          }
          if (
            store.state.type === process.env["VUE_APP_ACCOUNT_TYPE_TRUCKING"]
          ) {
            return {
              ...claim,
              number: claim.id.toUpperCase(),
              companyName: company ? company.businessLegalName : "",
              driverName:
                getDriverName(
                  store.state.driver.find((d) => d.entityId === claim.driver),
                ) || "N/A",
              created: moment.utc(claim.created).local().format("yyyy-MM-DD"),
            };
          }
          if (
            store.state.type === process.env["VUE_APP_ACCOUNT_TYPE_CAR_RENTAL"]
          ) {
            const cd = store.state.driver.find(
              (d) => d.entityId === claim.driver,
            );
            return {
              ...claim,
              number: claim.id.toUpperCase(),
              companyName: company ? company.businessLegalName : "",
              created: moment.utc(claim.created).local().format("yyyy-MM-DD"),
              driverName: cd ? getDriverName(cd) : claim.driverName,
            };
          }
          return {};
        });
      }),
      unresolvedClaims: computed(() => {
        return claims.value
          .map((claim) => {
            const company = companies.value
              .filter((company) => company.id === claim.companyId)
              .pop();
            function getDriverName(driver) {
              if (driver) {
                return `${driver.firstName || ""} ${driver.lastName || ""}`;
              }
              return "";
            }
            if (
              store.state.type === process.env["VUE_APP_ACCOUNT_TYPE_TRUCKING"]
            ) {
              return {
                ...claim,
                number: claim.id.toUpperCase(),
                companyName: company ? company.businessLegalName : "",
                driverName:
                  getDriverName(
                    store.state.driver.find((d) => d.entityId === claim.driver),
                  ) || "N/A",
                created: moment.utc(claim.created).local().format("yyyy-MM-DD"),
              };
            }
            if (
              store.state.type ===
              process.env["VUE_APP_ACCOUNT_TYPE_CAR_RENTAL"]
            ) {
              const cd = store.state.driver.find(
                (d) => d.entityId === claim.driver,
              );
              return {
                ...claim,
                number: claim.id.toUpperCase(),
                companyName: company ? company.businessLegalName : "",
                created: moment.utc(claim.created).local().format("yyyy-MM-DD"),
                driverName: cd ? getDriverName(cd) : claim.driverName,
              };
            }
            return {};
          })
          .filter((claim) => claim.status === "Reported");
      }),
      claimColumns: [
        { name: "id", hidden: true },
        { name: "entityId", hidden: true },
        { name: "Claim ID", id: "number" },
        {
          name: "Company",
          id: "companyName",
          formatter: (d) => ellipsisText(d, 10),
        },
        {
          name: "Driver",
          id: "driverName",
          formatter: (d) => ellipsisText(d, 10),
        },
        { name: "Status", id: "status" },
        { name: "Created", id: "created" },
      ],
      exportGrid() {
        toast("Exporting Claims");
        store.dispatch("exportGrid", { type: "claim" });
      },
      exportUnresolvedGrid() {
        toast("Exporting Claims");
        store.dispatch("exportGrid", { type: "unresolved" });
      },
      selectClaim(claimId, event) {
        if (event.ctrlKey || event.metaKey) {
          const route = router.resolve({
            name: "Claim",
            params: { id: claimId },
          });
          window.open(route.fullPath, "_blank");
        } else {
          router.push({ name: "LegacyClaim", params: { id: claimId } });
        }
      },
    };
  },
};
</script>
