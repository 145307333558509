<template>
  <div :class="{ dark: $store.state.darkMode }">
    <div class="dark:bg-gray-700 min-h-screen">
      <Sidebar v-if="isAuthenticated" />
      <Navbar v-if="isAuthenticated" />
      <main
        class="p-4"
        :class="{
          'w-screen h-screen flex justify-center items-center':
            loading || isAuthLoading || !isAuthenticated,
          'm-auto max-w-screen-xl': isAuthenticated,
        }"
      >
        <loader v-if="loading || isAuthLoading" />
        <router-view v-else-if="isAuthenticated" />
        <Launch v-else />
      </main>
    </div>
  </div>
</template>

<script>
import Navbar from "@/Navbar.vue";
import Launch from "@/Launch.vue";
import Sidebar from "@/Sidebar.vue";
import { useAuth0 } from "@auth0/auth0-vue";
import { watch } from "vue";
import { useStore } from "vuex";
import { firebaseSignInWithCustomToken } from "./auth0";

export default {
  name: "App",
  components: { Navbar, Launch, Sidebar },
  mounted() {
    this.$store.dispatch("loadInitialResources");
  },
  setup() {
    const { isLoading, user } = useAuth0();
    const store = useStore();

    watch([isLoading], async () => {
      try {
        if (isLoading.value) return; // auth0 sdk still loading

        // Try to get a valid identity token
        const token = await store.dispatch("getIdentityToken");
        if (token == null) {
          return;
        }

        store.state.user = user;
        await firebaseSignInWithCustomToken();
        store.dispatch("loadInitialResources");
      } catch (ex) {
        console.log(ex);
        store.commit("setIsAuthenticated", false);
      } finally {
        store.commit("setIsAuthLoading", false);
      }
    });
  },
  computed: {
    loading() {
      return this.$store.state.loading;
    },
    isAuthLoading() {
      return this.$store.state.isAuthLoading;
    },
    isAuthenticated() {
      return this.$store.state.isAuthenticated;
    },
  },
};
</script>
