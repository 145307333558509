<template>
  <div>
    <div
      class="bg-gray-50 border rounded-lg p-4 mt-4"
      :class="{ 'border-lula-dark': editContact }"
    >
      <h3 class="font-bold text-gray-800 text-xl">Contact</h3>
      <hr class="my-2" />
      <div>
        <div class="m-1" v-for="setting in contactSettings" :key="setting.key">
          <label class="font-bold text-gray-800">
            {{ setting.label }}
          </label>
          <br />
          <state-select
            class="border"
            :disabled="!editContact"
            v-model="settings[setting.key]"
            v-if="setting.type === 'state'"
          />
          <input
            :type="setting.type"
            :disabled="!editContact"
            class="border"
            v-model="settings[setting.key]"
            v-else
          />
        </div>
      </div>
    </div>
    <div class="flex justify-between">
      <span />
      <span
        class="font-bold text-gray-800 cursor-pointer hover:underline"
        @click="saveSettings"
        v-if="editContact"
      >
        Save Contact
      </span>
      <span
        class="font-bold text-gray-800 cursor-pointer hover:underline"
        @click="editContact = true"
        v-else
      >
        Edit Contact
      </span>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import { useToast } from "vue-toastification";

const contactSettings = [
  { key: "truckingcontactemail", label: "Email", type: "text" },
  { key: "truckingcontactnaic", label: "NAIC", type: "text" },
  { key: "truckingcontactname", label: "Name", type: "text" },
  { key: "truckingcontactphone", label: "Phone", type: "text" },
];

export default {
  setup() {
    const store = useStore();
    const toast = useToast();

    const settings = ref({});
    const editContact = ref(false);

    onMounted(async () => {
      const system = await store.dispatch("getSystemSettings");
      contactSettings.forEach((pSetting) => {
        const setting = system.find((setting) => {
          return setting.key === pSetting.key;
        });
        if (setting) {
          settings.value[pSetting.key] = setting.value;
        }
      });
    });

    return {
      settings,
      editContact,
      contactSettings,
      async saveSettings() {
        toast("Saving setting.");
        try {
          await Promise.all(
            contactSettings.map((pSetting) => {
              return store.dispatch("saveSystemSetting", {
                key: pSetting.key,
                value: settings.value[pSetting.key],
              });
            }),
          );
          editContact.value = false;
          toast.clear();
          toast("Setting saved.");
        } catch (err) {
          toast.clear();
          toast("Failed to save setting");
        }
      },
    };
  },
};
</script>
