<template>
  <div class="p-4 md:p-8">
    <div class="mb-4">
      <h1 class="text-5xl text-gray-800 font-bold mb-3 dark:text-white">
        Users
      </h1>
      <form
        @submit.prevent="getUserByEmail"
        class="flex rounded-full border"
        :class="{
          'bg-gray-200 hover:bg-gray-200': searching,
          'bg-white hover:bg-white': !searching,
        }"
      >
        <input
          class="w-full text-sm rounded-full py-2 px-4 mr-1 md:text-lg"
          :class="{
            'bg-gray-200 hover:bg-gray-200': searching,
            'bg-white hover:bg-white': !searching,
          }"
          placeholder="Search by email address"
          required
          :disabled="searching"
          v-model="searchValue"
        />
        <button
          type="submit"
          class="min-w-max text-sm bg-lula-gradient font-bold text-white cursor-default md:text-lg"
          disabled
          v-if="searching"
        >
          Searching...
        </button>
        <button
          type="submit"
          class="min-w-max text-sm bg-lula-gradient hover:bg-lula-gradient-alt font-bold text-white md:text-lg"
          v-else
        >
          Search Users
        </button>
      </form>
    </div>
    <div v-if="searched && !searchResults">
      <span class="text-gray-500 text-xl px-3"> No Users Found </span>
    </div>
    <div class="mb-4" v-else-if="searchResults">
      <div
        class="bg-white rounded-xl border text-sm cursor-pointer mb-2 hover:bg-gray-200 md:text-lg"
        @click="
          $router.push({
            name: 'UserDetails',
            params: {
              emailAddress: searchResults.emailAddress,
            },
          })
        "
      >
        <div class="flex justify-between items-start p-4">
          <div>
            <span class="font-bold text-gray-800">
              {{ searchResults.emailAddress }}
            </span>
            <br />
            <span class="text-gray-600">
              {{ searchResults.providers }}
            </span>
            <br />
            <span class="text-gray-600">
              {{ searchResults.createdAt }}
            </span>
          </div>
          <div>
            <span
              class="bg-lula-alt rounded-full text-white font-bold px-4 py-1"
            >
              {{ searchResults.status }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="mb-4 p-1">
      <h2 class="text-3xl text-gray-800 font-bold mb-2 dark:text-white">
        New Users
      </h2>
      <grid
        :columns="columns"
        :rows="recentUserIdentities"
        @click="selectUserIdentity"
        v-if="recentUserIdentities.length > 0"
      />
      <div
        class="text-lg rounded-lg bg-white text-gray-600 text-center p-4 mt-4"
        v-else
      >
        No New Users
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import { useToast } from "vue-toastification";
import { useRouter } from "vue-router";
import moment from "moment";

export default {
  setup() {
    // Vue setup
    const store = useStore();
    const router = useRouter();
    const toast = useToast();

    // User search bar
    const searched = ref(false);
    const searching = ref(false);
    const searchValue = ref("");
    const searchResults = ref(null);

    watch(searchValue, () => {
      if (!searchValue.value) {
        searchResults.value = null;
        searched.value = false;
      }
    });

    const getUserByEmail = async () => {
      try {
        searching.value = true;
        searchResults.value = await store.dispatch("getUserByEmail", {
          email: searchValue.value,
        });
        searched.value = true;
      } catch (ex) {
        console.log(ex);
        searchValue.value = null;
        searched.value = false;
        toast.clear();
        toast("User search failed.");
      } finally {
        searching.value = false;
      }
    };

    // Recent users table
    const recentUserIdentities = ref([]);
    const columns = [
      { id: "emailAddress", name: "Email" },
      {
        id: "provider",
        name: "Provider",
        formatter: (d) => `${d.type}:${d.name}`,
      },
      {
        id: "verified",
        name: "Status",
        formatter: (d) => (d ? "✅ Verified" : "❌ Unverified"),
      },
      {
        id: "createdAt",
        name: "Created",
        formatter: (d) => moment(d).fromNow(),
      },
    ];
    const selectUserIdentity = (emailAddress, event) => {
      if (event.ctrlKey || event.metaKey) {
        const route = router.resolve({
          name: "UserDetails",
          params: { emailAddress },
        });
        window.open(route.fullPath, "_blank");
      } else {
        router.push({ name: "UserDetails", params: { emailAddress } });
      }
    };

    onMounted(async () => {
      try {
        recentUserIdentities.value = await store.dispatch(
          "listRecentCustomerUserIdentities",
          { limit: 100 },
        );
      } catch (ex) {
        toast.clear();
        toast("Failed to get recent user identities.");
        throw ex;
      }
    });

    return {
      // User search bar
      searched,
      searching,
      getUserByEmail,
      searchValue,
      searchResults: computed(() => {
        if (!searchResults.value) {
          return null;
        }
        return {
          ...searchResults.value,
          createdAt: `Created ${moment(searchResults.value.createdAt).fromNow()}`,
          status: searchResults.value.verified ? "Verified" : "Unverified",
          providers:
            "Registered via " +
            searchResults.value.identities
              .map((i) => `'${i.provider.type}:${i.provider.name}'`)
              .join(", "),
        };
      }),
      // Recent user identities table
      recentUserIdentities: recentUserIdentities,
      selectUserIdentity: selectUserIdentity,
      columns,
    };
  },
};
</script>
