import { createRouter, createWebHistory } from "vue-router";

import CompanyGrid from "@/grids/CompanyGrid.vue";
import VehicleGrid from "@/grids/VehicleGrid.vue";
import DriverGrid from "@/grids/DriverGrid.vue";
import ClaimGrid from "@/grids/ClaimGrid.vue";
import UserGrid from "@/grids/UserGrid.vue";
import UserDetail from "@/detail/UserDetail.vue";
import CompanyDetail from "@/detail/CompanyDetail.vue";
import VehicleDetail from "@/detail/VehicleDetail.vue";
import DriverDetail from "@/detail/DriverDetail.vue";
import ClaimDetail from "@/detail/ClaimDetail.vue";
import LegacyClaimDetail from "@/detail/LegacyClaimDetail.vue";
import NewCompanyDetail from "@/detail/NewCompanyDetail.vue";
import NewClaimDetail from "@/detail/NewClaimDetail.vue";
import InsuranceDocumentDetail from "@/detail/InsuranceDocumentDetail.vue";
import EpisodeDetail from "@/detail/EpisodeDetail.vue";
import PolicyDetail from "@/detail/PolicyDetail.vue";
import SystemSettings from "@/SystemSettings.vue";
import UnderwritingCheck from "@/UnderwritingCheck.vue";

const routes = [
  {
    path: "/",
    redirect: () => {
      return { path: "/company" };
    },
  },
  {
    name: "SystemSettings",
    path: "/systemsettings",
    component: SystemSettings,
  },
  {
    name: "Policy",
    path: "/policies/:policyId",
    component: PolicyDetail,
  },
  {
    name: "Companies",
    path: "/company",
    component: { template: "<router-view></router-view>" },
    children: [
      {
        path: "",
        component: CompanyGrid,
        meta: { nav: "Companies" },
      },
      {
        name: "NewCompany",
        path: "new",
        component: NewCompanyDetail,
        meta: { nav: "Companies" },
      },
      {
        name: "Company",
        path: ":id",
        component: CompanyDetail,
        meta: { nav: "Companies" },
      },
      {
        name: "Vehicle",
        path: ":companyId/vehicle/:vehicleId",
        component: VehicleDetail,
        meta: { nav: "Vehicles" },
      },
      {
        name: "Driver",
        path: ":companyId/driver/:driverId",
        component: DriverDetail,
        meta: { nav: "Drivers" },
      },
      {
        name: "InsuranceDocumentDetail",
        path: ":companyId/insurance/:documentId",
        component: InsuranceDocumentDetail,
        meta: { nav: "Companies" },
      },
      {
        name: "EpisodeDetail",
        path: ":companyId/episode/:episodeId",
        component: EpisodeDetail,
        meta: { nav: "Episodes" },
      },
      {
        name: "Claim",
        path: ":tenant/claims/:id",
        component: ClaimDetail,
        meta: { nav: "Claims" },
      },
    ],
  },
  {
    name: "Vehicles",
    path: "/vehicle",
    component: { template: "<router-view></router-view>" },
    children: [
      {
        path: "",
        component: VehicleGrid,
      },
    ],
    meta: { nav: "Vehicles" },
  },
  {
    name: "Drivers",
    path: "/driver",
    component: { template: "<router-view></router-view>" },
    children: [
      {
        path: "",
        component: DriverGrid,
      },
    ],
    meta: { nav: "Drivers" },
  },
  {
    name: "Claims",
    path: "/claims",
    component: { template: "<router-view></router-view>" },
    children: [
      {
        path: "",
        component: ClaimGrid,
        meta: { nav: "Claims" },
      },
      {
        name: "NewClaim",
        path: "new",
        component: NewClaimDetail,
        meta: { nav: "Claim" },
      },
      {
        name: "LegacyClaim",
        path: ":id",
        component: LegacyClaimDetail,
        meta: { nav: "Claims" },
      },
    ],
  },
  {
    name: "Underwriting",
    path: "/underwriting",
    component: UnderwritingCheck,
    meta: { nav: "Underwriting" },
  },
  {
    name: "Users",
    path: "/users",
    component: { template: "<router-view></router-view>" },
    children: [
      {
        name: "Users",
        path: "",
        component: UserGrid,
      },
      {
        name: "UserDetails",
        path: ":emailAddress",
        component: UserDetail,
      },
    ],
    meta: { nav: "Users" },
  },
];

export default createRouter({
  history: createWebHistory(),
  routes,
});
