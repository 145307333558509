import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  gql,
} from "@apollo/client/core";
import store from "./store";

async function getClaimsClient() {
  const token = await store.dispatch("getIdentityToken");
  return new ApolloClient({
    cache: new InMemoryCache(),
    link: createHttpLink({
      uri: process.env["VUE_APP_CLAIMS_GRAPH"],
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  });
}

async function getLossRunReport(client, tenantId) {
  const result = await client.query({
    fetchPolicy: "network-only",
    query: gql`
      query GetLossRuns($tenantId: ID!) {
        getLossRuns(tenantId: $tenantId) {
          url
        }
      }
    `,
    variables: {
      tenantId: tenantId,
    },
  });
  return result.data.getLossRuns || null;
}

export { getClaimsClient, getLossRunReport };
