<template>
  <header
    :class="{ 'border-t-8': testMode }"
    class="w-full bg-white shadow-2xl flex justify-center border-lula dark:bg-gray-700"
  >
    <div class="max-w-screen-lg p-4 w-full md:flex md:p-0 md:px-4">
      <div class="w-full flex justify-between">
        <router-link to="/" class="flex items-center justify-center">
          <img src="@/assets/AddifyLogo_Transparent.png" alt="GetAddify Logo" />
        </router-link>
        <div
          class="flex flex-col items-center justify-center w-10 h-10 rounded-lg bg-lula-light cursor-pointer border border-transparent hover:border-lula transition hover:border-lula md:hidden"
          :class="{ 'border-lula': open }"
          @click="open = !open"
        >
          <div
            class="h-0.5 my-0.5 bg-lula rounded-2xl transform transition-all"
            :class="{
              'rotate-45 translate-y-0.5 w-6/12': open,
              'w-9/12': !open,
            }"
          />
          <div
            class="h-0.5 w-9/12 my-0.5 bg-lula rounded-2xl"
            :class="{ hidden: open }"
          />
          <div
            class="h-0.5 my-0.5 bg-lula rounded-2xl transform transition-all"
            :class="{
              '-rotate-45 -translate-y-1 w-6/12': open,
              'w-9/12': !open,
            }"
          />
        </div>
      </div>
      <div class="transform md:block" :class="{ hidden: !open }">
        <ul class="text-gray-500 md:flex">
          <li
            v-for="option in routes"
            class="relative flex select-none cursor-pointer dark:text-white hover:text-lula transition md:text-center dark:hover:text-lula"
            :key="option.path"
          >
            <div v-if="option.name == 'Underwriting'">
              <div class="w-full h-full md:p-6 md:m-0">
                <router-link
                  class="w-full h-full md:p-6 md:m-0"
                  @click="
                    $store.dispatch('logEvent', {
                      name: 'select_navigation_option',
                      data: { option: option.name },
                    })
                  "
                  :to="option.path"
                >
                  {{ option.name }}
                </router-link>
                <div
                  v-if="isCurrentPath(option.name)"
                  className="hidden w-2/3 h-1 bg-lula-gradient absolute bottom-0 left-0 right-0 mx-auto rounded-tl-lg rounded-tr-lg md:block"
                />
              </div>
            </div>
            <div v-else class="w-full h-full md:p-6 md:m-0">
              <router-link
                class="w-full h-full md:p-6 md:m-0"
                @click="
                  $store.dispatch('logEvent', {
                    name: 'select_navigation_option',
                    data: { option: option.name },
                  })
                "
                :to="option.path"
              >
                {{ option.name }}
              </router-link>
              <div
                v-if="isCurrentPath(option.name)"
                className="hidden w-2/3 h-1 bg-lula-gradient absolute bottom-0 left-0 right-0 mx-auto rounded-tl-lg rounded-tr-lg md:block"
              />
            </div>
          </li>
          <li
            class="relative select-none cursor-pointer hover:text-lula transition md:text-center md:hidden dark:text-white"
          >
            <a @click="logout">Logout</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="flex justify-center items-center px-0 md:px-4">
      <div
        @click="openSidebar"
        class="hidden rounded-full bg-lula-alt h-12 w-12 justify-center dark:bg-lula-dark items-center self-center text-white select-none cursor-pointer text-lg mx-4 border-2 border-transparent hover:border-lula sm:m-0 md:flex"
      >
        <span>
          {{ (user.email || "").charAt(0).toUpperCase() }}
        </span>
      </div>
    </div>
  </header>
</template>

<script>
import { useStore } from "vuex";
import { ref, computed, onMounted } from "vue";
import { useAuth0 } from "@auth0/auth0-vue";

export default {
  setup() {
    const { logout } = useAuth0();
    const store = useStore();
    onMounted(async () => {
      if (store.state.launchDarkly) {
        await store.state.launchDarkly.waitForInitialization();
      }
    });
    return {
      open: ref(false),
      openSidebar() {
        window.scrollTo(0, 0);
        document.querySelector("body").style.overflowY = "hidden";
        store.commit("setState", { key: "sidebar", value: true });
      },
      routes: computed(() => [
        {
          name: "Companies",
          path: "/company",
        },
        {
          name: "Vehicles",
          path: "/vehicle",
        },
        {
          name: "Claims",
          path: "/claims",
        },
        {
          name: "Underwriting",
          path: "/underwriting",
        },
        {
          name: "Users",
          path: "/users",
        },
      ]),
      logout: () => {
        store.dispatch("setPaddocksType", { type: null });
        logout({ logoutParams: { returnTo: window.location.origin } });
      },
    };
  },
  computed: {
    testMode() {
      return this.$store.state.testMode;
    },
    user() {
      return this.$store.state.user;
    },
  },
  methods: {
    isCurrentPath(name) {
      return this.$router.currentRoute.value.meta.nav?.includes(name);
    },
  },
};
</script>
