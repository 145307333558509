<template>
  <div class="m-auto bg-white rounded-2xl w-full p-4 mt-4 md:w-2/3">
    <div class="flex justify-between">
      <h1 class="text-2xl font-bold text-gray-800">Add a new company</h1>
      <div
        class="hidden items-center justify-center bg-lula-alt rounded-full px-4 sm:flex"
      >
        <span class="uppercase font-bold text-white">
          {{ $store.state.type }}
        </span>
      </div>
    </div>
    <form
      class="grid grid-cols-1 md:grid-cols-2 mt-2"
      @submit.prevent="saveCompany"
    >
      <div class="m-1" v-for="detail in details" :key="detail.key">
        <label class="font-bold text-gray-800 mb-1">{{ detail.label }}</label>
        <br />
        <input :type="detail.type" v-model="company[detail.key]" required />
      </div>
      <div class="flex justify-end col-span-full mt-2">
        <button
          type="button"
          class="mx-1 bg-gray-100 hover:bg-gray-200"
          @click="$router.go(-1)"
        >
          Cancel
        </button>
        <button
          type="submit"
          class="mx-1 text-white bg-lula-gradient hover:bg-lula-gradient-alt"
        >
          Save
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";

function getFieldsForAccountType(accountType) {
  const defaultFields = [
    "salesforceId",
    "stripeId",
    "businessLegalName",
    "billingEmail",
    "contactFirstName",
    "contactLastName",
    "contactEmail",
  ];
  switch (accountType) {
    case process.env["VUE_APP_ACCOUNT_TYPE_CAR_RENTAL"]: {
      return [...defaultFields];
    }
    case process.env["VUE_APP_ACCOUNT_TYPE_TRUCKING"]: {
      return [...defaultFields];
    }
    default:
      return [];
  }
}

export default {
  setup() {
    const store = useStore();
    const toast = useToast();
    const router = useRouter();
    const company = ref({});

    return {
      company,
      details: computed(() => {
        return [
          { label: "Salesforce ID", key: "salesforceId", type: "text" },
          { label: "Stripe ID", key: "stripeId", type: "text" },
          { label: "Company Name", key: "businessLegalName", type: "text" },
          { label: "Billing Email", key: "billingEmail", type: "text" },
          {
            label: "Contact First Name",
            key: "contactFirstName",
            type: "text",
          },
          { label: "Contact Last Name", key: "contactLastName", type: "text" },
          { label: "Contact Email", key: "contactEmail", type: "text" },
        ].filter(({ key }) =>
          getFieldsForAccountType(store.state.type).includes(key),
        );
      }),
      async saveCompany() {
        toast("Creating company...");
        try {
          const customer = await store.dispatch(
            "getStripeCustomer",
            company.value.stripeId,
          );
          if (customer.success) {
            if (
              customer.data &&
              customer.data.email === company.value.billingEmail
            ) {
              const save = await store.dispatch("createCompany", company.value);
              await store.dispatch("provisionCustomer", {
                customerId: save.entityId,
                ownerEmailAddress: save.contactEmail,
              });
              router.push({ name: "Company", params: { id: save.id } });
            } else {
              toast("Billing email does not match Stripe account.");
            }
          } else {
            toast(customer.message);
          }
        } catch (err) {
          toast("Failed to create company.");
        }
      },
    };
  },
};
</script>
