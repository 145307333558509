<template>
  <div>
    <div
      class="bg-gray-50 border rounded-lg p-4 mt-4"
      :class="{ 'border-lula-dark': editInsurer }"
    >
      <h3 class="font-bold text-gray-800 text-xl">Insurer</h3>
      <hr class="my-2" />
      <div>
        <div class="m-1" v-for="setting in insurerSettings" :key="setting.key">
          <label class="font-bold text-gray-800">
            {{ setting.label }}
          </label>
          <br />
          <state-select
            class="border"
            :disabled="!editInsurer"
            v-model="settings[setting.key]"
            v-if="setting.type === 'state'"
          />
          <input
            :type="setting.type"
            :disabled="!editInsurer"
            class="border"
            v-model="settings[setting.key]"
            v-else
          />
        </div>
      </div>
    </div>
    <div class="flex justify-between">
      <span />
      <span
        class="font-bold text-gray-800 cursor-pointer hover:underline"
        @click="saveSettings"
        v-if="editInsurer"
      >
        Save Insurer
      </span>
      <span
        class="font-bold text-gray-800 cursor-pointer hover:underline"
        @click="editInsurer = true"
        v-else
      >
        Edit Insurer
      </span>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import { useToast } from "vue-toastification";

const insurerSettings = [
  { key: "truckinginsurername", label: "Name", type: "text" },
  { key: "truckinginsurernaic", label: "NAIC", type: "text" },
];

export default {
  setup() {
    const store = useStore();
    const toast = useToast();

    const settings = ref({});
    const editInsurer = ref(false);

    onMounted(async () => {
      const system = await store.dispatch("getSystemSettings");
      insurerSettings.forEach((pSetting) => {
        const setting = system.find((setting) => {
          return setting.key === pSetting.key;
        });
        if (setting) {
          settings.value[pSetting.key] = setting.value;
        }
      });
    });

    return {
      settings,
      editInsurer,
      insurerSettings,
      async saveSettings() {
        toast("Saving setting.");
        try {
          await Promise.all(
            insurerSettings.map((pSetting) => {
              return store.dispatch("saveSystemSetting", {
                key: pSetting.key,
                value: settings.value[pSetting.key],
              });
            }),
          );
          editInsurer.value = false;
          toast.clear();
          toast("Setting saved.");
        } catch (err) {
          toast.clear();
          toast("Failed to save setting");
        }
      },
    };
  },
};
</script>
