import {
  Configuration,
  UsersApi,
  CustomersApi,
  CustomerUserAccountsApi,
  PermissionsApi,
} from "@getaddify/tenant-account-management";
import store from "./store";

const config = new Configuration({
  basePath: process.env["VUE_APP_ACCOUNT_MANAGEMENT_API_BASE_PATH"],
  accessToken: async () => await store.dispatch("getIdentityToken"),
});

export const listRecentCustomerUserIdentities = async (limit = 100) => {
  const api = new UsersApi(config);
  const response = await api.listUserIdentities({
    limit,
    systemType: "customer",
  });
  return response.items;
};

export const getUserByEmail = async (emailAddress) => {
  const api = new UsersApi(config);
  const response = await api.listUserIdentities({
    emailAddress: emailAddress?.trim()?.toLocaleLowerCase(),
    systemType: "customer",
  });

  const identities = response.items;
  if (identities.length === 0) return null;
  const oldestIdentity = identities.reduce((prev, current) =>
    prev.createdAt < current.createdAt ? prev : current,
  );

  return {
    id: `${oldestIdentity.provider.name}|${oldestIdentity.id}`,
    emailAddress: oldestIdentity.emailAddress,
    createdAt: oldestIdentity.createdAt,
    identities: identities,
    verified: identities.reduce(
      (prev, current) => current.verified || prev.verified,
      false,
    ),
    modifiedAt: identities.reduce((prev, current) =>
      prev.modifiedAt > current.modifiedAt ? prev : current,
    ),
  };
};

export const sendCustomerUserIdentityVerificationEmail = async (
  emailAddress,
) => {
  const api = new UsersApi(config);
  await api.sendUserIdentityVerificationEmail({
    // bug in open api client gen library - reused type
    // must be sendUserIdentityPasswordResetEmailRequest
    sendUserIdentityPasswordResetEmailRequest: {
      emailAddress,
      systemType: "customer",
    },
  });
};

export const sendCustomerUserIdentityPasswordResetEmail = async (
  emailAddress,
) => {
  const api = new UsersApi(config);
  await api.sendUserIdentityPasswordResetEmail({
    sendUserIdentityPasswordResetEmailRequest: {
      emailAddress,
      systemType: "customer",
    },
  });
};

export const getUserComments = async (emailAddress) => {
  const api = new UsersApi(config);
  return api.findLatestUserComments({ emailAddress });
};

export const addUserComments = async (emailAddress, comments) => {
  const api = new UsersApi(config);
  await api.addUserComments({
    addUserCommentsRequest: {
      emailAddress,
      comments,
    },
  });
};

export const listPermissions = async () => {
  const api = new PermissionsApi(config);
  return await api.listPermissions();
};

export const provisionCustomer = async (customerId, ownerEmailAddress) => {
  const api = new CustomersApi(config);
  return api.provisionCustomer({
    provisionCustomerRequest: {
      customerId,
      ownerEmailAddress,
    },
  });
};

export const getCustomerUserAccountsByEmail = async (emailAddress) => {
  const api = new CustomerUserAccountsApi(config);
  const accounts = await api.listCustomerUserAccounts({
    emailAddress,
    limit: 100,
  });
  return accounts.items;
};

export const getCustomerUserAccountsByCustomerId = async (customerId) => {
  const api = new CustomerUserAccountsApi(config);
  const accounts = await api.listCustomerUserAccounts({
    customerId,
    limit: 100,
  });
  return accounts.items;
};

export const createCustomerUserAccount = async ({
  customerId,
  emailAddress,
  accessLevel,
}) => {
  const api = new CustomerUserAccountsApi(config);
  return api.createCustomerUserAccount({
    createCustomerUserAccountRequest: {
      customerId,
      emailAddress,
      accessLevel,
    },
  });
};

export const deleteCustomerUserAccount = async (customerId, emailAddress) => {
  const api = new CustomerUserAccountsApi(config);
  await api.deleteCustomerUserAccount({ customerId, emailAddress });
};

export const changeCustomerUserAccountAccessLevel = async (
  customerId,
  emailAddress,
  accessLevel,
) => {
  const api = new CustomerUserAccountsApi(config);
  await api.changeCustomerUserAccountAccessLevel({
    customerId,
    emailAddress,
    changeCustomerUserAccountAccessLevelRequest: { accessLevel },
  });
};

export const listCustomerUserAccountsChangelog = async (emailAddress) => {
  const api = new CustomerUserAccountsApi(config);
  const changelog = await api.listCustomerUserAccountsChangelog({
    emailAddress,
    limit: 100,
  });
  return changelog.items;
};
