<template>
  <div class="p-4 md:p-8">
    <h1
      class="text-4xl text-gray-800 font-bold mb-8 md:mb-16 md:text-5xl dark:text-white"
    >
      System Settings
    </h1>
    <div class="mb-4">
      <h2 class="text-4xl text-gray-800 font-bold dark:text-white">
        Car Rental
      </h2>
      <hr class="my-4" />
      <div class="bg-white border p-4 rounded-lg">
        <SystemSettingInput
          type="text"
          edit="JSON"
          label="Adjusters JSON"
          property="carsadjusters"
        />
        <SystemSettingInput
          type="text"
          edit="JSON"
          label="Adjuster Codes JSON"
          property="carsadjustercodes"
        />
        <SystemSettingInput
          type="text"
          edit="Link"
          label="Support Link"
          property="carsupportlink"
        />
        <SystemSettingInput
          type="textarea"
          edit="Content"
          label="Vehicle Ack Message"
          property="carsvehicleacknote"
        />
        <SystemSettingInput
          type="textarea"
          edit="Content"
          label="Claims Next Steps"
          property="carsclaimsteps"
        />
        <CarPolicies />
      </div>
    </div>
    <div class="mb-4">
      <h2 class="text-4xl text-gray-800 font-bold dark:text-white">Trucking</h2>
      <hr class="my-4" />
      <div class="bg-white border p-4 rounded-lg">
        <SystemSettingInput
          type="text"
          edit="JSON"
          label="Adjusters JSON"
          property="truckingadjusters"
        />
        <SystemSettingInput
          type="text"
          edit="Link"
          label="Support Link"
          property="truckingsupportlink"
        />
        <SystemSettingInput
          type="text"
          edit="Link"
          label="Onboarding Video"
          property="truckingonboardingvideo"
        />
        <SystemSettingInput
          type="textarea"
          edit="Content"
          label="Onboarding Message"
          property="truckingonboardingmessage"
        />
        <SystemSettingInput
          type="textarea"
          edit="Content"
          label="Claims Next Steps"
          property="truckingclaimsteps"
        />
        <TruckingProducer />
        <TruckingContact />
        <TruckingInsurer />
      </div>
    </div>
  </div>
</template>

<script>
// Components
import SystemSettingInput from "@/setting/SystemSettingInput.vue";
import TruckingProducer from "@/setting/TruckingProducer.vue";
import TruckingContact from "@/setting/TruckingContact.vue";
import TruckingInsurer from "@/setting/TruckingInsurer.vue";
import CarPolicies from "@/setting/CarPolicies.vue";

export default {
  components: {
    SystemSettingInput,
    TruckingProducer,
    TruckingContact,
    TruckingInsurer,
    CarPolicies,
  },
};
</script>
