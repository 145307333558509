<template>
  <div>
    <div
      class="bg-gray-50 border rounded-lg p-4 mt-4"
      :class="{ 'border-lula-dark': editPolicy.edit }"
    >
      <h3 class="font-bold text-xl text-gray-800">Coverages</h3>
      <hr class="my-2" />
      <div>
        <form
          @submit.prevent="addPolicy"
          class="flex flex-col items-center md:flex-row"
          v-if="editPolicy.edit"
        >
          <div class="w-full m-1">
            <input
              type="text"
              placeholder="Coverage Number"
              class="bg-white border"
              v-model="editPolicy.number"
              required
            />
          </div>
          <div class="w-full m-1">
            <input
              type="text"
              placeholder="Coverage Name"
              class="bg-white border"
              v-model="editPolicy.name"
              required
            />
          </div>
          <div class="w-full m-1 md:w-min">
            <button
              type="submit"
              class="w-full rounded-none py-1 bg-white text-gray-600 rounded-lg whitespace-nowrap border hover:text-gray-800 md:w-max"
            >
              Add Policy
            </button>
          </div>
        </form>
      </div>
      <div v-if="policies.length > 0">
        <div
          class="border bg-white rounded-md px-4 py-2 m-1 cursor-pointer text-sm flex justify-between items-start hover:bg-gray-100 sm:text-base"
          @click="selectPolicy(policy)"
          v-for="policy in policies"
          :key="policy.policyNumber"
        >
          <div>
            <span class="font-bold text-gray-800">
              {{ policy.name }}
            </span>
            <br />
            <span class="text-gray-600">
              {{ policy.policyNumber }}
            </span>
          </div>
          <div
            class="uppercase font-bold text-white bg-lula-alt rounded-full px-4"
          >
            {{ policy.broker }}
          </div>
        </div>
      </div>
      <div
        class="border bg-white m-1 rounded text-gray-600 text-center p-4"
        v-else
      >
        No Policies
      </div>
    </div>
    <div class="flex justify-between">
      <span />
      <span
        class="font-bold text-gray-800 cursor-pointer hover:underline"
        @click="savePolicies"
        v-if="editPolicy.edit"
      >
        Save policies
      </span>
      <span
        class="font-bold text-gray-800 cursor-pointer hover:underline"
        @click="editPolicy.edit = true"
        v-else
      >
        Edit policies
      </span>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";

export default {
  setup() {
    const store = useStore();
    const router = useRouter();
    const toast = useToast();

    const policies = ref([]);
    const editPolicy = ref({
      edit: false,
      name: "",
      number: "",
    });

    onMounted(async () => {
      policies.value = await store.dispatch("getSystemPolicies");
    });

    return {
      policies: computed(() => {
        return policies.value.filter((p) => !p.account);
      }),
      editPolicy,
      addPolicy() {
        if (
          policies.value.find((p) => p.policyNumber === editPolicy.value.number)
        ) {
          alert("Coverage already exists.");
          return;
        }
        if (
          confirm(
            "Are you sure you want to add this coverage? This cannot be undone.",
          )
        ) {
          policies.value = [
            {
              name: editPolicy.value.name,
              policyNumber: editPolicy.value.number,
            },
            ...policies.value,
          ];
          editPolicy.value = {
            ...editPolicy.value,
            name: "",
            number: "",
          };
        }
      },
      selectPolicy(policy) {
        router.push({
          name: "Policy",
          params: { policyId: policy.id },
        });
      },
      async savePolicies() {
        toast("Save coverages.");
        try {
          const system = await store.dispatch("getSystemPolicies");
          const filtered = policies.value.filter((p) => {
            const systemNumbers = system.map((p) => p.policyNumber);
            return !systemNumbers.includes(p.policyNumber);
          });
          await Promise.all(
            filtered.map((p) => {
              return store.dispatch("saveSystemPolicy", p);
            }),
          );
          policies.value = await store.dispatch("getSystemPolicies");
          editPolicy.value.edit = false;
          toast.clear();
          toast("Coverages saved.");
        } catch (err) {
          toast.clear();
          toast("Failed to save coverages.");
        }
      },
    };
  },
};
</script>
