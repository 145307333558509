<template>
  <div class="p-4 md:p-8">
    <!-- User Details Card -->
    <div class="w-full bg-white rounded-lg mb-2" v-if="userDetails">
      <div class="flex flex-col justify-between items-start p-4 md:flex-row">
        <div>
          <span class="font-bold text-base text-2xl text-gray-800">
            {{ userDetails.emailAddress }}
          </span>
          <br />
          <span class="text-gray-600" v-if="userDetails.providers">
            {{ userDetails.providers }}
          </span>
          <br />
          <span class="text-gray-600">
            {{ userDetails.createdAt }}
          </span>
        </div>
        <div>
          <span
            class="bg-lula-alt rounded-full text-white font-bold text-xl px-4 py-1"
          >
            {{ userDetails.status }}
          </span>
        </div>
      </div>
      <div
        class="flex flex-col justify-start items-center p-4 gap-2 md:flex-row"
      >
        <button
          :class="{
            'bg-gray-100 text-gray cursor-default':
              !userDetails.exists || !userDetails.hasPassword,
            'bg-lula-gradient hover:bg-lula-gradient-alt text-white':
              userDetails.exists && userDetails.hasPassword,
          }"
          class="my-1 md:mr-1 md:my-0"
          :disabled="!userDetails.exists || !userDetails.hasPassword"
          @click="sendUserPasswordResetEmail"
        >
          Send Password Reset
        </button>
        <button
          :class="{
            'bg-gray-100 text-gray cursor-default':
              !userDetails.exists || userDetails.verified,
            'bg-lula-gradient hover:bg-lula-gradient-alt text-white':
              userDetails.exists && !userDetails.verified,
          }"
          class="my-1 md:mr-1 md:my-0"
          :disabled="userDetails.verified || !userDetails.exists"
          @click="sendUserVerificationEmail"
        >
          Send Verification
        </button>
      </div>
    </div>

    <!-- Comments Card -->
    <div class="p-4 bg-white rounded-lg mt-4 mb-4">
      <h2 class="font-bold text-gray-800 text-2xl mb-2">Comments</h2>
      <textarea
        rows="5"
        :disabled="!editingComments"
        v-model="commentsDraftRef"
        class="rounded-lg mb-2"
      />
      <div class="flex flex-col items-center justify-start md:flex-row">
        <button
          class="bg-lula-gradient hover:bg-lula-gradient-alt text-white my-1 md:mr-1 md:my-0"
          @click="onCommentsEditClicked()"
          v-if="!editingComments"
        >
          Edit Comments
        </button>
        <button
          class="bg-lula-gradient hover:bg-lula-gradient-alt text-white my-1 md:mr-1 md:my-0"
          @click="saveComments()"
          v-if="editingComments"
        >
          Save Comments
        </button>
        <button
          class="bg-gray-100 text-gray my-1 md:mr-1 md:my-0"
          @click="discardComments()"
          v-if="editingComments"
        >
          Discard Changes
        </button>
      </div>
    </div>

    <!-- Account Grid -->
    <div class="mb-4 p-1">
      <h2 class="text-3xl text-gray-800 font-bold dark:text-white">Accounts</h2>
      <h2 class="text-lg text-gray-600 mb-2 dark:text-white">
        Lists the user accounts and their permissions.
      </h2>
      <div class="flex flex-col gap-2">
        <div
          class="bg-white rounded-lg p-4"
          v-for="account in accountsRef"
          :key="account.customerId"
        >
          <div
            class="flex justify-between items-center mb-2 cursor-pointer"
            @click="
              () => {
                discardAccessLevelChanges();
                selectedCustomerId === account.customerId
                  ? (selectedCustomerId = null)
                  : (selectedCustomerId = account.customerId);
              }
            "
          >
            <div class="flex flex-col">
              <span
                class="font-bold hover:underline text-base text-2xl text-gray-800"
                @click.stop="routeToCompany(account)"
              >
                {{ account.customerDisplayName }}
              </span>
              <span> Added {{ account.added }} </span>
            </div>
            <svg
              :class="{
                'rotate-180': selectedCustomerId === account.customerId,
              }"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6 transform transition-all"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M19.5 8.25l-7.5 7.5-7.5-7.5"
              />
            </svg>
          </div>
          <!-- Focused Account Card Details -->
          <div
            class="flex flex-col"
            v-if="selectedCustomerId === account.customerId"
          >
            <div
              class="grid grid-cols-1 gap-2 justify-between lg:grid-cols-2 mb-2"
            >
              <div class="flex gap-2 items-center">
                <input
                  type="checkbox"
                  class="w-6"
                  :id="`${account.customerId}-accessLevel`"
                  :checked="
                    (editingAccessLevel
                      ? accessLevelDraftRef.type
                      : accessLevelRef[account.customerId].type) ===
                    AccessLevelTypeEnum.Owner
                  "
                  :disabled="!editingAccessLevel"
                  @click="onAccessLevelTypeClicked()"
                />
                <label :for="`${account.customerId}-accessLevel`">Owner</label>
              </div>
            </div>
            <div
              class="grid grid-cols-1 gap-2 justify-between lg:grid-cols-2 mb-2"
            >
              <div
                class="p-4 border rounded"
                v-for="permission in allPermissions"
                :key="permission.key"
              >
                <span class="text-lg font-bold">
                  {{ permission.label }}
                </span>
                <div class="grid grid-cols-4">
                  <div class="flex gap-2 items-center">
                    <input
                      type="checkbox"
                      class="w-6"
                      :id="`${account.customerId}-${permission.key}-write`"
                      :checked="
                        editingAccessLevel
                          ? accessLevelDraftRef.type ===
                              AccessLevelTypeEnum.Owner ||
                            accessLevelDraftRef.permissions[permission.key]
                              .write
                          : accessLevelRef[account.customerId].type ===
                              AccessLevelTypeEnum.Owner ||
                            accessLevelRef[account.customerId].permissions[
                              permission.key
                            ].write
                      "
                      :disabled="
                        !editingAccessLevel ||
                        accessLevelDraftRef.type === AccessLevelTypeEnum.Owner
                      "
                      @click="onPermissionClicked(permission.key, 'write')"
                    />
                    <label
                      :for="`${account.customerId}-${permission.key}-write`"
                      >Admin</label
                    >
                  </div>
                  <div class="col-span-3">
                    <span class="text-gray-600">
                      {{ permission.description.write }}
                    </span>
                  </div>
                  <div class="flex gap-2 items-center">
                    <input
                      type="checkbox"
                      class="w-6"
                      :id="`${account.customerId}-${permission.key}-read`"
                      :checked="
                        editingAccessLevel
                          ? accessLevelDraftRef.type ===
                              AccessLevelTypeEnum.Owner ||
                            accessLevelDraftRef.permissions[permission.key].read
                          : accessLevelRef[account.customerId].type ===
                              AccessLevelTypeEnum.Owner ||
                            accessLevelRef[account.customerId].permissions[
                              permission.key
                            ].read
                      "
                      :disabled="
                        !editingAccessLevel ||
                        accessLevelDraftRef.type ===
                          AccessLevelTypeEnum.Owner ||
                        (accessLevelDraftRef.permissions[permission.key].read &&
                          accessLevelDraftRef.permissions[permission.key].write)
                      "
                      @click="onPermissionClicked(permission.key, 'read')"
                    />
                    <label :for="`${account.customerId}-${permission.key}-read`"
                      >Viewer</label
                    >
                  </div>
                  <div class="col-span-3">
                    <span class="text-gray-600">
                      {{ permission.description.read }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="flex flex-col items-center justify-between gap-2 mb-2 md:flex-row"
            >
              <div class="flex flex-col items-center justify-start md:flex-row">
                <button
                  class="bg-lula-gradient hover:bg-lula-gradient-alt text-white my-1 md:mr-1 md:my-0"
                  @click="onAccessLevelEditClicked(account.customerId)"
                  v-if="!editingAccessLevel"
                >
                  Edit Permissions
                </button>
                <button
                  class="bg-lula-gradient hover:bg-lula-gradient-alt text-white my-1 md:mr-1 md:my-0"
                  @click="saveAccessLevelChanges(account.customerId)"
                  v-if="editingAccessLevel"
                >
                  Save Permissions
                </button>
                <button
                  class="bg-gray-100 text-gray my-1 md:mr-1 md:my-0"
                  @click="discardAccessLevelChanges()"
                  v-if="editingAccessLevel"
                >
                  Discard Changes
                </button>
              </div>
              <div
                class="flex justify-end gap-4 mb-2"
                v-if="
                  editingAccessLevel &&
                  accessLevelDraftRef.type === AccessLevelTypeEnum.Privileged
                "
              >
                <span
                  class="font-bold text-gray-600 cursor-pointer hover:underline mr-2"
                  @click="onBulkPermissionSelect('all')"
                >
                  Select All
                </span>
                <span
                  class="font-bold text-gray-600 cursor-pointer hover:underline mr-2"
                  @click="onBulkPermissionSelect('view')"
                >
                  View Only
                </span>
                <span
                  class="font-bold text-gray-600 cursor-pointer hover:underline mr-2"
                  @click="onBulkPermissionSelect('none')"
                >
                  Deselect All
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="text-lg rounded-lg bg-white text-gray-600 text-center p-4 mt-4"
        v-if="!accountsRef.length"
      >
        No Accounts
      </div>
    </div>

    <!-- Changelog grid -->
    <div class="mb-4 p-1">
      <div class="flex justify-between items-end mb-1">
        <h2 class="text-3xl text-gray-800 font-bold dark:text-white">
          Changelog
        </h2>
      </div>
      <div class="flex flex-col gap-2">
        <grid
          v-if="changelogRef.length > 0"
          :rows="changelogRef"
          :columns="changelogColumnsRef"
        />
        <div
          class="bg-white rounded-lg text-center text-gray-600 p-4 select-none"
          v-else
        >
          {{
            changelogRefreshRef
              ? "Refreshing changelog..."
              : "No User Account Change History"
          }}
        </div>
        <div class="flex justify-end">
          <span
            class="font-bold text-gray-600 cursor-pointer hover:underline dark:text-white"
            @click="refreshChangelog"
          >
            Refresh changelog
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import moment from "moment";
import { html } from "gridjs";
import { AccessLevelTypeEnum } from "@getaddify/tenant-account-management";

export default {
  computed: {
    AccessLevelTypeEnum() {
      return AccessLevelTypeEnum;
    },
  },
  setup() {
    // Vue setup
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    // User details card (email address, status, createdAt)
    const userDetails = ref(null);

    // Send Verification Email Button
    const sendUserVerificationEmail = async () => {
      toast(`Sending verification email.`);
      await store.dispatch("sendCustomerUserIdentityVerificationEmail", {
        email: route.params.emailAddress,
      });
      toast.clear();
      toast(`Successfully sent verification email.`);
    };

    // Send Password Reset Button
    const sendUserPasswordResetEmail = async () => {
      toast(`Sending password reset email.`);
      await store.dispatch("sendCustomerUserIdentityPasswordResetEmail", {
        email: route.params.emailAddress,
      });
      toast.clear();
      toast(`Successfully sent password reset email.`);
    };

    // User comments
    const commentsRef = ref(null);
    const commentsDraftRef = ref(null);
    const editingComments = ref(false);

    const onCommentsEditClicked = () => {
      editingComments.value = true;
      commentsDraftRef.value = JSON.parse(JSON.stringify(commentsRef.value));
    };

    const saveComments = async () => {
      try {
        toast(`Saving comments.`);
        await store.dispatch("addUserComments", {
          email: route.params.emailAddress,
          comments: commentsDraftRef.value,
        });
        commentsRef.value = commentsDraftRef.value;
        editingComments.value = false;
        toast.clear();
        toast(`Successfully saved comments.`);
      } catch (ex) {
        toast(`Failed to save comments.`);
        throw ex;
      }
    };
    const discardComments = () => {
      commentsDraftRef.value = commentsRef.value;
      editingComments.value = false;
    };

    // Account cards
    const allPermissions = ref([]);
    // Stored the access level for customer accounts
    const accessLevelRef = ref(null);
    // Stores the access level while editing
    const accessLevelDraftRef = ref(null);
    // Stores whether or not the access levels are currently being edited
    const editingAccessLevel = ref(null);

    // Stores the list of all customer accounts for the user
    const accountsRef = ref([]);
    // Stores the id of the currently selected customer
    const selectedCustomerId = ref(null);

    /**
     * Maps permissions from their API representation to that used by the UI
     * @param {Permission[]} permissions
     */
    const mapPermissions = (permissions) => {
      const reduced = permissions.reduce((map, permission) => {
        if (!map[permission.namespace]) {
          map[permission.namespace] = {
            key: permission.namespace,
            label:
              permission.namespace[0].toLocaleUpperCase() +
              permission.namespace.slice(1),
            description: {},
          };
        }
        map[permission.namespace].description[permission.privilege] =
          permission.description;
        return map;
      }, {});
      const values = [];
      // eslint-disable-next-line no-unused-vars
      for (const [_key, value] of Object.entries(reduced).values()) {
        values.push(value);
      }
      return values;
    };

    // Routes the user to the corresponding company page for this membership
    const routeToCompany = async (account) => {
      const customer = await store.dispatch("getTenant", account.customerId);
      router.push({
        name: "Company",
        params: {
          id: customer.documentId,
        },
      });
    };

    // Triggered when one of the bulk edit buttons are selected. Sets the appropriate value
    // for all of the account's draft permissions.
    const onBulkPermissionSelect = (kind) => {
      const write = kind === "all";
      const read = kind === "all" || kind === "view";
      for (const [namespace] of Object.entries(
        accessLevelDraftRef.value.permissions,
      )) {
        accessLevelDraftRef.value.permissions[namespace].write = write;
        accessLevelDraftRef.value.permissions[namespace].read = read;
      }
    };

    // Triggered when the access level checkbox is clicked.
    // Toggles the access level type value to the opposite and sets default permissions.
    const onAccessLevelTypeClicked = () => {
      if (accessLevelDraftRef.value.type === AccessLevelTypeEnum.Privileged) {
        onBulkPermissionSelect("all");
        accessLevelDraftRef.value.type = AccessLevelTypeEnum.Owner;
      } else {
        onBulkPermissionSelect("none");
        accessLevelDraftRef.value.type = AccessLevelTypeEnum.Privileged;
      }
    };

    // Triggered when a permission checkbox is clicked. Toggles the permission value to the opposite
    // variation. If a write permission is enabled, we also enable the corresponding read.
    const onPermissionClicked = (namespace, privilege) => {
      const newRead = !accessLevelDraftRef.value.permissions[namespace].read;
      const newWrite = !accessLevelDraftRef.value.permissions[namespace].write;

      if (privilege === "read") {
        accessLevelDraftRef.value.permissions[namespace].read = newRead;
      } else {
        if (newWrite) {
          accessLevelDraftRef.value.permissions[namespace].write = true;
          accessLevelDraftRef.value.permissions[namespace].read = true;
        } else {
          accessLevelDraftRef.value.permissions[namespace].write = false;
        }
      }
    };

    // Triggered when the edit access level button is selected. Starts an editing session with
    // a new draft permission set.
    const onAccessLevelEditClicked = (customerId) => {
      editingAccessLevel.value = true;
      accessLevelDraftRef.value = JSON.parse(
        JSON.stringify(accessLevelRef.value[customerId]),
      );
    };

    // Triggered when the discard permission button is selected.
    // Ends the editing session by resetting the draft access level and permissions.
    const discardAccessLevelChanges = () => {
      editingAccessLevel.value = false;
      accessLevelDraftRef.value = null;
    };

    // Triggered when the save permissions button is selected. Persists the changes and ends the
    // editing session.
    const saveAccessLevelChanges = async (customerId) => {
      try {
        let accessLevel = { type: accessLevelDraftRef.value.type };

        if (accessLevelDraftRef.value.type === AccessLevelTypeEnum.Privileged) {
          const permissionList = [];
          for (const [namespace] of Object.entries(
            accessLevelDraftRef.value.permissions,
          )) {
            if (accessLevelDraftRef.value.permissions[namespace].write) {
              permissionList.push(`${namespace}:write`);
            }
            if (accessLevelDraftRef.value.permissions[namespace].read) {
              permissionList.push(`${namespace}:read`);
            }
          }
          if (permissionList.length === 0) {
            toast(`Account must have at least one permission.`);
            return;
          } else {
            accessLevel.permissions = permissionList;
          }
        }

        toast(`Saving permissions.`);

        if (
          JSON.stringify(accessLevelRef.value[customerId]) !==
          JSON.stringify(accessLevelDraftRef.value)
        ) {
          await store.dispatch("changeCustomerUserAccountAccessLevel", {
            customerId: customerId,
            email: route.params.emailAddress,
            accessLevel: accessLevel,
          });
          accessLevelRef.value[customerId] = accessLevelDraftRef.value;
        }

        discardAccessLevelChanges();
        toast.clear();
        toast(`Successfully saved permissions.`);
      } catch (ex) {
        let reason = ".";
        if (ex.response?.status >= 400 && ex.response?.status < 500) {
          const error = await ex.response.json();
          if (error.detail) {
            reason = `: ${error.detail}`;
          }
        }
        toast(`Failed to save permissions${reason}`);
        throw ex;
      }
    };

    // Initializes the access level ref from the set of accounts.
    const getAccessLevels = (permissions, accounts) => {
      const accessLevelMap = {};
      accounts.forEach((account) => {
        accessLevelMap[account.customerId] = {
          type: account.accessLevel.type,
          permissions: {},
        };
        permissions.forEach((permission) => {
          accessLevelMap[account.customerId].permissions[permission.namespace] =
            accessLevelMap[account.customerId].permissions[
              permission.namespace
            ] ?? {};
          accessLevelMap[account.customerId].permissions[permission.namespace][
            permission.privilege
          ] =
            account.accessLevel.type === AccessLevelTypeEnum.Owner ||
            account.accessLevel.permissions?.includes(permission.id);
        });
      });
      return accessLevelMap;
    };

    // User changelog card
    const changelogRef = ref([]);
    const changelogRefreshRef = ref(false);

    const refreshChangelog = async () => {
      changelogRefreshRef.value = true;
      try {
        const logs = await store.dispatch("listCustomerUserAccountsChangelog", {
          email: route.params.emailAddress,
        });
        changelogRef.value = [];
        setTimeout(() => {
          changelogRef.value = mapChangelogEntries(logs);
          changelogRefreshRef.value = false;
        }, 1000);
      } catch (err) {
        toast("Failed to get changelogs.");
        setTimeout(() => (changelogRefreshRef.value = false), 1000);
      }
    };

    const mapChangelogEntries = (entries) =>
      entries.map((entry) => ({
        id: `${moment(entry.createdAt).unix()}_${entry.customerId}_${entry.changesetType}`,
        changesetType: entry.changesetType,
        changesetValue: entry.changesetValue,
        customerDisplayName: entry.customerDisplayName,
        createdBy: entry.createdBy,
        createdAt: moment.utc(entry.createdAt).local().format("yyyy-MM-DD"),
      }));

    const changelogColumnsRef = [
      { name: "id", hidden: true, key: "id" },
      { name: "Change Type", id: "changesetType" },
      {
        name: "Change Value",
        id: "changesetValue",
        formatter: (items) => {
          const listTemplate = items
            .map((value) => `<li>${value}</li>`)
            .join("");
          return html(`<ul>${listTemplate}</ul>`);
        },
      },
      { name: "Account", id: "customerDisplayName" },
      { name: "Changed By", id: "createdBy" },
      { name: "Created", id: "createdAt" },
    ];

    // Initial logic on mount. Gets the user details, their memberships, admin comments and changelog entries.
    onMounted(async () => {
      const [
        allPermissionList,
        userResult,
        userComments,
        accountsResult,
        accountsChangelogResult,
      ] = await Promise.all([
        store.dispatch("listPermissions"),
        store.dispatch("getUserByEmail", { email: route.params.emailAddress }),
        store.dispatch("getUserComments", { email: route.params.emailAddress }),
        store.dispatch("getCustomerUserAccountsByEmail", {
          email: route.params.emailAddress,
        }),
        store.dispatch("listCustomerUserAccountsChangelog", {
          email: route.params.emailAddress,
        }),
      ]);

      userDetails.value = userResult;
      accountsRef.value = accountsResult;
      accessLevelRef.value = getAccessLevels(
        allPermissionList,
        accountsRef.value,
      );
      allPermissions.value = mapPermissions(allPermissionList);
      commentsRef.value = userComments.comments?.value;
      commentsDraftRef.value = commentsRef.value;
      changelogRef.value = mapChangelogEntries(accountsChangelogResult);
    });

    return {
      // User
      userDetails: computed(() => {
        if (!userDetails.value) {
          return {
            emailAddress: route.params.emailAddress,
            createdAt: "⚠️ Never registered",
            status: "Unregistered",
            hasPassword: false,
            exists: false,
          };
        }
        return {
          ...userDetails.value,
          createdAt: `Created ${moment(userDetails.value.createdAt).fromNow()}`,
          providers:
            "Registered via " +
            userDetails.value.identities
              .map((i) => `'${i.provider.type}:${i.provider.name}'`)
              .join(", "),
          hasPassword: userDetails.value.identities.some(
            (i) => i.provider.type === "database",
          ),
          status: userDetails.value.verified ? "Verified" : "Unverified",
          exists: true,
        };
      }),
      sendUserPasswordResetEmail,
      sendUserVerificationEmail,

      // Accounts
      accountsRef: computed(() =>
        accountsRef.value.map((account) => ({
          ...account,
          added: moment.utc(account.createdAt).fromNow(),
        })),
      ),
      selectedCustomerId,
      routeToCompany,

      // Access level
      allPermissions,
      accessLevelDraftRef,
      accessLevelRef,
      editingAccessLevel,
      onBulkPermissionSelect: onBulkPermissionSelect,
      onPermissionClicked,
      onAccessLevelEditClicked,
      onAccessLevelTypeClicked,
      saveAccessLevelChanges,
      discardAccessLevelChanges,

      // Comments
      commentsRef,
      commentsDraftRef,
      editingComments,
      onCommentsEditClicked,
      discardComments,
      saveComments,

      // Changelog
      changelogRef,
      changelogRefreshRef,
      changelogColumnsRef,
      refreshChangelog,
    };
  },
};
</script>
