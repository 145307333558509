<template>
  <div>
    <label class="font-bold mb-2 text-gray-800 text-lg">
      {{ label }}
    </label>
    <textarea
      rows="5"
      :disabled="!enableEdit"
      class="border"
      :class="{ 'border-lula-dark': enableEdit }"
      v-model="settingValue"
      v-if="type === 'textarea'"
    />
    <input
      :type="text"
      :disabled="!enableEdit"
      class="border"
      :class="{ 'border-lula-dark': enableEdit }"
      v-model="settingValue"
      v-else
    />
    <div class="flex justify-between">
      <span />
      <span
        class="font-bold text-gray-800 cursor-pointer hover:underline"
        @click="saveSetting"
        v-if="enableEdit"
      >
        Save {{ edit }}
      </span>
      <span
        class="font-bold text-gray-800 cursor-pointer hover:underline"
        @click="enableEdit = true"
        v-else
      >
        Edit {{ edit }}
      </span>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import { useToast } from "vue-toastification";

export default {
  props: {
    label: {
      type: String,
      default: "System Setting",
    },
    edit: {
      type: String,
      default: "Value",
    },
    property: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: "text",
    },
  },
  setup(props) {
    const store = useStore();
    const toast = useToast();

    const enableEdit = ref(false);
    const settingValue = ref(null);

    onMounted(async () => {
      const system = await store.dispatch("getSystemSettings");
      const setting = system.find((setting) => {
        return setting.key === props.property;
      });
      if (setting) {
        settingValue.value = setting.value;
      }
    });

    return {
      enableEdit,
      settingValue,
      async saveSetting() {
        toast("Saving setting.");
        try {
          await store.dispatch("saveSystemSetting", {
            key: props.property,
            value: settingValue.value,
          });
          enableEdit.value = false;
          toast.clear();
          toast("Setting saved.");
        } catch (err) {
          toast.clear();
          toast("Failed to save setting");
        }
      },
    };
  },
};
</script>
