<template>
  <div class="w-full">
    <div class="w-full bg-white rounded-lg m-auto p-4 mt-4 md:w-2/3">
      <h2 class="text-gray-800 text-2xl font-bold">Episode</h2>
      <span class="text-gray-600 text-lg font-bold">
        {{ (episode.id || "").toUpperCase() }}
      </span>
      <form @submit.prevent="saveEpisode">
        <div class="grid grid-cols-1 md:grid-cols-2">
          <div class="m-1" v-for="field in episodeDetails" :key="field.key">
            <label class="font-bold text-gray-800">
              {{ field.label }}
            </label>
            <br />
            <input
              type="text"
              class="cursor-pointer"
              :value="company.businessLegalName"
              @click.prevent="selectCompany"
              v-if="field.key === 'company'"
            />
            <input
              type="text"
              :value="selectedVehicle.key || selectedVehicle.vin"
              @click="episode.vehicle = null"
              class="cursor-pointer"
              v-else-if="selectedVehicle && field.key === 'vehicle'"
            />
            <input
              list="company-vehicle-list"
              v-model="episode.vehicle"
              v-else-if="!selectedVehicle && field.key === 'vehicle'"
            />
            <input
              type="text"
              :value="`${selectedDriver.firstName} ${selectedDriver.lastName}`"
              @click="episode.driver = null"
              class="cursor-pointer"
              v-else-if="selectedDriver && field.key === 'driver'"
            />
            <input
              list="company-driver-list"
              v-model="episode.driver"
              v-else-if="!selectedDriver && field.key === 'driver'"
            />
            <a
              :href="stripePaymentLink"
              target="_blank"
              class="cursor-pointer"
              v-else-if="field.key === 'payment'"
            >
              <input
                :type="field.type"
                class="cursor-pointer"
                disabled
                :value="episode.payment"
              />
            </a>
            <input
              :type="field.type"
              :disabled="field.disabled"
              v-on:change="updateEndDate()"
              v-model="episode[field.key]"
              v-else-if="field.key === 'startDate'"
            />
            <input
              :type="field.type"
              :disabled="field.disabled"
              v-model="episode[field.key]"
              v-else
            />
          </div>
          <datalist id="company-vehicle-list">
            <option
              v-for="vehicle in companyVehicles"
              :value="vehicle.entityId"
              :key="vehicle.entityId"
            >
              <span v-if="vehicle.key">
                {{ vehicle.key }} ({{ vehicle.vin }})
              </span>
              <span v-else>
                {{ vehicle.year }} {{ vehicle.make }} {{ vehicle.model }}
              </span>
            </option>
          </datalist>
          <datalist id="company-driver-list">
            <option
              v-for="driver in companyDrivers"
              :value="driver.entityId"
              :key="driver.entityId"
            >
              {{ driver.firstName }} {{ driver.lastName }}
            </option>
          </datalist>
        </div>
        <div class="flex justify-between flex-col md:flex-row">
          <div class="flex flex-col mt-2 md:flex-row">
            <div v-if="isCanceled" class="flex items-center text-red-600">
              This episode has been canceled.
            </div>
          </div>
          <div class="flex justify-end flex-col mt-2 md:flex-row">
            <button
              @click="$router.go(-1)"
              type="button"
              class="bg-gray-100 hover:bg-gray-200 md:mr-1"
            >
              Back
            </button>
            <button
              v-if="!isCanceled"
              type="submit"
              class="bg-lula-gradient text-white mt-1 hover:bg-lula-gradient-alt md:mt-0"
            >
              Save
            </button>
          </div>
          <modal
            v-if="confirmCancel"
            class="fixed inset-0 w-full h-screen flex items-center justify-center bg-semi-75"
          >
            <div
              class="w-3/4 max-w-2xl bg-white shadow-lg rounded-lg p-6 flex flex-col flex-start"
            >
              <div class="py-2 px-2 flex font-bold w-full text-xl">
                Cancel coverage
              </div>
              <div class="text-gray-800 text-sm px-2 py-2 text-left">
                Are you sure you want to cancel this coverage? Client will be
                refunded.
              </div>
              <div class="flex flex-row pt-2 justify-between">
                <button
                  @click.prevent="confirmCancel = false"
                  class="transition text-gray-600 cursor-pointer py-2 mb-2 rounded-[50%] hover:bg-gray-100 text-sm"
                >
                  Back
                </button>
                <button
                  class="h-10 py-2 px-4 text-sm"
                  @click="cancelEpisode"
                  :disabled="saving"
                  :class="{
                    'bg-lula-gradient text-white hover:bg-lula-gradient-alt':
                      !saving,
                    'bg-gray-300 text-gray-600 cursor-default': saving,
                  }"
                >
                  Confirm cancelation
                </button>
              </div>
            </div>
          </modal>
        </div>
      </form>
      <extend-reservation
        v-if="extendReservation"
        :selectedReservation="reservation"
        :selectedEpisode="episode"
        :selectedVehicle="selectedVehicle"
        @toggle="toggleExtendReservation"
        @goBack="goBack"
      />
    </div>
  </div>
</template>

<script>
import { onMounted, computed, ref } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { useToast } from "vue-toastification";
import moment from "moment";
import ExtendReservation from "./ExtendReservation.vue";

function castEpisode(episode) {
  return {
    ...episode,
    startDate: moment.utc(episode.startDate).format("yyyy-MM-DDTHH:mm"),
    endDate: moment.utc(episode.endDate).format("yyyy-MM-DDTHH:mm"),
    created: moment.utc(episode.created).format("yyyy-MM-DD"),
    modified: moment.utc(episode.modified).format("yyyy-MM-DD"),
  };
}

export default {
  setup() {
    const extendReservation = ref(false);

    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const toast = useToast();
    const confirmCancel = ref(false);

    const episode = ref({});
    const company = ref({});
    const reservation = ref({});
    const saving = ref(false);

    onMounted(async () => {
      company.value = await store.dispatch(
        "getCompany",
        route.params.companyId,
      );
      episode.value = await store.dispatch("getEpisode", {
        companyId: route.params.companyId,
        episodeId: route.params.episodeId,
      });
      episode.value = castEpisode(episode.value);
      const reservations = await store.dispatch(
        "getReservationsForCompany",
        route.params.companyId,
      );
      reservation.value = reservations.find(
        (reservation) => reservation.entityId === episode.value.reservation,
      );
    });

    return {
      confirmCancel,
      episode,
      saving,
      reservation,
      extendReservation,
      episodeDetails: [
        { key: "vehicle", label: "Vehicle", disabled: false, type: "text" },
        { key: "driver", label: "Driver", disabled: false, type: "text" },
        { key: "company", label: "Company", disabled: true, type: "text" },
        {
          key: "payment",
          label: "Stripe Payment",
          disabled: true,
          type: "text",
        },
        {
          key: "startDate",
          label: "Start Date",
          disabled: false,
          type: "datetime-local",
        },
        {
          key: "endDate",
          label: "End Date",
          disabled: true,
          type: "datetime-local",
        },
        { key: "days", label: "Days", disabled: true, type: "number" },
        {
          key: "pricePerDay",
          label: "Price Per Day",
          disabled: true,
          type: "number",
        },
        { key: "created", label: "Created", disabled: true, type: "date" },
        { key: "modified", label: "Modified", disabled: true, type: "date" },
      ],
      selectCompany() {
        router.push({
          name: "Company",
          params: { id: route.params.companyId },
        });
      },
      company,
      companyVehicles: computed(() => {
        return store.state.vehicle.filter(({ companyId }) => {
          return companyId === route.params.companyId;
        });
      }),
      companyDrivers: computed(() => {
        return store.state.driver.filter(({ companyId }) => {
          return companyId === route.params.companyId;
        });
      }),
      selectedDriver: computed(() => {
        return store.state.driver.find(({ companyId, entityId }) => {
          return (
            companyId === route.params.companyId &&
            entityId === episode.value.driver
          );
        });
      }),
      selectedVehicle: computed(() => {
        return store.state.vehicle.find(({ companyId, entityId }) => {
          return (
            companyId === route.params.companyId &&
            entityId === episode.value.vehicle
          );
        });
      }),
      stripePaymentLink: computed(() => {
        return `${process.env["VUE_APP_STRIPE_URL"]}/payments/${episode.value.payment}`;
      }),
      isCanceled: computed(() => {
        return episode.value.status === "Canceled";
      }),
      toggleExtendReservation() {
        extendReservation.value = !extendReservation.value;
      },
      goBack() {
        router.go(-1);
      },
      updateEndDate() {
        episode.value.endDate = moment(episode.value.startDate)
          .add(episode.value.days, "days")
          .format("YYYY-MM-DDTHH:mm");
      },
      canExtendReservation() {
        const now = moment().utc();
        const endDate = moment(episode.value.endDate).utc();
        return episode.value.payment && now.isBefore(endDate);
      },
      canCancel() {
        const now = moment().utc();
        const startDate = moment(episode.value.startDate).utc();
        return now.isBefore(startDate);
      },
      async cancelEpisode() {
        try {
          saving.value = true;
          const payload = {
            companyId: route.params.companyId,
            entityId: episode.value.entityId,
          };
          const response = await store.dispatch("cancelEpisode", payload);
          const success = response.success;
          if (success) {
            toast.clear();
            toast("Episode canceled.");
            confirmCancel.value = false;
            // router.go(-1);
            router.push({
              name: "Company",
              params: { id: route.params.companyId },
            });
          } else {
            toast.clear();
            toast("Failed to cancel episode.");
          }
          saving.value = false;
        } catch (err) {
          toast.clear();
          toast("Failed to cancel episode.");
        }
      },
      async saveEpisode() {
        toast("Saving episode.");
        try {
          episode.value = await store.dispatch("saveEpisode", {
            companyId: route.params.companyId,
            episode: {
              ...episode.value,
              startDate: moment(episode.value.startDate).utc().format(),
              endDate: moment(episode.value.endDate).utc().format(),
            },
          });
          episode.value = castEpisode(episode.value);
          toast.clear();
          toast("Episode saved.");
        } catch (err) {
          toast.clear();
          toast("Failed to save episode.");
        }
      },
    };
  },
  components: {
    ExtendReservation,
  },
};
</script>
