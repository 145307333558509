import "vue-toastification/dist/index.css";
import Toast from "vue-toastification";
import "./assets/tailwind.css";
import "./assets/index.css";
import { createApp } from "vue";
import router from "./router";
import store from "./store";
import launchDarkly from "./launchDarkly";
import { auth0 } from "./auth0";
import {
  Modal,
  StateSelect,
  Datepicker,
  FloatingVue,
} from "@getaddify/lula-components";
import "@getaddify/lula-components/src/assets/index.css";

import Grid from "./Grid.vue";
import App from "./App.vue";

store.dispatch("loadInitialResources", true);

const toastOptions = { toastClassName: "bg-lula-gradient" };

const app = createApp(App);
app.mixin(launchDarkly);
app.use(Toast, toastOptions);
app.component("loader", {
  template: '<span class="loader select-none">🐎</span>',
});
app.component("grid", Grid);
app.component("modal", Modal);
app.component("state-select", StateSelect);
app.component("date-time-picker", Datepicker);

app.use(store);
app.use(router);
app.use(FloatingVue);

app.use(auth0);

app.mount("#app");
