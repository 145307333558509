<template>
  <div class="p-4 md:p-8">
    <div class="mb-4">
      <h1 class="text-5xl text-gray-800 font-bold mb-3 dark:text-white">
        Vehicles
      </h1>
      <form
        @submit.prevent="searchVehicle"
        class="flex rounded-full border"
        :class="{
          'bg-gray-200 hover:bg-gray-200': searching,
          'bg-white hover:bg-white': !searching,
        }"
      >
        <input
          class="w-full text-sm rounded-full py-2 px-4 mr-1 md:text-lg"
          :class="{
            'bg-gray-200 hover:bg-gray-200': searching,
            'bg-white hover:bg-white': !searching,
          }"
          placeholder="Search by vin, plate, nickname, vehicle id"
          required
          :disabled="searching"
          v-model="vehicleSearch"
        />
        <button
          type="submit"
          class="min-w-max text-sm bg-lula-gradient font-bold text-white cursor-default md:text-lg"
          disabled
          v-if="searching"
        >
          Searching...
        </button>
        <button
          type="submit"
          class="min-w-max text-sm bg-lula-gradient hover:bg-lula-gradient-alt font-bold text-white md:text-lg"
          v-else
        >
          Search Vehicles
        </button>
      </form>
    </div>
    <div v-if="searchResults?.length === 0">
      <span class="text-gray-500 text-xl px-3"> No Vehicles Found </span>
    </div>
    <div class="mb-4" v-else-if="searchResults">
      <div
        class="bg-white rounded-xl border text-sm cursor-pointer mb-2 hover:bg-gray-200 md:text-lg"
        @click="
          $router.push({
            name: 'Vehicle',
            params: {
              companyId: vehicle.account,
              vehicleId: vehicle.id,
            },
          })
        "
        v-for="vehicle in searchResults"
        :key="vehicle.id"
      >
        <div class="flex justify-between items-start p-4">
          <div>
            <span class="font-bold text-gray-800">
              {{ vehicle.vin }}
            </span>
            <br />
            <span class="text-gray-600">
              {{ vehicle.company }}
            </span>
          </div>
          <div>
            <span
              class="bg-lula-alt rounded-full text-white font-bold px-4 py-1"
            >
              {{ vehicle.status }}
            </span>
          </div>
        </div>
        <div
          class="w-full bg-lula text-white border-t px-4 text-sm rounded-b -mt-2"
        >
          <span
            >{{ vehicle.year || "" }} {{ vehicle.make || "" }}
            {{ vehicle.model || "" }}</span
          >
          <span v-if="vehicle.plate">&nbsp;({{ vehicle.plate }})</span>
        </div>
      </div>
    </div>
    <div class="mb-4 p-1" v-else>
      <h2 class="text-3xl text-gray-800 font-bold mb-2 dark:text-white">
        Pending Approval
      </h2>
      <grid
        :columns="requestColumns"
        :rows="requestRows"
        :search="true"
        @click="selectVehicle"
        placeholder="Search Vehicles"
        v-if="requestRows.length > 0"
      />
      <div
        class="text-lg rounded-lg bg-white text-gray-600 text-center p-4 mt-4"
        v-else
      >
        No Pending Vehicles
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import { ellipsisText } from "@/utils";
import moment from "moment";

export default {
  setup() {
    const store = useStore();
    const router = useRouter();
    const toast = useToast();

    const searching = ref(false);
    const vehicleSearch = ref("");
    const searchResults = ref(null);

    watch(vehicleSearch, () => {
      if (!vehicleSearch.value) {
        searchResults.value = null;
      }
    });

    return {
      searching,
      vehicleSearch,
      searchResults: computed(() => {
        if (!searchResults.value) {
          return null;
        }
        return searchResults.value.map((vehicle) => {
          const company = store.state.company.find(
            (c) => vehicle.account === c.id,
          );
          return {
            ...company,
            ...vehicle,
          };
        });
      }),
      async searchVehicle() {
        try {
          searching.value = true;
          searchResults.value = await store.dispatch(
            "searchVehicles",
            vehicleSearch.value,
          );
        } catch (err) {
          vehicleSearch.value = null;
          toast.clear();
          toast("Vehicle search failed.");
        }
        searching.value = false;
      },
      selectVehicle(vehicleId, event) {
        const vehicle = store.state.pendingVehicles.find(
          (v) => v.id === vehicleId,
        );
        if (event.ctrlKey || event.metaKey) {
          const route = router.resolve({
            name: "Vehicle",
            params: {
              companyId: vehicle.account,
              vehicleId: vehicle.id,
            },
          });
          window.open(route.fullPath, "_blank");
        } else {
          router.push({
            name: "Vehicle",
            params: {
              companyId: vehicle.account,
              vehicleId: vehicle.id,
            },
          });
        }
      },
      requestColumns: [
        { name: "id", hidden: true },
        { name: "entityId", hidden: true },
        { name: "VIN", id: "vin" },
        { name: "Year", id: "year" },
        { name: "Make", id: "make", formatter: (d) => ellipsisText(d, 10) },
        { name: "Model", id: "model", formatter: (d) => ellipsisText(d, 10) },
        {
          name: "Company",
          id: "company",
          formatter: (d) => ellipsisText(d, 10),
        },
        { name: "Created", id: "created", sorting: "ascending" },
      ],
      requestRows: computed(() => {
        return store.state.pendingVehicles.map((vin) => {
          return {
            ...vin,
            created: moment.utc(vin.created).local().format("yyyy-MM-DD"),
          };
        });
      }),
    };
  },
};
</script>
