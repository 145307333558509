<template>
  <div ref="gridRoot" />
</template>

<script>
import { Grid } from "gridjs";
import "gridjs/dist/theme/mermaid.css";
import { ref, toRefs, onMounted } from "vue";

export default {
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
    rows: {
      type: Array,
      default: () => [],
    },
    search: {
      type: Boolean,
      default: () => false,
    },
    sort: {
      type: Boolean,
      default: () => false,
    },
    placeholder: {
      type: String,
      default: "Search",
    },
  },
  emits: ["click"],
  setup(props, { emit }) {
    const gridRoot = ref(null);
    const { placeholder, columns, rows, search, sort } = toRefs(props);
    const grid = ref(null);

    onMounted(() => {
      grid.value = new Grid({
        columns: columns.value,
        data: rows.value,
        search: {
          enabled: search,
          ignoreHiddenColumns: false,
        },
        sort: {
          enabled: sort,
        },
        pagination: {
          limit: 10,
          buttonsCount: 0,
        },
      });

      grid.value.render(gridRoot.value);

      grid.value.on("ready", () => {
        if (gridRoot.value) {
          [...gridRoot.value.querySelectorAll("tbody tr")].forEach((tr) => {
            tr.classList.add("cursor-pointer");
            tr.classList.add("hover:bg-gray-200");
          });
        }
      });

      grid.value.on("rowClick", (...args) => {
        emit("click", args[1]["cells"][0]["data"], args[0]);
      });

      grid.value.on("ready", () => {
        if (gridRoot.value) {
          const searchInput = gridRoot.value.querySelector(
            'input[type="search"]',
          );
          if (searchInput) {
            searchInput.setAttribute("placeholder", placeholder.value);
          }
        }
      });

      const sorted = ref(false);
      grid.value.on("ready", () => {
        const sortCol = columns.value.find((c) => c.sorting);
        if (!sorted.value && sortCol && gridRoot.value) {
          const gridCol = gridRoot.value.querySelector(
            `th[data-column-id=${sortCol.id}]`,
          );
          if (gridCol) {
            if (sortCol.sorting === "ascending") {
              setTimeout(() => gridCol.click(), 0);
              sorted.value = true;
            }
            if (sortCol.sorting === "descending") {
              setTimeout(() => {
                gridCol.click();
                gridCol.click();
              }, 0);
              sorted.value = true;
            }
          }
        }
      });
    });

    return {
      gridRoot,
    };
  },
};
</script>

<style>
.gridjs-table {
  width: 100% !important;
}

.gridjs input[type="search"]:focus,
.gridjs input[type="search"] {
  border-radius: 9999px;
  box-shadow: none !important;
  border-color: #ddd;
  font-size: large;
  margin: 0px;
  padding: 10px 1rem;
}

.gridjs button[title="Previous"] {
  border-radius: 0px;
  box-shadow: none !important;
}

.gridjs button[title="Next"] {
  border-radius: 0px;
  box-shadow: none !important;
}

.gridjs tbody td {
  background-color: inherit;
}

.gridjs-search,
.gridjs-search input {
  width: 100%;
}

.gridjs-wrapper {
  width: 99%;
}

.gridjs-footer {
  margin: auto;
  width: 99%;
}

.gridjs-wrapper {
  margin: auto;
}

@media (max-width: 800px) {
  .gridjs-search,
  .gridjs-search input {
    width: 100%;
  }
}
</style>
