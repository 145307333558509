<template>
  <div class="p-4 md:p-8">
    <div class="md:flex w-full justify-center">
      <div class="w-full m-auto sm:w-1/2 md:w-1/3 lg:mx-1 mb-2 lg:mb-0 md:m-0">
        <input
          type="file"
          class="hidden"
          :disabled="isNew"
          accept=".jpeg,.jpg,.png"
          multiple="false"
          ref="thumbnailFileInput"
        />
        <img
          :class="{ 'cursor-pointer': !isNew }"
          class="w-full rounded-2xl mb-2 object-cover max-h-64"
          :src="thumbnailSrc"
          @click="clickThumbnail"
          v-if="thumbnailSrc"
        />
        <img
          @click="clickThumbnail"
          :class="{ 'cursor-pointer': !isNew }"
          class="w-full rounded-2xl mb-2 object-cover"
          src="@/assets/driver-license-1.svg"
          v-else
        />
      </div>
      <div class="max-w-2xl flex-grow mx-1">
        <form @submit.prevent="saveDriver" class="bg-white rounded-2xl p-4">
          <div class="md:grid md:grid-cols-2 md:mb-2">
            <div class="p-2" v-for="detail in details" :key="detail.key">
              <label class="font-bold">{{ detail.name }}</label>
              <br />
              <router-link
                :to="`/company/${company.id}`"
                v-if="detail.key === 'companyName'"
              >
                <input
                  type="text"
                  :value="company.businessLegalName"
                  class="cursor-pointer"
                  disabled
                />
              </router-link>
              <state-select
                v-else-if="detail.key === 'state'"
                v-model="driver[detail.key]"
              />
              <select
                @change="criteriaStatus"
                v-else-if="detail.key === 'insuranceCriteriaStatus'"
                v-model="driver[detail.key]"
              >
                <option>Under Review</option>
                <option>Approved</option>
                <option>Declined</option>
              </select>
              <select
                :disabled="
                  driver.insuranceCriteriaStatus === 'Under Review' ||
                  driver.insuranceCriteriaStatus === 'Declined'
                "
                v-else-if="detail.key === 'status'"
                v-model="driver[detail.key]"
              >
                <option disabled>Requested</option>
                <option>Active</option>
                <option>Inactive</option>
                <option v-if="driver.status !== 'Active'">Archive</option>
              </select>
              <input
                v-else
                :type="detail.type"
                v-model="driver[detail.key]"
                :disabled="detail.disabled === true"
              />
            </div>
          </div>
          <div class="flex flex-col md:flex-row md:justify-end">
            <button
              type="button"
              @click="$router.go(-1)"
              class="bg-gray-100 hover:bg-gray-200 my-1 md:mr-1 md:my-0"
            >
              Cancel
            </button>
            <button
              type="submit"
              class="bg-lula-gradient text-white my-1 hover:bg-lula-gradient-alt md:mr-1 md:my-0"
            >
              Save Driver
            </button>
          </div>
        </form>
        <div class="px-2 flex justify-between" v-if="!isNew">
          <span />
          <span
            class="text-gray-600 font-bold cursor-pointer hover:underline"
            @click="copyCustomerLink"
          >
            Copy customer link
          </span>
        </div>
      </div>
    </div>
    <div class="p-2 mt-4" v-if="!isNew">
      <div class="flex justify-between items-end mb-1">
        <h2
          class="font-bold text-3xl mb-2 text-gray-800 md:text-5xl dark:text-white"
        >
          Changelog
        </h2>
      </div>
      <grid
        v-if="changelog.length > 0"
        :rows="changelog"
        :columns="changelogColumns"
      />
      <div
        class="bg-white rounded-lg text-center text-gray-600 p-4 select-none"
        v-else
      >
        {{
          changelogRefresh
            ? "Refreshing changelog..."
            : "No Company Change History"
        }}
      </div>
      <div class="flex justify-end">
        <span
          class="font-bold text-gray-600 cursor-pointer hover:underline dark:text-white"
          @click="refreshChangelog"
        >
          Refresh changelog
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { useToast } from "vue-toastification";
import { useRoute, useRouter } from "vue-router";
import { watch, ref, onMounted, computed } from "vue";
import { getBase64, getMegabytesFromBytes } from "@/utils";
import { getChangelogDescription } from "@/utils";
import moment from "moment";

function getDriverDetails() {
  return [
    { name: "DL Number", key: "license", type: "text" },
    { name: "DL Class", key: "class", type: "text" },
    { name: "First Name", key: "firstName", type: "text" },
    { name: "Last Name", key: "lastName", type: "text" },
    { name: "State", key: "state", type: "text" },
    { name: "Company", key: "companyName", type: "text" },
    { name: "Date of Birth", key: "dateOfBirth", type: "date" },
    { name: "Date of Hire", key: "dateOfHire", type: "date" },
    { name: "Approval Status", key: "insuranceCriteriaStatus", type: "text" },
    { name: "Driver Status", key: "status", type: "text" },
    { name: "Email", key: "email", type: "text" },
    { name: "Phone", key: "phone", type: "text" },
    { name: "Modified", key: "modified", type: "text", disabled: true },
    { name: "Created", key: "created", type: "text", disabled: true },
  ];
}

function getDriverFromDetails(details) {
  const driver = {};
  details.forEach((detail) => {
    driver[detail.key] = "";
  });
  return driver;
}

export default {
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const isNew = computed(() => route.params.driverId === "new");
    const details = getDriverDetails();

    const driver = ref(getDriverFromDetails(details));
    const company = ref({});

    onMounted(async () => {
      company.value = await store.dispatch(
        "getCompany",
        route.params.companyId,
      );
    });

    async function refreshChangelog() {
      changelogRefresh.value = true;
      changelog.value = [];
      changelog.value = await store.dispatch("getDriverChangelog", {
        driverId: route.params.driverId,
        companyId: route.params.companyId,
      });
      changelogRefresh.value = false;
    }

    const changelog = ref([]);
    const changelogRefresh = ref(false);
    onMounted(refreshChangelog);

    const thumbnailFileInput = ref(null);
    const thumbnailSrc = ref(null);

    onMounted(() => {
      if (thumbnailFileInput.value) {
        thumbnailFileInput.value.addEventListener("change", async () => {
          const file = [...thumbnailFileInput.value.files].pop();
          if (file) {
            if (getMegabytesFromBytes(file.size) < 5) {
              const baseString = await getBase64(file);
              thumbnailSrc.value = baseString;
              toast("Saving thumbnail.");
              try {
                driver.value = await store.dispatch("saveDriverThumbnail", {
                  ...driver.value,
                  companyId: company.value.id,
                  thumbnail: thumbnailSrc.value,
                });
                store.dispatch("getDrivers");
                toast.clear();
                toast("Thumbnail saved.");
              } catch (err) {
                const matchExp =
                  /The value of property "thumbnail" is longer than \d+ bytes/;
                if (err.message.match(matchExp)) {
                  toast.clear();
                  toast("File too large!");
                } else {
                  toast.clear();
                  toast("Failed to upload file.");
                  throw err;
                }
              }
            } else {
              toast.clear();
              toast("File too large!");
            }
            thumbnailFileInput.value.value = "";
          }
        });
      }
    });

    onMounted(async () => {
      if (isNew.value) {
        driver.value.insuranceCriteriaStatus = "Under Review";
        driver.value.status = "Requested";
      } else {
        driver.value = await store.dispatch("getDriver", {
          companyId: route.params.companyId,
          driverId: route.params.driverId,
        });
      }
    });

    watch(driver, () => {
      driver.value.companyName = company.value.businessLegalName;
      driver.value.dateOfBirth = driver.value.dateOfBirth
        ? moment(driver.value.dateOfBirth).format("yyyy-MM-DD")
        : "";
      driver.value.dateOfHire = driver.value.dateOfHire
        ? moment(driver.value.dateOfHire).format("yyyy-MM-DD")
        : "";
      driver.value.modified = driver.value.modified
        ? moment(driver.value.modified).format("yyyy-MM-DD")
        : "";
      driver.value.created = driver.value.created
        ? moment(driver.value.created).format("yyyy-MM-DD")
        : "";
      thumbnailSrc.value = driver.value.thumbnail;
    });

    return {
      driver,
      company,
      details,
      isNew,
      truckingAccountType: process.env["VUE_APP_ACCOUNT_TYPE_TRUCKING"],
      viewSafetyLogs() {
        const ktLink = `https://web.keeptruckin.com/en-US/#/safety/events;driver_ids=${driver.value.keeptruckinId};only_with_videos=false;all_drive_events=false`;
        window.open(ktLink, "_blank");
      },
      thumbnailFileInput,
      thumbnailSrc,
      clickThumbnail() {
        if (thumbnailFileInput.value) {
          thumbnailFileInput.value.click();
        }
      },
      refreshChangelog,
      changelogRefresh,
      changelogColumns: [
        { name: "id", hidden: true },
        { name: "Change", key: "change" },
        { name: "Changed By", id: "email" },
        { name: "Created", id: "created" },
      ],
      changelog: computed(() => {
        return changelog.value.map((change) => {
          return {
            id: change.id,
            change: getChangelogDescription(change),
            email: change.actor,
            created: moment.utc(change.timestamp).local().format("yyyy-MM-DD"),
          };
        });
      }),
      criteriaStatus() {
        if (driver.value.insuranceCriteriaStatus === "Under Review") {
          driver.value.status = "Requested";
        }
        if (driver.value.insuranceCriteriaStatus !== "Under Review") {
          driver.value.status = "Inactive";
        }
      },
      copyCustomerLink() {
        const hostname = window.location.origin.replace("paddocks", "app");
        navigator.clipboard.writeText(
          `${hostname}/drivers/${driver.value.entityId}`,
        );
        toast("Customer driver link copied.");
      },
      saveDriver() {
        if (!driver.value.firstName || !driver.value.lastName) {
          alert("Missing information.");
          return;
        }
        store
          .dispatch("saveDriver", {
            ...driver.value,
            dateOfBirth: moment(driver.value.dateOfBirth).utc().format(),
            dateOfHire: moment(driver.value.dateOfHire).utc().format(),
            companyId: company.value.id,
          })
          .then((res) => {
            toast("Driver saved.");
            driver.value = res;
            router.replace({
              name: "Driver",
              params: { companyId: company.value.id, driverId: res.id },
            });
          })
          .catch(() => toast("Failed to save driver."));
      },
    };
  },
};
</script>
