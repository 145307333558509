import { createStore } from "vuex";
import { exportToCsv, getAccountTypes } from "@/utils";
import {
  dispatchEvent,
  generateApiKey,
  subscribeVehicleCollection,
  getAdditionalUsersForAccount,
  getClaim,
  getClaimAttachmentsList,
  getClaims,
  getCompanies,
  getCompany,
  getCompanyChangelog,
  getDriver,
  getDrivers,
  getDriversForCompany,
  getFileDownloadUrl,
  getInsuranceDocumentChangelog,
  getVehicle,
  getVehicleChangelog,
  getVehicleDocumentList,
  getVehiclesForCompany,
  saveClaim,
  saveCompany,
  saveDriver,
  saveVehicle,
  saveVehicleComments,
  saveVehicleDocument,
  saveFileMeta,
  saveVehicleUploadAttachment,
  addVehicleChangelog,
  saveVehicleSubscriptionStatus,
  getVehicleSubscriptionStatusHistory,
  subscribeVehicleStatusHistoryCollection,
  createCompany,
  getVehicleCoverageHistory,
  runManualInvoice,
  saveDriverThumbnail,
  saveVehicleThumbnail,
  getDriverChangelog,
  getSystemSettings,
  saveSystemSetting,
  saveCommentsForClaim,
  saveClaimDocument,
  removeDocument,
  getClaimChangelog,
  saveCompanySubscriptionSpots,
  saveVehicleInsuranceDocument,
  getVehicleInsuranceDocument,
  getVehicleInsuranceDocuments,
  getCompanyDocumentList,
  saveCompanyDocument,
  addCompanyChangelog,
  saveCompanyInsuranceDocument,
  getCompanyInsuranceDocument,
  getCompanyInsuranceDocuments,
  getEpisodesForCompany,
  getRentersForCompany,
  getReservationsForCompany,
  getEpisode,
  saveEpisode,
  saveCompanyInsuranceDocumentComments,
  saveSystemPolicy,
  getSystemPolicies,
  saveCommentsForCompany,
  getCompanyByTenantId,
  getUnderwritingResponse,
  getConnectionsForCompany,

  /* Functions */
  getBulkVehicles,
  saveVehicles,
  getStripeCustomer,
  activateAccount,
  runInvoiceDryRun,
  generateCertificate,
  createEpisodeFromEpisode,
  cancelEpisode,
  getPendingVehicles,
  searchVehicles,
  searchCompanies,
  getRecentCompanies,
  creditWalletFunds,
  addAdditionalUser,
  removeAdditionalUser,
  generateVehicleDocuments,
  getPolicyNumber,
  getOffRentMileage,
  getTelematicsAccountStatus,
  disconnectTelematicsIntegration,
  getVehicleAccountStatus,
} from "@/service";
import {
  listRecentCustomerUserIdentities,
  getUserByEmail,
  getUserComments,
  addUserComments,
  sendCustomerUserIdentityVerificationEmail,
  sendCustomerUserIdentityPasswordResetEmail,
  listPermissions,
  provisionCustomer,
  getCustomerUserAccountsByCustomerId,
  getCustomerUserAccountsByEmail,
  createCustomerUserAccount,
  deleteCustomerUserAccount,
  changeCustomerUserAccountAccessLevel,
  listCustomerUserAccountsChangelog,
} from "@/account-management";
import { getClaimsClient, getLossRunReport } from "./claims-service";
import { getIdentityToken } from "./auth0";

export default createStore({
  state() {
    return {
      sidebar: false,
      testMode: window.localStorage.getItem("test-mode") === "true",
      darkMode: window.localStorage.getItem("dark-mode") === "true",
      type: window.localStorage.getItem("paddocks-type"),
      accountTypes: getAccountTypes(),
      launchDarkly: null,
      loading: false,
      user: false,
      company: [],
      pendingVehicles: [],
      recentCompanies: [],
      driver: [],
      claim: [],
      isAuthLoading: true,
      isAuthenticated: false,
    };
  },
  mutations: {
    setLoading(state, load) {
      state.loading = load;
    },
    setUser(state, user) {
      state.user = user ? { ...user } : null;
    },
    setState(state, { key, value }) {
      state[key] = value;
    },
    setIsAuthLoading(state, value) {
      state.isAuthLoading = value;
    },
    setIsAuthenticated(state, value) {
      state.isAuthenticated = value;
    },
  },
  actions: {
    /**
     * Attempts to get a valid identity token, refreshing if needed.
     * Sets the isAuthenticated state value. All tokens should be
     * retrieved from the store so we can ensure the authentication
     * state is correct.
     * @param {ActionContext<any>} store
     * @returns
     */
    async getIdentityToken(store) {
      const token = await getIdentityToken();
      store.commit("setIsAuthenticated", token != null);
      return token;
    },
    async loadInitialResources({ commit, dispatch, state }, silent = false) {
      console.log("[STORE] refreshing");

      if (!silent) {
        commit("setLoading", true);
      }

      if (state.accountTypes.includes(state.type) && state.user) {
        try {
          await Promise.all([
            dispatch("getPendingVehicles"),
            dispatch("getRecentCompanies"),
          ]);
        } catch (ex) {
          console.log(ex);
        }
      }

      if (!silent) {
        commit("setLoading", false);
      }
    },
    logEvent({ state }, { name, data }) {
      /* Firebase Analytics */
      dispatchEvent(name, {
        application: "paddocks",
        sub: state.user?.sub,
        email: state.user?.email,
        ...(data || {}),
      });
    },
    getLaunchDarklyFlag({ state }, flag) {
      return state.launchDarkly?.variation(flag, false);
    },
    toggleTestMode({ state }) {
      window.localStorage.setItem("test-mode", !state.testMode);
      window.location.replace("/");
    },
    toggleDarkMode({ state }) {
      window.localStorage.setItem("dark-mode", !state.darkMode);
      window.location.replace("/");
    },
    subscribeVehicles(context, { companyId, subscription }) {
      subscribeVehicleCollection(companyId, subscription);
    },
    async initClaimsClient({ commit }) {
      const claimsClient = await getClaimsClient();
      commit("setState", {
        key: "claimsClient",
        value: claimsClient,
      });
    },
    async getCompanies({ state, commit }) {
      const companies = await getCompanies(state.testMode, state.type);
      commit("setState", { key: "company", value: companies || [] });
      return companies;
    },
    async searchVehicles({ state }, search) {
      const request = await searchVehicles(search, state.type, state.testMode);
      return request.data.results;
    },
    async searchCompanies({ state }, search) {
      const request = await searchCompanies(search, state.type, state.testMode);
      return request.data;
    },
    async getAdditionalUsersForAccount(store, accountId) {
      return getAdditionalUsersForAccount(accountId);
    },
    async getRecentCompanies({ state, commit }) {
      const response = await getRecentCompanies(
        state.testMode,
        state.type,
        state.testMode,
      );
      if (response.success) {
        const companies = response.data.companies;
        commit("setState", { key: "recentCompanies", value: companies || [] });
        return companies;
      }
      throw new Error(response.message);
    },
    async getPendingVehicles({ state, commit }) {
      const response = await getPendingVehicles({
        demo: state.testMode,
        mode: state.type,
      });
      if (response.success) {
        const vehicles = response.data.vehicles;
        commit("setState", { key: "pendingVehicles", value: vehicles || [] });
        return vehicles;
      }
      throw new Error(response.message);
    },
    async getDrivers({ state, commit }) {
      const drivers = await getDrivers(state.company);
      commit("setState", { key: "driver", value: drivers || [] });
      return drivers;
    },
    async getClaims({ state, commit }) {
      const claims = await getClaims(state.company);
      commit("setState", { key: "claim", value: claims || [] });
    },
    async setPaddocksType({ _, commit }, { type }) {
      commit("setState", { key: "type", value: type });
      window.localStorage.setItem("paddocks-type", type);
    },
    async getEpisodesForCompany(context, companyId) {
      return await getEpisodesForCompany(companyId);
    },
    async getRentersForCompany(context, companyId) {
      return await getRentersForCompany(companyId);
    },
    async getReservationsForCompany(context, companyId) {
      return await getReservationsForCompany(companyId);
    },
    async saveCommentsForCompany(context, { companyId, comments }) {
      return await saveCommentsForCompany(companyId, comments);
    },
    async getSystemPolicies() {
      return getSystemPolicies();
    },
    async saveSystemPolicy({ state }, policy) {
      return saveSystemPolicy({
        ...policy,
        type: state.type,
      });
    },
    async saveCompanyInsuranceDocumentComments(
      context,
      { companyId, documentId, comments },
    ) {
      return await saveCompanyInsuranceDocumentComments(
        companyId,
        documentId,
        comments,
      );
    },
    async saveEpisode(context, { companyId, episode }) {
      return await saveEpisode(companyId, episode);
    },
    async getEpisode(context, { companyId, episodeId }) {
      return await getEpisode(companyId, episodeId);
    },
    async exportGrid({ state, dispatch }, { type, companyId, strict }) {
      dispatch("logEvent", {
        name: companyId ? "company_export_grid" : "export_grid",
        data: { type },
      });

      const companyMap = (items) => {
        return items.map((item) => {
          const company = state.company.find(({ id }) => item.companyId === id);
          if (company) {
            return {
              ...item,
              companyName: company.businessLegalName,
              companyStatus: company.status,
              businessAddressLineOne: company.businessAddressLineOne,
              businessAddressLineTwo: company.businessAddressLineTwo,
              businessCity: company.businessCity,
              businessState: company.state,
              businessZipcode: company.businessZipcode,
            };
          } else {
            return item;
          }
        });
      };

      const vehicleMap = (items) => {
        return items.map((item) => {
          return {
            ...item,
            financeCompanyApplicable: item.financeCompanyApplicable
              ? "TRUE"
              : "FALSE",
          };
        });
      };

      switch (type) {
        case "company": {
          const companies = await getCompanies(state.testMode, state.type);
          const exportCompanies = companies.map((company) => {
            return { ...company };
          });
          exportToCsv("companies.csv", exportCompanies, [
            "id",
            "status",
            "businessLegalName",
            "businessPhone",
            "businessEmail",
            "billingEmail",
            "businessAddressLineOne",
            "businessAddressLineTwo",
            "businessCity",
            "state",
            "businessZipcode",
            "garagingAddressLineOne",
            "garagingAddressLineTwo",
            "garagingCity",
            "garagingState",
            "garagingZipcode",
            "einNumber",
            "mcNumber",
            "contactFirstName",
            "contactLastName",
            "contactNumber",
            "contactEmail",
            "contactLicense",
            "achPreferred",
            "autoCharge",
            "roadsideAssistancePrice",
            "episodePrice",
            "vehicleSubscriptionPrice",
            "stripeId",
            "stripeTestId",
            "serviceStart",
            "billingDate",
            "spots",
            "apiKey",
            "type",
          ]);
          break;
        }
        case "vehicle": {
          const vehicles = vehicleMap(await getVehiclesForCompany(companyId));
          const headers = [
            "companyId",
            "id",
            "companyName",
            "vin",
            "key",
            "plate",
            "year",
            "make",
            "model",
            "class",
            "color",
            "statedValue",
            "status",
            "insuranceCriteriaStatus",
            "created",
            "vehicleRegistrationState",
            "domicileState",
            "businessAddressLineOne",
            "businessAddressLineTwo",
            "businessCity",
            "businessState",
            "businessZipcode",
            "driverLicenseStateIssued",
            "registrantGovernmentId",
            "registrantGovernmentIdType",
            "registrantType",
            "registrationAddress",
            "registrationClass",
            "registrationName",
            "registrantFirstName",
            "registrantMiddleName",
            "registrantLastName",
            "registrantDateOfBirth",
            "registrationAddressLineOne",
            "registrationAddressLineTwo",
            "registrationCity",
            "registrationState",
            "registrationZipcode",
            "statedMileageRange",
            "telematicsDevice",
            "carsharingPlatform",
            "financeCompanyAddress",
            "financeCompanyName",
            "financeCompanyAddressLineOne",
            "financeCompanyAddressLineTwo",
            "financeCompanyCity",
            "financeCompanyState",
            "financeCompanyZipcode",
            "financeCompanyApplicable",
            "lotType",
            "lotName",
            "lotAddress",
            "lotAddressLineOne",
            "lotAddressLineTwo",
            "lotCity",
            "lotState",
            "lotZipcode",
            "override",
            "comments",
            "mileage",
          ].filter((h) => {
            const newFields = [
              "registrantType",
              "registrationClass",
              "registrantGovernmentId",
              "registrantGovernmentIdType",
              "driverLicenseStateIssued",
              "statedMileageRange",
              "telematicsDevice",
              "carsharingPlatform",
              "registrationName",
              "registrantFirstName",
              "registrantMiddleName",
              "registrantLastName",
              "registrantDateOfBirth",
            ];
            const deprecatedFields = [
              "override",
              "domicileState",
              "companyName",
              "businessAddressLineOne",
              "businessAddressLineTwo",
              "businessCity",
              "businessState",
              "businessZipcode",
              "registrationAddress",
              "statedValue",
              "financeCompanyAddress",
              "lotType",
              "lotName",
              "created",
            ];

            // Remove deprecated fields in strict mode.
            if (strict && deprecatedFields.includes(h)) {
              return false;
            }

            // Remove new fields if strict mode not enabled.
            if (!strict && newFields.includes(h)) {
              return true;
            }

            return true;
          });
          exportToCsv("vehicles.csv", companyMap(vehicles), headers);
          break;
        }
        case "driver": {
          const drivers = await getDrivers(state.company);
          exportToCsv(
            "drivers.csv",
            companyMap(
              companyId
                ? drivers.filter((d) => d.companyId === companyId)
                : drivers,
            ),
          );
          break;
        }
        case "unresolved": {
          const claims = await getClaims(state.company);
          const unresolved = claims.filter(
            (claim) => !(claim.status === "Resolved"),
          );
          exportToCsv(
            "claims.csv",
            companyMap(
              companyId
                ? unresolved.filter((c) => c.companyId === companyId)
                : unresolved,
            ),
          );
          break;
        }
        case "claim": {
          const claims = await getClaims(state.company);
          exportToCsv(
            "claims.csv",
            companyMap(
              companyId
                ? claims.filter((c) => c.companyId === companyId)
                : claims,
            ),
          );
          break;
        }
        default:
          return false;
      }
    },
    async saveCompany({ state, commit }, company) {
      company.modifiedBy = state.user.email;
      const save = await saveCompany(company);
      commit("setState", {
        key: "company",
        value: state.company.map((comp) => {
          if (comp.id === save.id) {
            return save;
          }
          return comp;
        }),
      });
      return save;
    },
    async getTelematicsAccountStatus(context, entityId) {
      const result = await getTelematicsAccountStatus(entityId);
      return result;
    },
    async getVehicleAccountStatus(context, entityId) {
      return await getVehicleAccountStatus(entityId);
    },
    async saveVehicle({ state, dispatch }, vehicle) {
      if (vehicle.id) {
        dispatch("logEvent", { name: "save_vehicle" });
      } else {
        dispatch("logEvent", { name: "save_new_vehicle" });
      }
      vehicle.modifiedBy = state.user.email;
      const save = await saveVehicle(vehicle);
      return save;
    },
    async saveVehicleThumbnail(context, vehicle) {
      return await saveVehicleThumbnail(vehicle);
    },
    async getVehiclesForCompany(context, companyId) {
      const vehicles = await getVehiclesForCompany(companyId);
      return vehicles;
    },
    async getDriversForCompany(context, companyId) {
      const drivers = await getDriversForCompany(companyId);
      return drivers;
    },
    async getVehicle(context, { companyId, vehicleId }) {
      const vehicle = await getVehicle(companyId, vehicleId);
      return vehicle;
    },
    async getDriver(context, { companyId, driverId }) {
      const driver = await getDriver(companyId, driverId);
      return driver;
    },
    async saveDriver({ state }, driver) {
      driver.modifiedBy = state.user.email;
      const save = await saveDriver(driver);
      return save;
    },
    async getDriverChangelog(context, { driverId, companyId }) {
      return await getDriverChangelog(driverId, companyId);
    },
    async saveDriverThumbnail(context, driver) {
      const save = await saveDriverThumbnail(driver);
      return save;
    },
    async getCompany(context, id) {
      const company = await getCompany(id);
      return company;
    },
    async getTenant(context, tenantId) {
      const company = await getCompanyByTenantId(tenantId);
      return company;
    },
    async getCompanyChangelog(context, id) {
      const changelog = await getCompanyChangelog(id);
      return changelog;
    },
    async generateApiKey({ dispatch }, companyId) {
      await generateApiKey(companyId);
      const company = await dispatch("getCompany", companyId);
      return company;
    },
    async getClaimChangelog(context, { claimId, companyId }) {
      const changelog = await getClaimChangelog(claimId, companyId);
      return changelog;
    },
    async getVehicleChangelog(context, { vehicleId, companyId }) {
      const changelog = await getVehicleChangelog(vehicleId, companyId);
      return changelog;
    },
    async getInsuranceDocumentChangelog(_context, { documentId, companyId }) {
      const changelog = await getInsuranceDocumentChangelog(
        documentId,
        companyId,
      );
      return changelog;
    },
    async getSystemSettings() {
      return await getSystemSettings();
    },
    async saveSystemSetting(context, { key, value }) {
      return await saveSystemSetting(key, value);
    },
    async saveClaimDocument({ dispatch }, { accountId, claimId, file }) {
      dispatch("logEvent", { name: "upload_claim_document" });
      return await saveClaimDocument(accountId, claimId, file);
    },
    async saveCommentsForClaim({ dispatch }, { companyId, claimId, comments }) {
      dispatch("logEvent", { name: "save_claim_comment" });
      const save = await saveCommentsForClaim(companyId, claimId, comments);
      return save;
    },
    async saveClaim({ commit, state, dispatch }, claim) {
      dispatch("logEvent", { name: "save_claim" });
      const save = await saveClaim({
        ...claim,
        modifiedBy: state.user.email,
      });
      if (claim.id) {
        commit("setState", {
          key: "claim",
          value: state.claim.map((clm) => {
            if (clm.id === save.id) {
              const { companyId } = claim;
              return { companyId, ...save };
            }
            return clm;
          }),
        });
      } else {
        commit("setState", { key: "claim", value: [...state.claim, save] });
      }
      return save;
    },
    async getClaim({ commit, state }, { companyId, claimId }) {
      const claim = await getClaim(companyId, claimId);
      commit("setState", {
        key: "claim",
        value: state.claim.map((clm) => {
          if (clm.id === claim.id) {
            return claim;
          }
          return clm;
        }),
      });
      return claim;
    },
    async getClaimAttachmentsList(context, { companyId, claimId }) {
      const list = await getClaimAttachmentsList(companyId, claimId);
      return list;
    },
    async getVehicleDocumentList(context, { accountId, vehicleId }) {
      const list = await getVehicleDocumentList(accountId, vehicleId);
      return list;
    },
    async getCompanyDocumentList(context, { accountId }) {
      const list = await getCompanyDocumentList(accountId);
      return list;
    },
    async getFileDownloadUrl(context, path) {
      const url = await getFileDownloadUrl(path);
      return url;
    },
    async saveFileMeta(context, { filePath, meta }) {
      return await saveFileMeta(filePath, meta);
    },
    async saveCompanyDocument({ dispatch }, { accountId, file }) {
      dispatch("logEvent", { name: "upload_company_document" });
      return await saveCompanyDocument(accountId, file);
    },
    async saveVehicleDocument({ dispatch }, { accountId, vehicleId, file }) {
      dispatch("logEvent", { name: "upload_vehicle_document" });
      return await saveVehicleDocument(accountId, vehicleId, file);
    },
    async addCompanyChangelog(
      { state },
      { accountId, eventType, entityId, fieldName, oldValue, newValue },
    ) {
      return await addCompanyChangelog(
        accountId,
        eventType,
        entityId,
        fieldName,
        oldValue,
        newValue,
        state.user.email,
      );
    },
    async addVehicleChangelog(
      { state },
      {
        accountId,
        vehicleId,
        eventType,
        entityId,
        fieldName,
        oldValue,
        newValue,
      },
    ) {
      return await addVehicleChangelog(
        accountId,
        vehicleId,
        eventType,
        entityId,
        fieldName,
        oldValue,
        newValue,
        state.user.email,
      );
    },
    async saveVehicleUploadAttachment({ dispatch }, { accountId, file }) {
      dispatch("logEvent", { name: "vehicle_bulk_import" });
      return await saveVehicleUploadAttachment(accountId, file);
    },
    async saveVehicleComments(context, { companyId, vehicleId, comments }) {
      return await saveVehicleComments(companyId, vehicleId, comments);
    },
    async saveVehicleSubscriptionStatus(
      context,
      { accountId, vehicleId, status },
    ) {
      return await saveVehicleSubscriptionStatus(accountId, vehicleId, status);
    },
    async getVehicleSubscriptionStatusHistory(
      context,
      { companyId, vehicleId, vehicleEntityId },
    ) {
      const isFeatureFlagOn = await this.dispatch(
        "getLaunchDarklyFlag",
        "policy-service-vehicle-coverage",
      );

      if (isFeatureFlagOn)
        return await getVehicleCoverageHistory(
          vehicleEntityId,
          companyId,
          vehicleId,
        );
      else
        return await getVehicleSubscriptionStatusHistory(companyId, vehicleId);
    },
    subscribeVehicleStatusHistoryCollection(
      context,
      { companyId, vehicleId, sub },
    ) {
      return subscribeVehicleStatusHistoryCollection(companyId, vehicleId, sub);
    },
    async getBulkVehicles(context, { filePath, contentType, strict }) {
      return await getBulkVehicles(filePath, contentType, strict);
    },
    async saveVehicles(context, { vehicles, accountId, strict }) {
      return await saveVehicles(accountId, vehicles, strict);
    },
    async creditWalletFunds(_context, { creditInfo }) {
      return await creditWalletFunds(creditInfo);
    },
    async getStripeCustomer({ state }, stripeId) {
      return await getStripeCustomer(stripeId, state.testMode, state.type);
    },
    async createEpisodeFromEpisode(
      { state },
      { accountId, entityId, paymentIntent, days },
    ) {
      return await createEpisodeFromEpisode(
        accountId,
        entityId,
        paymentIntent,
        days,
        state.testMode,
        state.type,
      );
    },
    async cancelEpisode({ state }, { accountId, entityId }) {
      return await cancelEpisode(
        accountId,
        entityId,
        state.testMode,
        state.type,
      );
    },
    async createCompany({ state, commit }, company) {
      function getDefaultAccountStatus(accountType) {
        switch (accountType) {
          case process.env["VUE_APP_ACCOUNT_TYPE_CAR_RENTAL"]: {
            return "Onboarding";
          }
          case process.env["VUE_APP_ACCOUNT_TYPE_TRUCKING"]:
          default: {
            return "Inactive";
          }
        }
      }

      const save = await createCompany({
        demo: state.testMode,
        modifiedBy: state.user.email,
        type: state.type,
        status: getDefaultAccountStatus(state.type),
        ...company,
      });
      commit("setState", {
        key: "company",
        value: [...state.company, save],
      });
      return save;
    },
    async getCompanyInsuranceDocuments(contact, { companyId }) {
      return await getCompanyInsuranceDocuments(companyId);
    },
    async getVehicleInsuranceDocuments(context, { companyId, vehicleId }) {
      return await getVehicleInsuranceDocuments(companyId, vehicleId);
    },
    async getVehicleInsuranceDocument(
      context,
      { companyId, vehicleId, insuranceDocumentId },
    ) {
      return await getVehicleInsuranceDocument(
        companyId,
        vehicleId,
        insuranceDocumentId,
      );
    },
    async saveCompanyInsuranceDocument(
      context,
      { companyId, insuranceDocument },
    ) {
      return await saveCompanyInsuranceDocument(companyId, insuranceDocument);
    },
    async saveVehicleInsuranceDocument(
      context,
      { companyId, vehicleId, insuranceDocument },
    ) {
      return await saveVehicleInsuranceDocument(
        companyId,
        vehicleId,
        insuranceDocument,
      );
    },
    async getCompanyInsuranceDocument(
      context,
      { companyId, insuranceDocumentId },
    ) {
      return await getCompanyInsuranceDocument(companyId, insuranceDocumentId);
    },
    async activateAccount(context, activation) {
      return await activateAccount(activation);
    },
    async runInvoiceDryRun() {
      return await runInvoiceDryRun();
    },
    async generateCertificate(context, data) {
      return await generateCertificate(data);
    },
    async generateVehicleDocuments(context, data) {
      return await generateVehicleDocuments(data);
    },
    async getPolicyNumber(context, data) {
      return await getPolicyNumber(data);
    },
    async getOffRentMileage(context, request) {
      return await getOffRentMileage(
        request.vin,
        request.startDate,
        request.endDate,
        request.tenantId,
      );
    },
    async disconnectTelematicsIntegration(context, { tenantId, provider }) {
      return await disconnectTelematicsIntegration(tenantId, provider);
    },
    async manualInvoice(context, { accountId, test }) {
      await runManualInvoice(accountId, test);
    },
    async removeDocument(context, { path }) {
      await removeDocument(path);
    },
    async saveCompanySubscriptionSpots({ state }, { company }) {
      return await saveCompanySubscriptionSpots({
        ...company,
        modifiedBy: state.user.email,
      });
    },
    async addAdditionalUser(_store, user) {
      return await addAdditionalUser(user);
    },
    async removeAdditionalUser(_store, user) {
      return await removeAdditionalUser(user);
    },
    async getConnectionsForCompany(_store, companyId) {
      return await getConnectionsForCompany(companyId);
    },
    async getUnderwritingResponse(_store, { inputs }) {
      return await getUnderwritingResponse(inputs.value);
    },
    async listRecentCustomerUserIdentities(_store, { limit }) {
      return await listRecentCustomerUserIdentities(limit);
    },
    async sendCustomerUserIdentityPasswordResetEmail(_store, { email }) {
      return await sendCustomerUserIdentityPasswordResetEmail(email);
    },
    async sendCustomerUserIdentityVerificationEmail(_store, { email }) {
      return await sendCustomerUserIdentityVerificationEmail(email);
    },
    async getUserByEmail(_store, { email }) {
      return await getUserByEmail(email);
    },
    async getUserComments(_store, { email }) {
      return await getUserComments(email);
    },
    async addUserComments(_store, { email, comments }) {
      return await addUserComments(email, comments);
    },
    async listPermissions() {
      return await listPermissions();
    },
    async provisionCustomer(_store, { customerId, ownerEmailAddress }) {
      return await provisionCustomer(customerId, ownerEmailAddress);
    },
    async getCustomerUserAccountsByEmail(_store, { email }) {
      return await getCustomerUserAccountsByEmail(email);
    },
    async getCustomerUserAccountsByCustomerId(_store, { customerId }) {
      return await getCustomerUserAccountsByCustomerId(customerId);
    },
    async createCustomerUserAccount(
      _store,
      { customerId, emailAddress, accessLevel },
    ) {
      return await createCustomerUserAccount({
        customerId,
        emailAddress,
        accessLevel,
      });
    },
    async deleteCustomerUserAccount(_store, { customerId, emailAddress }) {
      return await deleteCustomerUserAccount(customerId, emailAddress);
    },
    async changeCustomerUserAccountAccessLevel(
      _store,
      { customerId, email, accessLevel },
    ) {
      return await changeCustomerUserAccountAccessLevel(
        customerId,
        email,
        accessLevel,
      );
    },
    async listCustomerUserAccountsChangelog(_store, { email }) {
      return await listCustomerUserAccountsChangelog(email);
    },
    async getLossRunReport({ state }, { tenantId }) {
      return await getLossRunReport(state.claimsClient, tenantId);
    },
  },
});
