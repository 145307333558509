<template>
  <div class="w-full flex flex-col items-center justify-center md:pt-8">
    <div class="w-full bg-white p-4 rounded-lg lg:w-2/3">
      <div class="flex justify-between items-start">
        <div>
          <h1 class="text-gray-800 font-bold text-2xl">
            {{ policy.name }}
          </h1>
          <router-link
            class="text-gray-600 font-bold hover:underline"
            :to="{
              name: 'Company',
              params: {
                id: company.id,
              },
            }"
            v-if="company"
          >
            {{ company.businessLegalName }}
          </router-link>
          <span class="text-gray-600 font-bold" v-else>
            {{ policy.policyNumber }}
          </span>
        </div>
      </div>
      <div class="mt-1">
        <form @submit.prevent="savePolicy">
          <div class="grid grid-cols-1 md:grid-cols-2">
            <div class="m-1" v-for="detail in policyDetails" :key="detail.key">
              <label class="font-bold text-gray-800">
                {{ detail.label }}
              </label>
              <br />
              <select
                v-if="detail.key === 'carrier'"
                v-model="policy[detail.key]"
              >
                <option value="assurant">Assurant</option>
                <option value="united">United</option>
                <option value="oxford">Oxford</option>
              </select>
              <select
                v-else-if="detail.key === 'broker'"
                v-model="policy[detail.key]"
              >
                <option value="aon">AON</option>
                <option value="abi">ABI</option>
                <option value="apt">APT</option>
              </select>
              <select
                v-else-if="detail.key === 'coverageUse'"
                v-model="policy[detail.key]"
              >
                <option value="on-rent">On Rent</option>
                <option value="off-rent">On Lot</option>
                <option value="traditional">Traditional</option>
              </select>
              <select
                v-else-if="detail.key === 'groupPolicy'"
                v-model="policy[detail.key]"
              >
                <option :value="true">This Is A Group Policy</option>
                <option :value="false">This Is Not A Group Policy</option>
              </select>
              <input
                :type="detail.type"
                :disabled="detail.disabled"
                :required="detail.required"
                v-model="policy[detail.key]"
                v-else
              />
            </div>
          </div>
          <div class="m-1">
            <label class="font-bold text-gray-800"> Comments </label>
            <br />
            <textarea row="3" v-model="policy.comments" />
          </div>
          <div class="m-1 p-4 bg-gray-50 rounded-md">
            <h3 class="font-bold text-lg text-gray-800">ABI Integration</h3>
            <hr class="my-2" />
            <div class="flex flex-col md:flex-row">
              <div class="w-full m-1">
                <label class="text-gray-800 font-bold"> Policy Number </label>
                <br />
                <input type="text" v-model="policy.abiPolicyNumber" />
              </div>
              <div class="w-full m-1">
                <label class="text-gray-800 font-bold"> Sequence Number </label>
                <br />
                <input type="text" v-model="policy.abiSequenceNumber" />
              </div>
            </div>
          </div>
          <div class="flex justify-end flex-col mt-2 md:flex-row">
            <button
              type="button"
              class="bg-gray-100 mr-0 hover:bg-gray-200 md:mr-1"
              @click="$router.go(-1)"
            >
              Cancel
            </button>
            <button
              type="submit"
              class="bg-lula-gradient text-white mt-1 cursor-pointer hover:bg-lula-gradient-alt md:mt-0"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { useToast } from "vue-toastification";
import moment from "moment";

const policyDetails = [
  {
    key: "policyNumber",
    label: "Policy Number",
    type: "text",
    disabled: true,
    required: true,
  },
  {
    key: "name",
    label: "Policy Name",
    type: "text",
    disabled: false,
    required: true,
  },
  {
    key: "groupPolicy",
    label: "Group Policy",
    type: "text",
    disabled: false,
    required: true,
  },
  {
    key: "coverageUse",
    label: "Coverage Use",
    type: "select",
    disabled: false,
    required: true,
  },
  {
    key: "broker",
    label: "Broker",
    type: "text",
    disabled: false,
    required: true,
  },
  {
    key: "carrier",
    label: "Carrier",
    type: "text",
    disabled: false,
    required: true,
  },
  {
    key: "effectiveStart",
    label: "Effective Start",
    type: "date",
    disabled: false,
    required: true,
  },
  {
    key: "effectiveEnd",
    label: "Effective End",
    type: "date",
    disabled: false,
    required: true,
  },
  {
    key: "created",
    label: "Created",
    type: "date",
    disabled: true,
    required: false,
  },
  {
    key: "modified",
    label: "Modified",
    type: "date",
    disabled: true,
    required: false,
  },
];

export default {
  setup() {
    const store = useStore();
    const route = useRoute();
    const toast = useToast();

    const policy = ref({});
    const company = ref({});

    onMounted(async () => {
      const policies = await store.dispatch("getSystemPolicies");
      policy.value = {
        broker: "apt",
        carrier: "assurant",
        coverageUse: "off-rent",
        groupPolicy: true,
        ...policy.value,
        ...(policies.find((p) => p.id === route.params.policyId) || {}),
      };
      policy.value = {
        ...policy.value,
        created: moment.utc(policy.value.created).format("yyyy-MM-DD"),
        modified: moment.utc(policy.value.modified).format("yyyy-MM-DD"),
      };
      company.value = await store.dispatch("getCompany", policy.value.account);
    });

    return {
      policy,
      policyDetails,
      company,
      async savePolicy() {
        toast("Saving policy");
        try {
          policy.value = await store.dispatch("saveSystemPolicy", {
            ...policy.value,
          });
          policy.value = {
            ...policy.value,
            created: moment.utc(policy.value.created).format("yyyy-MM-DD"),
            modified: moment.utc(policy.value.modified).format("yyyy-MM-DD"),
          };
          toast.clear();
          toast("Policy saved.");
        } catch (err) {
          console.log(err);
          toast.clear();
          toast("Failed to save policy.");
        }
      },
    };
  },
};
</script>
