import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent, setUserId } from "firebase/analytics";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  onSnapshot,
  orderBy,
  query,
  updateDoc,
  where,
  connectFirestoreEmulator,
} from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import {
  getStorage,
  ref,
  listAll,
  getDownloadURL,
  uploadBytes,
  deleteObject,
  getMetadata,
  updateMetadata,
} from "firebase/storage";
import store from "./store";

function purgeUndefined(doc) {
  const purged = {};
  Object.keys(doc).forEach((key) => {
    if (!(doc[key] === undefined)) {
      purged[key] = doc[key];
    }
  });
  return purged;
}

function getAccessToken() {
  const auth = getAuth();
  return auth.currentUser?.accessToken || null;
}

async function getIdToken() {
  const auth = getAuth();
  return (await auth.currentUser?.getIdToken()) || null;
}

function onAuthChange(change = () => {}) {
  const auth = getAuth();
  onAuthStateChanged(auth, change);
}

function getCollectionAscending(col, orderByField = "created") {
  // eslint-disable-next-line
  return new Promise(async (resolve, reject) => {
    if (col) {
      try {
        const db = getFirestoreInstance();
        const docs = [];
        const querySnapshot = await getDocs(
          query(collection(db, col), orderBy(orderByField, "asc")),
        );
        if (querySnapshot.empty) {
          resolve([]);
        } else {
          let idx = 0;
          querySnapshot.forEach((doc) => {
            idx += 1;
            docs.push({ id: doc.id, ...doc.data() });
            if (idx === querySnapshot.size || idx === querySnapshot.size - 1) {
              resolve(docs);
            }
          });
        }
      } catch (err) {
        reject(err);
      }
    } else {
      reject(Error("No collection specified."));
    }
  });
}

function getCollectionDescending(col, orderByField = "created") {
  // eslint-disable-next-line
  return new Promise(async (resolve, reject) => {
    if (col) {
      try {
        const db = getFirestoreInstance();
        const docs = [];
        const querySnapshot = await getDocs(
          query(collection(db, col), orderBy(orderByField, "desc")),
        );
        if (querySnapshot.empty) {
          resolve([]);
        } else {
          let idx = 0;
          querySnapshot.forEach((doc) => {
            idx += 1;
            docs.push({ id: doc.id, ...doc.data() });
            if (idx === querySnapshot.size || idx === querySnapshot.size - 1) {
              resolve(docs);
            }
          });
        }
      } catch (err) {
        reject(err);
      }
    } else {
      reject(Error("No collection specified."));
    }
  });
}

function getCollection(col) {
  // eslint-disable-next-line
  return new Promise(async (resolve, reject) => {
    if (col) {
      try {
        const db = getFirestoreInstance();
        const docs = [];
        const querySnapshot = await getDocs(collection(db, col));
        if (querySnapshot.empty) {
          resolve([]);
        } else {
          let idx = 0;
          querySnapshot.forEach((doc) => {
            idx += 1;
            docs.push({ id: doc.id, ...doc.data() });
            if (idx === querySnapshot.size || idx === querySnapshot.size - 1) {
              resolve(docs);
            }
          });
        }
      } catch (err) {
        reject(err);
      }
    } else {
      reject(Error("No collection specified."));
    }
  });
}

function getCollectionWhere(col, key, op, value) {
  // eslint-disable-next-line
  return new Promise(async (resolve, reject) => {
    if (col) {
      try {
        const db = getFirestoreInstance();
        const constraint = where(key, op, value);
        const ref = collection(db, col);
        const q = query(ref, constraint);
        const snapshot = await getDocs(q);
        resolve(snapshot.docs.map((d) => ({ ...d.data(), id: d.id })));
      } catch (err) {
        reject(err);
      }
    } else {
      reject(Error("No collection specified."));
    }
  });
}

async function getDocument(path) {
  const db = getFirestoreInstance();
  const snap = await getDoc(doc(db, path));
  return snap.data();
}

async function saveDocument(path, data) {
  const db = getFirestoreInstance();
  const record = await getDocument(path);
  await updateDoc(doc(db, path), purgeUndefined({ ...record, ...data }));
  return await getDocument(path);
}

async function createDocument(path, data) {
  const db = getFirestoreInstance();
  const ref = await addDoc(collection(db, path), purgeUndefined(data));
  const doc = await getDocument(`${path}/${ref.id}`);
  return { id: ref.id, ...doc };
}

function subscribeDocument(path, sub) {
  const db = getFirestoreInstance();
  onSnapshot(doc(db, path), sub);
}

function subscribeCollection(path, sub) {
  const db = getFirestoreInstance();
  onSnapshot(collection(db, path), async () => {
    sub(await getCollection(path));
  });
}

function getFileNameList(path) {
  return new Promise((resolve, reject) => {
    const storage = getStorage();
    const listRef = ref(storage, path);
    listAll(listRef)
      .then(async (res) => {
        if (res.items.length) {
          resolve(
            await Promise.all(res.items.map((fileRef) => getMetadata(fileRef))),
          );
        } else {
          resolve([]);
        }
      })
      .catch((err) => reject(err));
  });
}

async function saveFileMeta(path, meta = {}) {
  const storage = getStorage();
  const storageRef = ref(storage, path);
  const update = await updateMetadata(storageRef, meta);
  return update;
}

async function saveFile(path, file) {
  const storage = getStorage();
  const storageRef = ref(storage, path);
  const snap = await uploadBytes(storageRef, file);
  return snap;
}

async function getFileDownloadUrl(path) {
  const storage = getStorage();
  const fileRef = ref(storage, path);
  const url = await getDownloadURL(fileRef);
  return url;
}

async function deleteFile(path) {
  const storage = getStorage();
  const fileRef = ref(storage, path);
  return await deleteObject(fileRef);
}

function dispatchEvent(eventName, data) {
  const analytics = getAnalytics(app);
  if (data.sub) {
    setUserId(analytics, data.sub);
  }
  logEvent(analytics, eventName, data);
}

let isFirestoreInstanceInitialized = false;

function initializeFirestoreInstance() {
  const firestoreInstance = getFirestore();

  if (process.env["VUE_APP_FIREBASE_FIRESTORE_USE_EMULATOR"] == "true") {
    const emulatorPort =
      +process.env["VUE_APP_FIREBASE_FIRESTORE_EMULATOR_PORT"];

    if (!emulatorPort) {
      throw Error(
        "[FB] Cannot connect to the Firestore Emulator. Reason: emulator port is not defined.",
      );
    }

    connectFirestoreEmulator(firestoreInstance, "localhost", emulatorPort);
    console.warn(
      `[FB] Firebase Firestore. Connected to the local emulator on port ${emulatorPort}`,
    );
  }

  isFirestoreInstanceInitialized = true;
  return firestoreInstance;
}

function getFirestoreInstance() {
  if (!isFirestoreInstanceInitialized) {
    return initializeFirestoreInstance();
  }
  return getFirestore();
}

/**
 *
 * @param {string} functionName
 * @param {unknown} data
 * @returns {Promise<{success: true, data?: any} | { success: false, message: string }>}
 */
const executeFirebaseFunction = async (functionName, request) => {
  const url = `${process.env["VUE_APP_FIREBASE_FUNCTION_SUBDOMAIN"]}/${functionName}`;
  const body =
    request != null ? JSON.stringify(purgeUndefined(request)) : undefined;
  const response = await fetch(url, {
    method: "POST",
    credentials: "same-origin",
    body,
    headers: {
      Authorization: `Bearer ${await store.dispatch("getIdentityToken")}`,
    },
  });
  if (!response.ok) {
    try {
      const json = await response.json();
      const { title, detail } = json;
      return { success: false, message: `${title}: ${detail}` };
    } catch (ex) {
      console.log("[FB] Error response was not valid json.");
      console.log(ex);
      const text = await response.text();
      return { success: false, message: text };
    }
  }
  if (response.status === 204) {
    return { success: true };
  }
  const data = await response.json();
  return { success: true, data };
};

/* Functions */
const prorateVehicle = (vehicle) =>
  executeFirebaseFunction("vehicle-prorateVehicle", vehicle);

// TODO: Once POL-194 is verified to work, remove `strict` flag and update function.
const getBulkVehicles = (fileName, contentType, strict) =>
  executeFirebaseFunction("vehicle-getBulkVehicles", {
    fileName,
    contentType,
    strict,
  });

const saveVehicles = (accountId, vehicles = [], strict = false) =>
  executeFirebaseFunction("vehicle-saveVehicles", {
    vehicles,
    accountId,
    strict,
  });

const getStripeCustomer = (stripeId, demo, type) =>
  executeFirebaseFunction("account-getStripeCustomer", {
    stripeId,
    demo,
    type,
  });

const createEpisodeFromEpisode = (accountId, entityId, paymentIntent, days) =>
  executeFirebaseFunction("account-createEpisodeFromEpisode", {
    accountId,
    entityId,
    paymentIntent,
    days,
  });

const cancelEpisode = (accountId, entityId) =>
  executeFirebaseFunction("account-cancelEpisode", { accountId, entityId });

const activateAccount = ({ companyId, effectiveDate, autoCharge }) =>
  executeFirebaseFunction("account-activateAccount", {
    accountId: companyId,
    effectiveDate,
    autoCharge,
  });

const runInvoiceDryRun = () =>
  executeFirebaseFunction("account-runInvoiceDryRun");

const getPendingVehicles = ({ demo, mode }) =>
  executeFirebaseFunction("paddocks-getPendingVehicles", { demo, type: mode });

const searchVehicles = (search, mode, demo) =>
  executeFirebaseFunction("paddocks-searchVehicles", { search, mode, demo });

const getRecentCompanies = async (demo, type) =>
  executeFirebaseFunction("paddocks-getRecentCompanies", { demo, type });

const searchCompanies = (search, type, demo) =>
  executeFirebaseFunction("paddocks-searchCompanies", {
    search,
    mode: type,
    demo,
  });

const creditWalletFunds = (creditInfo) =>
  executeFirebaseFunction("paddocks-creditWalletFunds", creditInfo);

const generateCertificate = (data) =>
  executeFirebaseFunction("vehicle-generateCertificate", {
    // ABI REQUEST
    account: data.account,
    vehicle: data.vehicle,

    // AON REQUEST
    vehicleId: data.id,
    accountId: data.accountId,
    vin: data.vin,
    name: data.certificateHolder,
    addressLineOne: data.addressLineOne,
    addressLineTwo: data.addressLineTwo || "",
    zipcode: data.zipcode,
    city: data.city,
    state: data.state,
    certificateState: data.certificateState,
    certificateType: data.certificateType,
  });

const addAdditionalUser = (user) =>
  executeFirebaseFunction("account-addAdditionalUser", user);

const removeAdditionalUser = (user) =>
  executeFirebaseFunction("account-removeAdditionalUser", user);
/* End Functions */

export {
  createDocument,
  getCollection,
  getCollectionAscending,
  getCollectionDescending,
  getCollectionWhere,
  getDocument,
  getFileDownloadUrl,
  getFileNameList,
  onAuthChange,
  saveDocument,
  deleteFile,
  saveFile,
  saveFileMeta,
  subscribeDocument,
  subscribeCollection,
  dispatchEvent,
  getAccessToken,
  getIdToken,

  /* Functions */
  prorateVehicle,
  getBulkVehicles,
  saveVehicles,
  getStripeCustomer,
  activateAccount,
  runInvoiceDryRun,
  generateCertificate,
  createEpisodeFromEpisode,
  cancelEpisode,
  getPendingVehicles,
  searchVehicles,
  getRecentCompanies,
  searchCompanies,
  addAdditionalUser,
  removeAdditionalUser,
  creditWalletFunds,
};

const app = initializeApp({
  apiKey: process.env["VUE_APP_FIREBASE_APIKEY"],
  authDomain: process.env["VUE_APP_FIREBASE_AUTH_DOMAIN"],
  projectId: process.env["VUE_APP_FIREBASE_PROJECT_ID"],
  storageBucket: process.env["VUE_APP_FIREBASE_STORAGE_BUCKET"],
  messagingSenderId: process.env["VUE_APP_FIREBASE_MESSAGING_SENDER_ID"],
  appId: process.env["VUE_APP_FIREBASE_APP_ID"],
  measurementId: process.env["VUE_APP_FIREBASE_MEASUREMENT_ID"],
});

export default app;
