import {
  activateAccount,
  addAdditionalUser,
  cancelEpisode,
  createDocument,
  createEpisodeFromEpisode,
  creditWalletFunds,
  deleteFile,
  dispatchEvent,
  generateCertificate,
  getBulkVehicles,
  getCollection,
  getCollectionDescending,
  getCollectionWhere,
  getDocument,
  getFileDownloadUrl,
  getFileNameList,
  getPendingVehicles,
  getRecentCompanies,
  getStripeCustomer,
  prorateVehicle,
  removeAdditionalUser,
  runInvoiceDryRun,
  saveDocument,
  saveFile,
  saveFileMeta,
  saveVehicles,
  searchCompanies,
  searchVehicles,
  subscribeCollection,
  subscribeDocument,
} from "@/firebase";
import { getCoveragesFromPolicy, isEmptyOrNaN } from "@/utils";
import moment from "moment";
import axios from "axios";
import store from "./store";

const { v4: uuidv4 } = require("uuid");

async function getCompanies(demo, type) {
  const accounts = await getCollection("accounts");
  return accounts
    .filter((a) => (demo ? a.demo === true : a.demo !== true))
    .filter((a) => a.type === type);
}

async function generateVehicleDocuments(data) {
  return axios({
    method: "POST",
    url: `${process.env["VUE_APP_POLICYMANAGEMENT_API"]}/generatedocuments`,
    data: {
      accountId: data.accountId,
      vehicleId: data.vehicleId,
      endDate: "",
      documentType: data.documentType,
    },
    headers: {
      Authorization: `Bearer ${await store.dispatch("getIdentityToken")}`,
    },
  });
}

async function getPolicyNumber(data) {
  try {
    const resp = await axios({
      method: "POST",
      url: `${process.env["VUE_APP_POLICYMANAGEMENT_API"]}/policynumber`,
      data: data,
      headers: {
        Authorization: `Bearer ${await store.dispatch("getIdentityToken")}`,
      },
    });
    return resp.data.policyNumber;
  } catch (error) {
    if (error.response) {
      // Request made and server responded
      console.error(
        `[SVC] Failed to get policy number with status ${error.response.status}. ${error.response.data}`,
      );
      return "";
    } else if (error.request) {
      // Request made but no response is received from the server.
      console.error(error.request);
    } else {
      // Error occured while setting up the request
      console.error("[SVC] Error", error.message);
    }
  }
}

async function processTelematicsRequest(requestCall) {
  try {
    const response = await requestCall();

    return {
      success: true,
      data: response.data,
    };
  } catch (error) {
    return {
      success: false,
      statusCode: error?.response?.status || 0,
      error: error?.response?.data || {},
    };
  }
}

async function disconnectTelematicsIntegration(tenantId, provider) {
  return await processTelematicsRequest(async () =>
    axios({
      method: "POST",
      url: `${process.env["VUE_APP_TELEMATICS_API"]}/tenants/${tenantId}/${provider}/disconnect`,
      headers: {
        Authorization: `Bearer ${await store.dispatch("getIdentityToken")}`,
      },
    }),
  );
}

async function getOffRentMileage(vin, startDate, endDate, tenantId) {
  return await processTelematicsRequest(async () =>
    axios({
      method: "GET",
      url: `${process.env["VUE_APP_TELEMATICS_API"]}/off-rent-mileage/${vin}`,
      params: {
        start_date: startDate,
        end_date: endDate,
        tenant_id: tenantId,
      },
      headers: {
        Authorization: `Bearer ${await store.dispatch("getIdentityToken")}`,
      },
    }),
  );
}

async function getTelematicsAccountStatus(accountEntityId) {
  return await processTelematicsRequest(async () =>
    axios({
      method: "GET",
      url: `${process.env["VUE_APP_TELEMATICS_API"]}/tenant-statuses/${accountEntityId}`,
      headers: {
        Authorization: `Bearer ${await store.dispatch("getIdentityToken")}`,
      },
    }),
  );
}

async function getVehicleAccountStatus(accountEntityId) {
  return await processTelematicsRequest(() =>
    axios({
      method: "GET",
      url: `${process.env["VUE_APP_TELEMATICS_API"]}/vehicles/${accountEntityId}`,
      withCredentials: true,
    }),
  );
}

async function getVehiclesForCompany(companyId) {
  const vehicles = await getCollection(`accounts/${companyId}/vehicles`);
  return vehicles.map((vin) => ({ ...vin, companyId }));
}
async function getDriversForCompany(companyId) {
  const drivers = await getCollection(`accounts/${companyId}/drivers`);
  return drivers.map((drv) => ({ ...drv, companyId }));
}

async function getClaimsForCompany(companyId) {
  const claims = await getCollection(`accounts/${companyId}/claims`);
  return claims.map((claim) => ({ ...claim, companyId }));
}

async function getEpisodesForCompany(companyId) {
  const episodes = await getCollectionDescending(
    `accounts/${companyId}/episodes`,
  );
  return episodes.map((episode) => ({ ...episode, companyId }));
}
async function getReservationsForCompany(companyId) {
  const reservations = await getCollection(
    `accounts/${companyId}/reservations`,
  );
  return reservations.map((reservation) => ({ ...reservation, companyId }));
}
async function getRentersForCompany(companyId) {
  const renters = await getCollection(`accounts/${companyId}/renters`);
  return renters.map((renter) => ({ ...renter, companyId }));
}

function getDrivers(accounts = []) {
  // eslint-disable-next-line
  return new Promise(async (resolve) => {
    const companies = accounts.length ? accounts : await getCompanies();
    const drivers = [];
    for (let idx = 0; idx < companies.length; idx++) {
      const company = companies[idx];
      const companyDrivers = await getDriversForCompany(company.id);
      companyDrivers.forEach((vin) => drivers.push(vin));
      if (idx === companies.length - 1) {
        resolve(drivers);
      }
    }
  });
}

function getClaims(accounts = []) {
  // eslint-disable-next-line
  return new Promise(async (resolve) => {
    const companies = accounts.length ? accounts : await getCompanies();
    const claims = [];
    for (let idx = 0; idx < companies.length; idx++) {
      const company = companies[idx];
      const companyClaims = await getClaimsForCompany(company.id);
      companyClaims.forEach((vin) => claims.push(vin));
      if (idx === companies.length - 1) {
        resolve(claims);
      }
    }
  });
}

async function getSystemSettings() {
  return await getCollection("settings");
}

async function saveSystemSetting(key, value) {
  const settings = await getCollection("settings");
  const setting = settings.find((x) => x.key === key);
  if (setting) {
    const save = await saveDocument(`settings/${setting.id}`, { key, value });
    return { ...save };
  } else {
    const save = await createDocument("settings", { key, value });
    return { ...save };
  }
}

async function saveCompanySubscriptionSpots(company) {
  const oldCompany = await getDocument(`accounts/${company.id}`);
  const save = await saveDocument(`accounts/${company.id}`, {
    spots: company.spots,
    modifiedBy: company.modifiedBy,
  });
  if (oldCompany.spots != save.spots) {
    await createDocument(`accounts/${company.id}/changelog`, {
      timestamp: moment.utc().format(),
      effective: moment.utc().format(),
      eventType: "account-modified",
      entityId: company.id,
      fieldName: "spots",
      oldValue: oldCompany.spots,
      newValue: company.spots,
      actor: company.modifiedBy,
    });
  }
  return save;
}

async function saveCompany(company) {
  const save = await saveDocument(`accounts/${company.id}`, {
    salesforceId: company.salesforceId,
    stripeId: company.stripeId,
    businessEmail: company.businessEmail,
    businessLegalName: company.businessLegalName,
    businessPhone: company.businessPhone,
    billingEmail: company.billingEmail,
    einNumber: company.einNumber,
    serviceAccount: company.serviceAccount,
    contactEmail: company.contactEmail.trim().toLocaleLowerCase(),
    contactLicense: company.contactLicense,
    contactFirstName: company.contactFirstName,
    contactLastName: company.contactLastName,
    contactNumber: company.contactNumber,
    contactPosition: company.contactPosition,
    defaultPolicy: company.defaultPolicy,
    roadsideAssistancePrice: isEmptyOrNaN(company.roadsideAssistancePrice)
      ? null
      : parseFloat(company.roadsideAssistancePrice).toFixed(2),
    vehicleSubscriptionPrice: isEmptyOrNaN(company.vehicleSubscriptionPrice)
      ? null
      : parseFloat(company.vehicleSubscriptionPrice).toFixed(2),
    episodePrice: isEmptyOrNaN(company.episodePrice)
      ? null
      : parseFloat(company.episodePrice).toFixed(2),
    businessAddressLineOne: company.businessAddressLineOne,
    businessAddressLineTwo: company.businessAddressLineTwo,
    businessCity: company.businessCity,
    businessZipcode: company.businessZipcode,
    garagingAddressLineOne: company.garagingAddressLineOne,
    garagingAddressLineTwo: company.garagingAddressLineTwo,
    garagingCity: company.garagingCity,
    garagingZipcode: company.garagingZipcode,
    garagingState: company.garagingState,
    abiIntegration: company.abiIntegration,
    state: company.state,
    demo: company.demo,
    onboardingScreen: company.onboardingScreen,
    autoCharge: company.autoCharge,
    achPreferred: company.achPreferred,
    enableOverride: company.enableOverride,
    serviceStart: moment(company.serviceStart).utc().format(),
    serviceAgreementSpots: Math.abs(company.serviceAgreementSpots),
    riskApiEnabled: company.riskApiEnabled,
    defaultReservationPlatform: company.defaultReservationPlatform,
    initialInvoice: company.initialInvoice,
    mcNumber: company.mcNumber,
    dotNumber: company.dotNumber,
    operationalSince: company.operationalSince,
    authorityType: company.authorityType,
    status: company.status,
    statusReason: company.statusReason,
    statusReasonDetails: company.statusReasonDetails,
    modifiedBy: company.modifiedBy,
  });
  return { id: company.id, ...save };
}

async function runManualInvoice(accountId, test = false) {
  await saveDocument(`accounts/${accountId}`, {
    invoice: false,
    testInvoice: false,
  });
  if (test) {
    const save = await saveDocument(`accounts/${accountId}`, {
      testInvoice: true,
    });
    return save;
  }
  const save = await saveDocument(`accounts/${accountId}`, { invoice: true });
  return save;
}

async function createCompany(company) {
  const save = await createDocument("accounts", {
    entityId: uuidv4(),
    stripeId: company.stripeId,
    salesforceId: company.salesforceId,
    initialInvoice: company.initialInvoice,
    billingEmail: company.billingEmail,
    businessEmail: "",
    businessLegalName: company.businessLegalName,
    contactEmail: company.contactEmail.trim().toLocaleLowerCase(),
    contactLicense: "",
    contactFirstName: company.contactFirstName,
    contactLastName: company.contactLastName,
    contactNumber: "",
    roadsideAssistancePrice: null,
    vehicleSubscriptionPrice: null,
    state: "",
    demo: company.demo,
    achPreferred: false,
    autoCharge: true,
    enableOverride: false,
    abiIntegration: false,
    billingDate: null,
    serviceStart: moment().utc().format(),
    serviceAgreementSpots: 0,
    status: company.status,
    type: company.type,
    statusReason: "Created in Paddocks",
    modifiedBy: company.modifiedBy,
  });
  return { ...save };
}

async function getCompanyByTenantId(tenantId) {
  const query = await getCollectionWhere(
    "accounts",
    "entityId",
    "==",
    tenantId,
  );
  const company = query.pop();
  return company ? { ...company, documentId: company.id, id: tenantId } : null;
}

async function getCompany(id) {
  const company = await getDocument(`accounts/${id}`);
  return { ...company, id };
}

async function getConnectionsForCompany(companyId) {
  const connections = await getCollection(`accounts/${companyId}/connections`);
  return connections.map((con) => ({ ...con, companyId }));
}

async function saveEpisode(companyId, episode) {
  const record = {
    vehicle: episode.vehicle,
    driver: episode.driver,
    startDate: episode.startDate,
    endDate: episode.endDate,
  };
  if (episode.id) {
    const save = await saveDocument(
      `accounts/${companyId}/episodes/${episode.id}`,
      record,
    );
    return { id: episode.id, ...save };
  } else {
    const save = await createDocument(`accounts/${companyId}/episodes`, record);
    return { id: episode.id, ...save };
  }
}

async function saveVehicle(vehicle) {
  const record = {
    registrationClass: (vehicle.registrationClass || "").toLowerCase(),
    registrantDateOfBirth: vehicle.registrantDateOfBirth,
    class: vehicle.class,
    color: vehicle.color,
    key: vehicle.key,
    make: vehicle.make,
    statedMileageRange: vehicle.statedMileageRange,
    model: vehicle.model,
    plate: vehicle.plate,
    statedValue: vehicle.statedValue,
    status: vehicle.status,
    insuranceCriteriaStatus: vehicle.insuranceCriteriaStatus,
    insuranceCriteriaReason: vehicle.insuranceCriteriaReason,
    vin: vehicle.vin.toUpperCase(),
    year: vehicle.year,
    vehicleRegistrationState: vehicle.vehicleRegistrationState,
    domicileState: vehicle.domicileState,
    override: isEmptyOrNaN(vehicle.override)
      ? null
      : parseFloat(vehicle.override || 0).toFixed(2),
    driverLicenseStateIssued: vehicle.driverLicenseStateIssued,
    registrantType: vehicle.registrantType,
    registrantGovernmentIdType: vehicle.registrantGovernmentIdType,
    registrantGovernmentId: vehicle.registrantGovernmentId,
    registrationName: vehicle.registrationName,
    registrantFirstName: vehicle.registrantFirstName,
    registrantMiddleName: vehicle.registrantMiddleName,
    registrantLastName: vehicle.registrantLastName,
    registrationAddress: vehicle.registrationAddress,
    registrationAddressLineOne: vehicle.registrationAddressLineOne,
    registrationAddressLineTwo: vehicle.registrationAddressLineTwo,
    registrationCity: vehicle.registrationCity,
    registrationState: vehicle.registrationState,
    registrationZipcode: vehicle.registrationZipcode,
    financeCompanyApplicable: vehicle.financeCompanyApplicable,
    financeCompanyName: vehicle.financeCompanyName,
    financeCompanyAddress: vehicle.financeCompanyAddress,
    financeCompanyAddressLineOne: vehicle.financeCompanyAddressLineOne,
    financeCompanyAddressLineTwo: vehicle.financeCompanyAddressLineTwo,
    financeCompanyCity: vehicle.financeCompanyCity,
    financeCompanyState: vehicle.financeCompanyState,
    financeCompanyZipcode: vehicle.financeCompanyZipcode,
    lotName: vehicle.lotName,
    lotType: vehicle.lotType,
    lotAddress: vehicle.lotAddress,
    lotAddressLineOne: vehicle.lotAddressLineOne,
    lotAddressLineTwo: vehicle.lotAddressLineTwo,
    lotCity: vehicle.lotCity,
    lotState: vehicle.lotState,
    lotZipcode: vehicle.lotZipcode,
    modifiedBy: vehicle.modifiedBy,
    mileage: vehicle.mileage,
  };

  const currentVehicle = await getDocument(
    `accounts/${vehicle.companyId}/vehicles/${vehicle.id}`,
  );
  if (
    (!currentVehicle || currentVehicle.status !== "Active") &&
    vehicle.status === "Active"
  ) {
    const account = await getDocument(`accounts/${vehicle.companyId}`);
    const vehicles = await getCollection(
      `accounts/${vehicle.companyId}/vehicles`,
    );
    if (
      account.status === "Active" &&
      vehicles.filter((v) => v.status === "Active").length + 1 > account.spots
    ) {
      const prorate = await prorateVehicle({
        accountId: vehicle.companyId,
        vehicleId: vehicle.id,
      });
      if (!prorate.success) {
        throw new Error("Failed to prorate vehicle");
      }
    }
  }

  if (vehicle.id) {
    const save = await saveDocument(
      `accounts/${vehicle.companyId}/vehicles/${vehicle.id}`,
      record,
    );
    return { id: vehicle.id, ...save };
  } else {
    const save = await createDocument(
      `accounts/${vehicle.companyId}/vehicles`,
      record,
    );
    return { id: vehicle.id, ...save };
  }
}

async function saveVehicleSubscriptionStatus(accountId, vehicleId, status) {
  const record = {
    status: status.status,
    start: status.start,
    end: status.end === null ? "" : status.end,
    policy: status.policy,
    vehicleRegistrationState: status.vehicleRegistrationState,
    entityId: status.entityId,
    archived: status.archived,
  };
  const policyQuery = await getCollectionWhere(
    "policies",
    "entityId",
    "==",
    record.policy,
  );
  const policy = policyQuery.pop();
  const fbCoverages = getCoveragesFromPolicy(policy.policyNumber);

  if (status.id) {
    const statusQuery = await getCollectionWhere(
      `accounts/${accountId}/vehicles/${vehicleId}/statusHistory`,
      "entityId",
      "==",
      record.entityId,
    );
    const firebaseStatus = statusQuery.pop();
    if (record.archived) {
      const save = await saveDocument(
        `accounts/${accountId}/vehicles/${vehicleId}/statusHistory/${firebaseStatus.id}`,
        record,
      );
      await axios({
        method: "DELETE",
        url: `${process.env["VUE_APP_POLICYMANAGEMENT_API"]}/vehiclecoverage/${status.entityId}`,
        headers: {
          Authorization: `Bearer ${await store.dispatch("getIdentityToken")}`,
        },
      });
      return { id: status.id, ...save };
    } else {
      try {
        await axios({
          method: "PUT",
          url: `${process.env["VUE_APP_POLICYMANAGEMENT_API"]}/vehicleCoverage`,
          data: {
            coverageEntityId: record.entityId,
            effectiveDate: record.start,
            endDate: record.end,
            registrationState: record.vehicleRegistrationState,
            coverages: fbCoverages,
          },
          headers: {
            Authorization: `Bearer ${await store.dispatch("getIdentityToken")}`,
          },
        });

        const save = await saveDocument(
          `accounts/${accountId}/vehicles/${vehicleId}/statusHistory/${firebaseStatus.id}`,
          record,
        );
        return { id: status.id, ...save };
      } catch (error) {
        console.error("[SVC] Error", error.message);
        return null;
      }
    }
  } else {
    record.entityId = uuidv4();
    const vehicle = await getDocument(
      `accounts/${accountId}/vehicles/${vehicleId}`,
    );
    const account = await getDocument(`accounts/${accountId}`);
    const data = {
      Vehicle: {
        AccountId: account.id,
        VehicleId: vehicle.id,
        Vin: vehicle.vin,
        Year: vehicle.year,
        Make: vehicle.make,
        Model: vehicle.model,
        RegistrationName: vehicle.registrationName,
        RegistrationAddressLineOne: vehicle.registrationAddressLineOne,
        RegistrationAddressLineTwo: vehicle.registrationAddressLineTwo,
        RegistrationZipcode: vehicle.registrationZipcode,
        RegistrationState: vehicle.registrationState,
        RegistrationCity: vehicle.registrationCity,
        FinanceCompanyName: vehicle.financeCompanyName,
        FinanceCompanyAddressLineOne: vehicle.financeCompanyAddressLineOne,
        FinanceCompanyAddressLineTwo: vehicle.financeCompanyAddressLineTwo,
        FinanceCompanyCity: vehicle.financeCompanyCity,
        FinanceCompanyState: vehicle.financeCompanyState,
        FinanceCompanyZipcode: vehicle.financeCompanyZipcode,
        Status: vehicle.status,
        VehicleRegistrationState: vehicle.vehicleRegistrationState,
        RegistrationClass: vehicle.registrationClass,
        EntityId: vehicle.entityId,
      },
      Account: {
        AccountId: account.id,
        BusinessLegalName: account.businessLegalName,
        BusinessAddressLineOne: account.businessAddressLineOne,
        BusinessAddressLineTwo: account.businessAddressLineTwo,
        BusinessCity: account.businessCity,
        BusinessState: account.state,
        BusinessZipcode: account.businessZipcode,
      },
    };

    try {
      await axios({
        method: "POST",
        url: `${process.env["VUE_APP_POLICYMANAGEMENT_API"]}/schedulevehicle`,
        data: {
          vehicleEntityId: vehicle.entityId,
          registrationState: record.vehicleRegistrationState,
          vehicleData: JSON.stringify(data),
          coverages: fbCoverages,
          effectiveDate: record.start,
          endDate: record.end,
          coverageEntityId: record.entityId,
        },
        headers: {
          Authorization: `Bearer ${await store.dispatch("getIdentityToken")}`,
        },
      });
      const save = await createDocument(
        `accounts/${accountId}/vehicles/${vehicleId}/statusHistory`,
        record,
      );
      return { id: status.id, ...save };
    } catch (error) {
      console.error("[SVC] Error", error.message);
      return null;
    }
  }
}

async function saveVehicleThumbnail(vehicle) {
  const save = await saveDocument(
    `accounts/${vehicle.companyId}/vehicles/${vehicle.id}`,
    { thumbnail: vehicle.thumbnail },
  );
  return { id: vehicle.id, ...save };
}

async function getVehicleSubscriptionStatusHistory(accountId, vehicleId) {
  const history = await getCollection(
    `accounts/${accountId}/vehicles/${vehicleId}/statusHistory`,
  );
  return history;
}

async function getVehicleCoverageHistory(
  vehicleEntityId,
  vehicleId,
  companyId,
) {
  const policies = await getCollection("policies");
  try {
    const resp = await axios({
      method: "GET",
      url: `${process.env["VUE_APP_POLICYMANAGEMENT_API"]}/vehiclecoverages/${vehicleEntityId}`,
      headers: {
        Authorization: `Bearer ${await store.dispatch("getIdentityToken")}`,
      },
    });

    return resp.data.map((item) => {
      let firebasePolicy = "";
      const cvgName = item.coverageName.toUpperCase();

      if (cvgName === "500K" && item.vehicleRegistrationState !== "NY") {
        firebasePolicy = policies.find(
          (p) => p.policyNumber === "NKM-ORMSII-1-22-NC-2",
        );
      } else if (cvgName === "500K" && item.vehicleRegistrationState === "NY") {
        firebasePolicy = policies.find(
          (p) => p.policyNumber === "NKM-ORMSII-1-22-NC-7-X",
        );
      } else if (cvgName === "MFR") {
        firebasePolicy = policies.find(
          (p) => p.policyNumber === "NKM-ORMSII-1-22-NC-1",
        );
      } else if (cvgName === "APD") {
        firebasePolicy = policies.find(
          (p) => p.policyNumber === "ORMSII-1-22-NC",
        );
      } else if (cvgName === "1M") {
        firebasePolicy = policies.find(
          (p) => p.policyNumber === "NKM-ORMSII-1-22-NC-3",
        );
      } else if (cvgName === "OFF-RENT MFR") {
        firebasePolicy = policies.find(
          (p) => p.policyNumber === "NKM-ORMSII-1-ORP-22-NC-1",
        );
      } else if (cvgName === "OFF-RENT APD") {
        firebasePolicy = policies.find(
          (p) => p.policyNumber === "NKM-ORMSII-1-ORP-22-NC-1",
        );
      }

      return {
        id: item.id,
        end: item.endDate,
        entityId: item.id,
        policyNumber: item.policyNumber,
        start: item.effectiveDate,
        vehicleRegistrationState: item.vehicleRegistrationState,
        policy: firebasePolicy ? firebasePolicy.entityId : null,
      };
    });
  } catch (error) {
    if (error.response) {
      console.error(
        `[SVC] Failed to get coverage history from policy service with status ${error.response.status}. ${error.response.data}`,
      );
      return "";
    }
  }
}

async function saveVehicleComments(companyId, vehicleId, comments) {
  const save = await saveDocument(
    `accounts/${companyId}/vehicles/${vehicleId}`,
    { comments },
  );
  return { id: vehicleId, ...save };
}

async function getEpisode(companyId, episodeId) {
  const episode = await getDocument(
    `accounts/${companyId}/episodes/${episodeId}`,
  );
  return { ...episode, id: episodeId };
}

async function getVehicle(companyId, vehicleId) {
  const vehicle = await getDocument(
    `accounts/${companyId}/vehicles/${vehicleId}`,
  );
  return { ...vehicle, id: vehicleId };
}

async function saveDriver(driver) {
  const record = {
    dateOfBirth: driver.dateOfBirth,
    dateOfHire: driver.dateOfHire,
    license: driver.license,
    firstName: driver.firstName,
    lastName: driver.lastName,
    insuranceCriteriaStatus: driver.insuranceCriteriaStatus,
    status: driver.status,
    class: driver.class,
    email: driver.email,
    phone: driver.phone,
    state: driver.state,
    modifiedBy: driver.modifiedBy,
  };

  if (driver.id) {
    const save = await saveDocument(
      `accounts/${driver.companyId}/drivers/${driver.id}`,
      record,
    );
    return { id: driver.id, ...save };
  } else {
    const save = await createDocument(
      `accounts/${driver.companyId}/drivers`,
      record,
    );
    return { id: driver.id, ...save };
  }
}

async function saveDriverThumbnail(driver) {
  const save = await saveDocument(
    `accounts/${driver.companyId}/drivers/${driver.id}`,
    { thumbnail: driver.thumbnail },
  );
  return { id: driver.id, ...save };
}

async function getCompanyInsuranceDocument(companyId, insuranceDocumentId) {
  const insuranceDocument = await getDocument(
    `accounts/${companyId}/insurance/${insuranceDocumentId}`,
  );
  return insuranceDocument;
}

async function getVehicleInsuranceDocument(
  companyId,
  vehicleId,
  insuranceDocumentId,
) {
  const insuranceDocument = await getDocument(
    `accounts/${companyId}/vehicles/${vehicleId}/insurance/${insuranceDocumentId}`,
  );
  return insuranceDocument;
}

async function getCompanyInsuranceDocuments(companyId) {
  const documents = await getCollection(`accounts/${companyId}/insurance`);
  return documents;
}

async function getVehicleInsuranceDocuments(companyId, vehicleId) {
  const documents = await getCollection(
    `accounts/${companyId}/vehicles/${vehicleId}/insurance`,
  );
  return documents;
}

async function getSystemPolicies() {
  const policies = await getCollection("policies");
  return policies;
}

async function saveSystemPolicy(policy) {
  const record = {
    type: policy.type,
    name: policy.name,
    account: policy.account,
    policyNumber: policy.policyNumber,
    groupPolicy: policy.groupPolicy,
    coverageUse: policy.coverageUse,
    broker: policy.broker,
    carrier: policy.carrier,
    effectiveStart: policy.effectiveStart,
    effectiveEnd: policy.effectiveEnd,
    abiPolicyNumber: policy.abiPolicyNumber,
    abiSequenceNumber: policy.abiSequenceNumber,
    comments: policy.comments,
  };

  if (policy.id) {
    const save = await saveDocument(`policies/${policy.id}`, record);
    return { id: save.id, ...save };
  } else {
    const save = await createDocument("policies", record);
    return { id: save.id, ...save };
  }
}

async function saveCompanyInsuranceDocumentComments(
  companyId,
  documentId,
  comments,
) {
  const save = await saveDocument(
    `accounts/${companyId}/insurance/${documentId}`,
    { comments },
  );
  return { id: save.id, companyId, ...save };
}

async function saveCompanyInsuranceDocument(companyId, insuranceDocument) {
  const entityId = uuidv4();
  const record = {
    docType: insuranceDocument.docType,
    entityId,
    filePath: insuranceDocument.filePath,
    vehicle: insuranceDocument.vehicle,
    driver: insuranceDocument.driver,
    episodeRate: insuranceDocument.episodeRate,
    policyNumber: insuranceDocument.policyNumber,
    startDate: insuranceDocument.startDate,
    endDate: insuranceDocument.endDate,
    issueDate: insuranceDocument.issueDate,
    state: insuranceDocument.state,
    certificateNumber: insuranceDocument.certificateNumber,
    revisionNumber: insuranceDocument.revisionNumber,
    certificateDate: insuranceDocument.certificateDate,
    certificateHolder: insuranceDocument.certificateHolder,
    certificateHolderAddressLineOne:
      insuranceDocument.certificateHolderAddressLineOne,
    certificateHolderAddressLineTwo:
      insuranceDocument.certificateHolderAddressLineTwo,
    certificateHolderState: insuranceDocument.certificateHolderState,
    certificateHolderZipcode: insuranceDocument.certificateHolderZipcode,
    certificateHolderCity: insuranceDocument.certificateHolderCity,
    insuredName: insuranceDocument.businessLegalName,
    insuredAddressLineOne: insuranceDocument.businessAddressLineOne,
    insuredAddressLineTwo: insuranceDocument.businessAddressLineTwo,
    insuredState: insuranceDocument.state,
    insuredCity: insuranceDocument.city,
    insuredZipcode: insuranceDocument.zipcode,
    contactName: insuranceDocument.contactName,
    contactPhone: insuranceDocument.contactPhone,
    contactEmail: insuranceDocument.contactEmail,
    contactNaic: insuranceDocument.contactNaic,
    producerName: insuranceDocument.producerName,
    producerAddressLineOne: insuranceDocument.producerAddressLineOne,
    producerAddressLineTwo: insuranceDocument.producerAddressLineTwo,
    producerCity: insuranceDocument.producerCity,
    producerState: insuranceDocument.producerState,
    producerZipcode: insuranceDocument.producerZipcode,
    insurerName: insuranceDocument.insurerName,
    insurerNaic: insuranceDocument.insurerNaic,
    description: insuranceDocument.description,
  };

  const account = await getDocument(`accounts/${companyId}`);
  return axios({
    method: "POST",
    url: `${process.env["VUE_APP_POLICYMANAGEMENT_API"]}/insurancedocument/${account.entityId}/${record.entityId}`,
    data: record,
    headers: {
      Authorization: `Bearer ${await store.dispatch("getIdentityToken")}`,
    },
  });
}

async function saveVehicleInsuranceDocument(
  companyId,
  vehicleId,
  insuranceDocument,
) {
  const record = {
    docType: insuranceDocument.docType,
    filePath: insuranceDocument.filePath,
    state: insuranceDocument.state,
    description: insuranceDocument.description,
    certificateNumber: insuranceDocument.certificateNumber,
    revisionNumber: insuranceDocument.revisionNumber,
    certificateDate: insuranceDocument.certificateDate,
    certificateHolderFirstName: insuranceDocument.certificateHolderFirstName,
    certificateHolderLastName: insuranceDocument.certificateHolderLastName,
    insuredName: insuranceDocument.businessLegalName,
    insuredAddressLineOne: insuranceDocument.businessAddressLineOne,
    insuredAddressLineTwo: insuranceDocument.businessAddressLineTwo,
    insuredState: insuranceDocument.state,
    insuredCity: insuranceDocument.city,
    insuredZipcode: insuranceDocument.zipcode,
    contactName: insuranceDocument.contactName,
    contactPhone: insuranceDocument.contactPhone,
    contactEmail: insuranceDocument.contactEmail,
    contactNaic: insuranceDocument.contactNaic,
    producerName: insuranceDocument.producerName,
    producerAddressLineOne: insuranceDocument.producerAddressLineOne,
    producerAddressLineTwo: insuranceDocument.producerAddressLineTwo,
    producerCity: insuranceDocument.producerCity,
    producerState: insuranceDocument.producerState,
    producerZipcode: insuranceDocument.producerZipcode,
    insurerName: insuranceDocument.insurerName,
    insurerNaic: insuranceDocument.insurerNaic,
  };

  if (insuranceDocument.id) {
    const save = await saveDocument(
      `accounts/${companyId}/vehicles/${vehicleId}/insurance/${insuranceDocument.id}`,
      record,
    );
    return { id: save.id, companyId, vehicleId, ...save };
  } else {
    const save = await createDocument(
      `accounts/${companyId}/vehicles/${vehicleId}/insurance`,
      record,
    );
    return { id: save.id, companyId, vehicleId, ...save };
  }
}

async function saveCommentsForCompany(companyId, comments) {
  const save = await saveDocument(`accounts/${companyId}`, { comments });
  return { id: companyId, ...save };
}

async function saveCommentsForClaim(companyId, claimId, comments) {
  const save = await saveDocument(`accounts/${companyId}/claims/${claimId}`, {
    comments,
  });
  return { id: claimId, companyId, ...save };
}

async function saveClaim(claim) {
  const record = {
    description: claim.description,
    thirdParty: claim.thirdParty,
    witness: claim.witness,
    driverName: claim.driverName,
    injuries: claim.injuries || false,
    passengers: claim.passsengers || false,
    police: claim.police || false,
    driverInjured: claim.driverInjured || false,
    incidentDate: claim.incidentDate,
    location: claim.location,
    locationAddressLineOne: claim.locationAddressLineOne,
    locationAddressLineTwo: claim.locationAddressLineTwo,
    locationCity: claim.locationCity,
    locationZipcode: claim.locationZipcode,
    locationState: claim.locationState,
    tpaClaimId: claim.tpaClaimId,
    claimAdjuster: claim.claimAdjuster,
    amountSpent: claim.amountSpent,
    amountPaid: claim.amountPaid,
    resolution: claim.resolution,
    lossType: claim.lossType,
    status: claim.status,
    vin: claim.vin,
    vehicle: claim.vehicle,
    driver: claim.driver,
  };

  if (claim.id) {
    const save = await saveDocument(
      `accounts/${claim.companyId}/claims/${claim.id}`,
      record,
    );
    return { id: claim.id, ...save };
  } else {
    const save = await createDocument(
      `accounts/${claim.companyId}/claims`,
      record,
    );
    return { id: save.id, ...save };
  }
}

async function addCompanyChangelog(
  accountId,
  eventType,
  entityId,
  fieldName,
  oldValue,
  newValue,
  actor,
  effective = "",
) {
  const log = await createDocument(`accounts/${accountId}/changelog`, {
    timestamp: moment.utc().format(),
    effective: effective || moment.utc().format(),
    eventType,
    entityId,
    fieldName,
    oldValue,
    newValue,
    actor,
  });
  return log;
}

async function addVehicleChangelog(
  accountId,
  vehicleId,
  eventType,
  entityId,
  fieldName,
  oldValue,
  newValue,
  actor,
  effective = "",
) {
  const log = await createDocument(
    `accounts/${accountId}/vehicles/${vehicleId}/changelog`,
    {
      timestamp: moment.utc().format(),
      effective: effective || moment.utc().format(),
      eventType,
      entityId,
      fieldName,
      oldValue,
      newValue,
      actor,
    },
  );
  return log;
}

async function getDriver(companyId, driverId) {
  const driver = await getDocument(`accounts/${companyId}/drivers/${driverId}`);
  return { ...driver, id: driverId };
}

async function getClaim(companyId, claimId) {
  const claim = await getDocument(`accounts/${companyId}/claims/${claimId}`);
  return { ...claim, id: claimId };
}

async function generateApiKey(companyId) {
  return new Promise((resolve) => {
    const path = `accounts/${companyId}`;
    subscribeDocument(path, () => {
      getDocument(path).then((company) => {
        if (company.apiKey && !(company.apiKey === true)) {
          resolve(company.apiKey);
        }
      });
    });
    saveDocument(path, { apiKey: true });
  });
}

async function getVehicleChangelog(vehicleId, companyId) {
  const changelog = await getCollectionDescending(
    `accounts/${companyId}/vehicles/${vehicleId}/changelog`,
    "timestamp",
  );
  return changelog;
}

async function getClaimChangelog(claimId, companyId) {
  const changelog = await getCollectionDescending(
    `accounts/${companyId}/claims/${claimId}/changelog`,
    "timestamp",
  );
  return changelog;
}

async function getDriverChangelog(driverId, companyId) {
  const changelog = await getCollectionDescending(
    `accounts/${companyId}/drivers/${driverId}/changelog`,
    "timestamp",
  );
  return changelog;
}

async function getCompanyChangelog(companyId) {
  const changelog = await getCollectionDescending(
    `accounts/${companyId}/changelog`,
    "timestamp",
  );
  return changelog;
}

async function getInsuranceDocumentChangelog(documentId, companyId) {
  const changelog = await getCollectionDescending(
    `accounts/${companyId}/insurance/${documentId}/changelog`,
    "timestamp",
  );
  return changelog;
}

async function getClaimAttachmentsList(companyId, claimId) {
  const list = await getFileNameList(`accounts/${companyId}/claims/${claimId}`);
  return list;
}

async function saveVehicleUploadAttachment(accountId, file) {
  const snap = await saveFile(
    `accounts/${accountId}/vehicleBulkUpload/${file.name}`,
    file,
  );
  return snap;
}

async function saveClaimDocument(accountId, claimId, file) {
  const snap = await saveFile(
    `accounts/${accountId}/claims/${claimId}/${file.name}`,
    file,
  );
  return snap;
}

async function saveCompanyDocument(accountId, file) {
  const snap = await saveFile(
    `accounts/${accountId}/documents/${file.name}`,
    file,
  );
  return snap;
}

async function saveVehicleDocument(accountId, vehicleId, file) {
  const snap = await saveFile(
    `accounts/${accountId}/vehicles/${vehicleId}/documents/${file.name}`,
    file,
  );
  return snap;
}

async function getVehicleDocumentList(accountId, vehicleId) {
  const list = await getFileNameList(
    `accounts/${accountId}/vehicles/${vehicleId}/documents`,
  );
  return list;
}

async function getCompanyDocumentList(accountId) {
  const list = await getFileNameList(`accounts/${accountId}/documents`);
  return list;
}

async function removeDocument(path) {
  const del = await deleteFile(path);
  return del;
}

function subscribeVehicleCollection(accountId, sub) {
  subscribeCollection(`accounts/${accountId}/vehicles`, sub);
}

function subscribeVehicleStatusHistoryCollection(accountId, vehicleId, sub) {
  subscribeCollection(
    `accounts/${accountId}/vehicles/${vehicleId}/statusHistory`,
    sub,
  );
}

async function getAdditionalUsersForAccount(accountId) {
  return getCollectionWhere("additionalUsers", "accountId", "==", accountId);
}
async function getUnderwritingResponse(inputs) {
  try {
    const response = await axios({
      method: "POST",
      url: `${process.env["VUE_APP_NEW_UNDERWRITING_URL"]}`,
      data: inputs,
      headers: {
        Authorization: `Bearer ${await store.dispatch("getIdentityToken")}`,
      },
    });
    return response;
  } catch (e) {
    return { success: false, ...e.response };
  }
}

export {
  dispatchEvent,
  subscribeVehicleCollection,
  generateApiKey,
  getClaim,
  getClaimAttachmentsList,
  getClaims,
  getInsuranceDocumentChangelog,
  getCompanies,
  getCompany,
  getCompanyChangelog,
  getDriver,
  getDrivers,
  getDriversForCompany,
  getFileDownloadUrl,
  getVehicle,
  getVehicleDocumentList,
  getVehicleChangelog,
  saveClaim,
  saveCompany,
  saveDriver,
  saveVehicle,
  saveVehicleDocument,
  saveFileMeta,
  saveVehicleUploadAttachment,
  getVehiclesForCompany,
  saveVehicleComments,
  addVehicleChangelog,
  saveVehicleSubscriptionStatus,
  getVehicleSubscriptionStatusHistory,
  subscribeVehicleStatusHistoryCollection,
  createCompany,
  runManualInvoice,
  saveDriverThumbnail,
  saveVehicleThumbnail,
  getDriverChangelog,
  getSystemSettings,
  saveCommentsForClaim,
  saveSystemSetting,
  saveClaimDocument,
  removeDocument,
  getClaimChangelog,
  saveCompanySubscriptionSpots,
  saveVehicleInsuranceDocument,
  getVehicleInsuranceDocument,
  getVehicleInsuranceDocuments,
  getCompanyDocumentList,
  saveCompanyDocument,
  addCompanyChangelog,
  saveCompanyInsuranceDocument,
  getCompanyInsuranceDocument,
  getCompanyInsuranceDocuments,
  getEpisodesForCompany,
  getReservationsForCompany,
  getRentersForCompany,
  getEpisode,
  saveEpisode,
  saveCompanyInsuranceDocumentComments,
  saveSystemPolicy,
  getSystemPolicies,
  saveCommentsForCompany,
  getAdditionalUsersForAccount,
  getCompanyByTenantId,
  getUnderwritingResponse,
  getConnectionsForCompany,
  getOffRentMileage,
  getVehicleCoverageHistory,
  getVehicleAccountStatus,

  /* Functions */
  generateVehicleDocuments,
  getPolicyNumber,
  getPendingVehicles,
  getRecentCompanies,
  getBulkVehicles,
  saveVehicles,
  getStripeCustomer,
  activateAccount,
  runInvoiceDryRun,
  generateCertificate,
  createEpisodeFromEpisode,
  cancelEpisode,
  searchVehicles,
  searchCompanies,
  creditWalletFunds,
  addAdditionalUser,
  removeAdditionalUser,
  getTelematicsAccountStatus,
  disconnectTelematicsIntegration,
};
