<template>
  <div class="p-4 md:p-8">
    <div class="flex justify-between items-end mb-4">
      <h1 class="text-5xl text-gray-800 font-bold dark:text-white">
        Companies
      </h1>
      <button
        class="hidden text-white bg-lula-gradient hover:bg-lula-gradient-alt sm:block"
        @click="$router.push({ name: 'NewCompany' })"
        v-if="getLaunchDarklyFlag('admin.account')"
      >
        Add Company
      </button>
      <span v-else />
    </div>
    <form
      @submit.prevent="searchCompanies"
      class="flex rounded-full border"
      :class="{
        'bg-gray-200 hover:bg-gray-200': searching,
        'bg-white hover:bg-white': !searching,
      }"
    >
      <input
        class="w-full text-sm rounded-full py-2 px-4 mr-1 md:text-lg"
        :class="{
          'bg-gray-200 hover:bg-gray-200': searching,
          'bg-white hover:bg-white': !searching,
        }"
        placeholder="Search by company name or contact"
        required
        :disabled="searching"
        v-model="companiesSearch"
      />
      <button
        type="submit"
        class="min-w-max text-sm bg-lula-gradient font-bold text-white cursor-default md:text-lg"
        disabled
        v-if="searching"
      >
        Searching...
      </button>
      <button
        type="submit"
        class="min-w-max text-sm bg-lula-gradient hover:bg-lula-gradient-alt font-bold text-white md:text-lg"
        v-else
      >
        Search Companies
      </button>
    </form>
    <div v-if="searching">
      <span class="text-gray-500 text-xl px-3"> Searching, please wait </span>
    </div>
    <div v-else-if="searchResults?.length === 0">
      <span class="text-gray-500 text-xl px-3"> No Companies Found </span>
    </div>
    <div class="mb-4" v-else-if="searchResults">
      <grid
        :columns="columns"
        :rows="searchResults"
        :search="true"
        @click="selectCompany"
        placeholder="Search Within"
        v-if="rows.length > 0"
      />
      <div
        class="text-lg rounded-lg bg-white text-gray-600 text-center p-4 mt-4"
        v-else
      >
        No Search and Filtered Companies
      </div>
    </div>
    <div class="mb-4 p-1" v-else>
      <h2 class="text-3xl text-gray-800 font-bold mb-2 dark:text-white mt-6">
        Recently Added Companies
      </h2>
      <grid
        :columns="columns"
        :rows="rows"
        :search="true"
        @click="selectCompany"
        placeholder="Search Companies"
        v-if="rows.length > 0"
      />
      <div
        class="text-lg rounded-lg bg-white text-gray-600 text-center p-4 mt-4"
        v-else
      >
        No Recent Companies
      </div>
    </div>
    <button
      class="w-full text-white bg-lula-gradient hover:bg-lula-gradient-alt sm:hidden"
      @click="$router.push({ name: 'NewCompany' })"
      v-if="getLaunchDarklyFlag('admin.account')"
    >
      Add Company
    </button>
  </div>
</template>

<script>
import { computed, watch, ref, onMounted } from "vue";
import { useStore } from "vuex";
import { useToast } from "vue-toastification";
import { useRouter } from "vue-router";
import { ellipsisText } from "@/utils";

export default {
  setup() {
    const store = useStore();
    const toast = useToast();
    const router = useRouter();

    const searching = ref(false);
    const companiesSearch = ref("");
    const searchResults = ref(null);

    watch(companiesSearch, () => {
      if (!companiesSearch.value) {
        searchResults.value = null;
      }
    });

    return {
      searching,
      companiesSearch,
      searchResults: computed(() => {
        if (!searchResults.value) {
          return null;
        }
        return searchResults.value.map((d) => ({
          ...d,
          detailedName: d.tenantId
            ? `${d.businessLegalName} (${d.tenantId})`
            : d.businessLegalName,
        }));
      }),
      selectCompany(companyId, event) {
        if (event.ctrlKey || event.metaKey) {
          const route = router.resolve({
            name: "Company",
            params: { id: companyId },
          });
          window.open(route.fullPath, "_blank");
        } else {
          router.push({ name: "Company", params: { id: companyId } });
        }
      },
      columns: [
        { name: "documentId", hidden: true },
        { name: "entityId", hidden: true },
        { name: "contactName", hidden: true },
        {
          name: "Company",
          id: "detailedName",
          formatter: (d) => ellipsisText(d),
        },
        { name: "State", id: "state" },
        { name: "Email", id: "contactEmail" },
        { name: "Status", id: "status" },
      ],
      rows: computed(() => {
        return store.state.recentCompanies.map((company) => ({
          ...company,
          detailedName: company.tenantId
            ? `${company.businessLegalName} (${company.tenantId})`
            : company.businessLegalName,
        }));
      }),
      exportGrid() {
        toast("Exporting Companies");
        store.dispatch("exportGrid", { type: "company" });
      },
      async searchCompanies() {
        try {
          searching.value = true;
          searchResults.value = null;
          searchResults.value = await store.dispatch(
            "searchCompanies",
            companiesSearch.value,
          );
        } catch (err) {
          companiesSearch.value = null;
          toast.clear();
          toast("Companies search failed.");
        }
        searching.value = false;
      },
    };
  },
};
</script>
