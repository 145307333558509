<template>
  <div class="w-full m-auto pt-4 md:p-8">
    <div class="rounded-lg bg-white p-4 m-auto">
      <div class="mb-4">
        <h3 class="font-bold text-gray-800 text-xl">Insurance Document</h3>
        <span class="font-bold text-gray-600 text-lg">
          {{ company.businessLegalName }}
        </span>
      </div>
      <form @submit.prevent="saveInsuranceDocument">
        <div class="grid grid-cols-1 md:grid-cols-2">
          <div
            class="m-1"
            v-for="field in insuranceDocumentFields"
            :key="field.key"
          >
            <label class="text-gray-800 font-bold">
              {{ field.label }}
            </label>
            <br />
            <select
              v-model="insuranceDocument.docType"
              :disabled="!isNew"
              v-if="field.key === 'docType'"
            >
              <option value="certificate-of-insurance">
                Certificate of Insurance
              </option>
              <option value="insurance-card">Insurance Card</option>
              <option value="policy">Policy</option>
            </select>
            <state-select
              v-else-if="field.type === 'state'"
              v-model="insuranceDocument[field.key]"
            />
            <input
              list="company-driver-list"
              v-model="insuranceDocument.driver"
              v-else-if="field.key === 'driver' && !selectedDriver"
            />
            <input
              class="cursor-pointer"
              type="text"
              :value="`${selectedDriver.firstName} ${selectedDriver.lastName}`"
              :required="field.required"
              @click="insuranceDocument.driver = null"
              v-else-if="field.key === 'driver' && selectedDriver"
            />
            <input
              list="company-vehicle-list"
              v-model="insuranceDocument.vehicle"
              v-else-if="field.key === 'vehicle' && !selectedVehicle"
            />
            <input
              class="cursor-pointer"
              type="text"
              :value="selectedVehicle.key || selectedVehicle.vin"
              :required="field.required"
              @click="insuranceDocument.vehicle = null"
              v-else-if="field.key === 'vehicle' && selectedVehicle"
            />
            <input
              list="company-document-list"
              v-model="selectedFilePath"
              v-else-if="field.key === 'filePath' && !selectedFile"
            />
            <input
              class="cursor-pointer"
              type="text"
              :value="selectedFile.name"
              :required="field.required"
              @click="selectedFilePath = null"
              v-else-if="field.key === 'filePath' && selectedFile"
            />
            <input
              :type="field.type"
              :required="field.required"
              :disabled="field.disabled"
              @change="defaultInputChange(field.key)"
              v-model="insuranceDocument[field.key]"
              v-else
            />
            <div
              class="flex justify-between"
              v-if="field.key === 'policyNumber'"
            >
              <span />
              <span
                @click="generateDocumentNumber('policyNumber', 'LULAT')"
                class="text-sm font-bold cursor-pointer text-gray-800 hover:underline"
              >
                Generate
              </span>
            </div>
            <div
              class="flex justify-between"
              v-if="field.key === 'certificateNumber'"
            >
              <span />
              <span
                @click="generateDocumentNumber('certificateNumber', 'LTCOI')"
                class="text-sm font-bold cursor-pointer text-gray-800 hover:underline"
              >
                Generate
              </span>
            </div>
            <div
              class="flex justify-between"
              v-if="field.key === 'filePath' && selectedFile"
            >
              <span />
              <span
                @click="viewSelectedFile"
                class="text-sm font-bold cursor-pointer text-gray-800 hover:underline"
              >
                View File
              </span>
            </div>
          </div>
          <datalist id="company-document-list">
            <option
              v-for="file in companyDocuments"
              :value="file.fullPath"
              :key="file.fullPath"
            >
              {{ file.name }}
            </option>
          </datalist>
          <datalist id="company-driver-list">
            <option
              v-for="driver in companyDrivers"
              :value="driver.entityId"
              :key="driver.entityId"
            >
              {{ driver.firstName }} {{ driver.lastName }}
            </option>
          </datalist>
          <datalist id="company-vehicle-list">
            <option
              v-for="vehicle in companyVehicles"
              :value="vehicle.entityId"
              :key="vehicle.entityId"
            >
              <span v-if="vehicle.key">
                {{ vehicle.key }} ({{ vehicle.vin }})
              </span>
              <span v-else>
                {{ vehicle.year }} {{ vehicle.make }} {{ vehicle.model }}
                {{ vehicle.vin }}
              </span>
            </option>
          </datalist>
        </div>
        <div v-if="insuranceDocument.docType === 'certificate-of-insurance'">
          <div class="bg-gray-50 p-4 mt-4 rounded-lg">
            <h3 class="text-gray-800 font-bold text-lg">Insured Information</h3>
            <hr class="my-2" />
            <div class="grid grid-cols-1 md:grid-cols-2">
              <div
                class="m-1"
                v-for="field in insuredInformationFields"
                :key="field.key"
              >
                <label class="text-gray-800 font-bold">
                  {{ field.label }}
                </label>
                <br />
                <input type="text" :value="company[field.key]" disabled />
              </div>
            </div>
          </div>
          <div class="bg-gray-50 p-4 mt-4 rounded-lg">
            <h3 class="text-gray-800 font-bold text-lg">Contact</h3>
            <hr class="my-2" />
            <div class="text-gray-800 rounded grid grid-cols-1 md:grid-cols-2">
              <div class="m-1">
                <label class="font-bold text-gray-800"> Name </label>
                <br />
                <input
                  type="text"
                  disabled
                  :value="insuranceDocument.contactName"
                />
              </div>
              <div class="m-1">
                <label class="font-bold text-gray-800"> Phone </label>
                <br />
                <input
                  type="text"
                  disabled
                  :value="insuranceDocument.contactPhone"
                />
              </div>
              <div class="m-1">
                <label class="font-bold text-gray-800"> Email </label>
                <br />
                <input
                  type="text"
                  disabled
                  :value="insuranceDocument.contactEmail"
                />
              </div>
              <div class="m-1">
                <label class="font-bold text-gray-800"> NAIC </label>
                <br />
                <input
                  type="text"
                  disabled
                  :value="insuranceDocument.contactNaic"
                />
              </div>
            </div>
          </div>
          <div class="bg-gray-50 p-4 mt-4 rounded-lg">
            <h3 class="text-gray-800 font-bold text-lg">Producer</h3>
            <hr class="my-2" />
            <div class="text-gray-800 rounded grid grid-cols-1 md:grid-cols-2">
              <div class="m-1">
                <label class="font-bold text-gray-800"> Name </label>
                <br />
                <input
                  type="text"
                  disabled
                  :value="insuranceDocument.producerName"
                />
              </div>
              <div class="m-1">
                <label class="font-bold text-gray-800">
                  Address Line One
                </label>
                <br />
                <input
                  type="text"
                  disabled
                  :value="insuranceDocument.producerAddressLineOne"
                />
              </div>
              <div class="m-1">
                <label class="font-bold text-gray-800">
                  Address Line Two
                </label>
                <br />
                <input
                  type="text"
                  disabled
                  :value="insuranceDocument.producerAddressLineTwo"
                />
              </div>
              <div class="m-1">
                <label class="font-bold text-gray-800"> City </label>
                <br />
                <input
                  type="text"
                  disabled
                  :value="insuranceDocument.producerCity"
                />
              </div>
              <div class="m-1">
                <label class="font-bold text-gray-800"> Zipcode </label>
                <br />
                <input
                  type="text"
                  disabled
                  :value="insuranceDocument.producerZipcode"
                />
              </div>
              <div class="m-1">
                <label class="font-bold text-gray-800"> State </label>
                <br />
                <input
                  type="text"
                  disabled
                  :value="insuranceDocument.producerState"
                />
              </div>
            </div>
          </div>
          <div class="bg-gray-50 p-4 mt-4 rounded-lg">
            <h3 class="text-gray-800 font-bold text-lg">Insurer</h3>
            <hr class="my-2" />
            <div class="text-gray-800 rounded grid grid-cols-1 md:grid-cols-2">
              <div class="m-1">
                <label class="font-bold text-gray-800"> Name </label>
                <br />
                <input
                  type="text"
                  disabled
                  :value="insuranceDocument.insurerName"
                />
              </div>
              <div class="m-1">
                <label class="font-bold text-gray-800"> NAIC </label>
                <br />
                <input
                  type="text"
                  disabled
                  :value="insuranceDocument.insurerNaic"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="flex justify-end flex-col mt-4 md:flex-row">
          <button
            @click="$router.go(-1)"
            type="button"
            class="bg-gray-100 mr-0 hover:bg-gray-200 md:mr-2"
          >
            Cancel
          </button>
          <button
            type="submit"
            class="bg-lula-gradient text-white mt-1 hover:bg-lula-gradient-alt md:mt-0"
          >
            Save
          </button>
        </div>
      </form>
    </div>
    <div class="p-4 bg-white rounded-lg mt-2" v-if="!isNew">
      <h4 class="font-bold text-gray-800 text-2xl">Comments</h4>
      <textarea
        rows="5"
        class="mt-1"
        :disabled="!editComments"
        v-model="insuranceDocument.comments"
      />
      <div class="flex justify-between">
        <span />
        <span
          @click="editComments = false"
          class="font-bold cursor-pointer hover:underline"
          v-if="editComments"
        >
          Save Comments
        </span>
        <span
          @click="editComments = true"
          class="font-bold cursor-pointer hover:underline"
          v-else
        >
          Edit Comments
        </span>
      </div>
    </div>

    <div class="my-4">
      <h2 class="font-bold text-2xl text-gray-800 mb-4">Changelog</h2>
      <grid
        v-if="changelog.length > 0"
        class="w-full"
        :rows="changelog"
        :columns="changelogColumns"
      />
      <div
        v-else
        class="bg-white rounded-lg text-center text-gray-600 p-4 select-none"
      >
        {{
          changelogIsRefreshing
            ? "Refreshing changelog..."
            : "No Change History"
        }}
      </div>
      <div class="flex justify-end">
        <span
          class="font-bold text-gray-600 cursor-pointer hover:underline mr-2"
          @click="refreshChangelog"
        >
          Refresh Changelog
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, watch } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { useToast } from "vue-toastification";
import moment from "moment";

function getChangelogEventDescription(changelogItem) {
  if (changelogItem.eventType === "property-changed") {
    return "The field was changed";
  }
  return changelogItem.eventType;
}

export default {
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const toast = useToast();

    const isNew = computed(() => {
      return route.params.documentId === "new";
    });

    const company = ref({});
    const vehicles = ref([]);

    onMounted(async () => {
      company.value = await store.dispatch(
        "getCompany",
        route.params.companyId,
      );
      vehicles.value = await store.dispatch(
        "getVehiclesForCompany",
        company.value.id,
      );
    });

    const insuranceDocument = ref({
      docType:
        route.params.documentId === "new" ? "certificate-of-insurance" : null,
      episodeRate: company.value.episodePrice,
    });

    onMounted(async () => {
      if (route.params.documentId !== "new") {
        const insuranceDoc = await store.dispatch(
          "getCompanyInsuranceDocument",
          {
            companyId: company.value.id,
            insuranceDocumentId: route.params.documentId,
          },
        );
        insuranceDocument.value = {
          ...insuranceDoc,
          startDate: insuranceDoc.startDate
            ? moment.utc(insuranceDoc.startDate).format("yyyy-MM-DD")
            : null,
          endDate: insuranceDoc.endDate
            ? moment.utc(insuranceDoc.endDate).format("yyyy-MM-DD")
            : null,
          issueDate: insuranceDoc.issueDate
            ? moment.utc(insuranceDoc.issueDate).format("yyyy-MM-DD")
            : null,
          certificateDate: insuranceDoc.certificateDate
            ? moment.utc(insuranceDoc.certificateDate).format("yyyy-MM-DD")
            : null,
        };
        selectedFilePath.value = insuranceDoc.filePath || null;
      }
    });

    const editComments = ref(false);
    watch(editComments, async () => {
      if (!editComments.value) {
        toast("Saving comments");
        try {
          await store.dispatch("saveCompanyInsuranceDocumentComments", {
            companyId: route.params.companyId,
            documentId: route.params.documentId,
            comments: insuranceDocument.value.comments,
          });
          toast.clear();
          toast("Comments saved.");
        } catch (err) {
          toast.clear();
          toast("Failed to save comments");
        }
      }
    });

    const companyDocuments = ref([]);
    onMounted(async () => {
      companyDocuments.value = await store.dispatch("getCompanyDocumentList", {
        accountId: route.params.companyId,
      });
    });

    const selectedFilePath = ref(null);
    const selectedFile = computed(() => {
      return companyDocuments.value.find(({ fullPath }) => {
        return fullPath === selectedFilePath.value;
      });
    });

    const selectedVehicle = computed(() => {
      return vehicles.value.find(({ companyId, entityId }) => {
        return (
          companyId === company.value.id &&
          entityId === insuranceDocument.value.vehicle
        );
      });
    });

    const selectedDriver = computed(() => {
      return store.state.driver.find(({ companyId, entityId }) => {
        return (
          companyId === company.value.id &&
          entityId === insuranceDocument.value.driver
        );
      });
    });

    const changelog = ref([]);
    const changelogIsRefreshing = ref(false);

    async function refreshChangelog() {
      changelogIsRefreshing.value = true;
      try {
        changelog.value = [];
        changelog.value = await store.dispatch(
          "getInsuranceDocumentChangelog",
          {
            documentId: route.params.documentId,
            companyId: route.params.companyId,
          },
        );
      } catch (error) {
        toast("Failed to get changelogs.");
        console.error(`[INSDTL] Failed to get changelogs. Reason: ${error}`);
      }

      changelogIsRefreshing.value = false;
    }

    onMounted(refreshChangelog);

    const systemSettings = ref([]);
    onMounted(async () => {
      systemSettings.value = await store.dispatch("getSystemSettings");
      insuranceDocument.value = {
        ...insuranceDocument.value,
        insuredName: company.value.businessLegalName,
        insuredAddressLineOne: company.value.businessAddressLineOne,
        insuredAddressLineTwo: company.value.businessAddressLineTwo,
        insuredState: company.value.state,
        insuredCity: company.value.businessCity,
        insuredZipcode: company.value.businessZipcode,
        contactName: getSystemSetting("truckingcontactname").value,
        contactPhone: getSystemSetting("truckingcontactphone").value,
        contactEmail: getSystemSetting("truckingcontactemail").value,
        contactNaic: getSystemSetting("truckingcontactnaic").value,
        producerName: getSystemSetting("truckingproducername").value,
        producerAddressLineOne: getSystemSetting(
          "truckingproduceraddresslineone",
        ).value,
        producerAddressLineTwo: getSystemSetting(
          "truckingproduceraddresslinetwo",
        ).value,
        producerCity: getSystemSetting("truckingproducercity").value,
        producerState: getSystemSetting("truckingproducerstate").value,
        producerZipcode: getSystemSetting("truckingproducerzipcode").value,
        insurerName: getSystemSetting("truckinginsurername").value,
        insurerNaic: getSystemSetting("truckinginsurernaic").value,
      };
    });

    function getSystemSetting(setting) {
      const ss = systemSettings.value.find(({ key }) => {
        return key === setting;
      });
      return ss || { value: "" };
    }

    return {
      company,
      isNew,
      selectedFilePath,
      selectedFile,
      async viewSelectedFile() {
        store.dispatch("logEvent", { name: "download_company_document" });
        const url = await store.dispatch(
          "getFileDownloadUrl",
          selectedFile.value.fullPath,
        );
        window.open(url, "_blank");
      },
      companyDocuments,
      selectedVehicle,
      selectedDriver,
      companyVehicles: computed(() => {
        return vehicles.value.filter(({ companyId }) => {
          return companyId === route.params.companyId;
        });
      }),
      companyDrivers: computed(() => {
        return store.state.driver.filter(({ companyId }) => {
          return companyId === route.params.companyId;
        });
      }),
      changelog: computed(() => {
        return changelog.value.map((change) => {
          return {
            id: change.id,
            event: getChangelogEventDescription(change),
            field: change.fieldName,
            originalValue: change.oldValue,
            newValue: change.newValue,
            user: change.actor,
            timestamp: moment(change.timestamp)
              .local()
              .format("yyyy-MM-DD h:mmA"),
          };
        });
      }),
      changelogColumns: [
        { name: "Event", id: "event" },
        { name: "Field", id: "field" },
        { name: "Original Value", id: "originalValue" },
        { name: "New Value", id: "newValue" },
        { name: "User", id: "user" },
        { name: "Timestamp", id: "timestamp" },
      ],
      changelogIsRefreshing,
      refreshChangelog,
      insuranceDocument,
      insuranceDocumentFields: computed(() => {
        return [
          {
            key: "docType",
            label: "Document Type",
            type: "text",
            required: true,
            disabled: true,
            docType: "all",
          },
          {
            key: "episodeRate",
            label: "Episode Rate",
            type: "number",
            required: true,
            disabled: true,
            docType: "policy",
          },
          {
            key: "filePath",
            label: "Associated Document",
            type: "text",
            required: false,
            disabled: false,
            docType: "all",
          },
          {
            key: "policyNumber",
            label: "Policy Number",
            type: "text",
            required: true,
            disabled: false,
            docType: "policy",
          },
          {
            key: "state",
            label: "State",
            type: "state",
            required: true,
            disabled: false,
            docType: "insurance-card",
          },
          {
            key: "startDate",
            label: "Start Date",
            type: "date",
            required: true,
            disabled: false,
            docType: "policy",
          },
          {
            key: "endDate",
            label: "End Date",
            type: "date",
            required: true,
            disabled: false,
            docType: "policy",
          },
          {
            key: "issueDate",
            label: "Issue Date",
            type: "date",
            required: true,
            disabled: false,
            docType: "policy",
          },
          {
            key: "vehicle",
            label: "Vehicle",
            type: "text",
            required: false,
            disabeld: false,
            docType: "all",
          },
          {
            key: "driver",
            label: "Driver",
            type: "text",
            required: false,
            disabeld: false,
            docType: "all",
          },
          {
            key: "certificateNumber",
            label: "Certificate Number",
            required: true,
            docType: "certificate-of-insurance",
          },
          {
            key: "revisionNumber",
            label: "Revision Number",
            type: "text",
            required: false,
            docType: "certificate-of-insurance",
          },
          {
            key: "certificateDate",
            label: "Certificate Date",
            type: "date",
            required: true,
            docType: "certificate-of-insurance",
          },
          {
            key: "certificateHolder",
            label: "Certificate Holder Name",
            type: "text",
            required: true,
            docType: "certificate-of-insurance",
          },
          {
            key: "certificateHolderAddressLineOne",
            label: "Certificate Holder Address Line 1",
            type: "text",
            required: true,
            docType: "certificate-of-insurance",
          },
          {
            key: "certificateHolderAddressLineTwo",
            label: "Certificate Holder Address Line 2",
            type: "text",
            required: false,
            docType: "certificate-of-insurance",
          },
          {
            key: "certificateHolderState",
            label: "Certificate Holder State",
            type: "state",
            required: true,
            docType: "certificate-of-insurance",
          },
          {
            key: "certificateHolderZipcode",
            label: "Certificate Holder Zipcode",
            type: "text",
            required: true,
            docType: "certificate-of-insurance",
          },
          {
            key: "certificateHolderCity",
            label: "Certificate Holder City",
            type: "text",
            required: true,
            docType: "certificate-of-insurance",
          },
          {
            key: "description",
            label: "Description",
            type: "text",
            required: false,
            disabled: false,
            docType: "all",
          },
        ].filter(
          ({ docType }) =>
            docType === "all" || docType === insuranceDocument.value.docType,
        );
      }),
      insuredInformationFields: [
        { key: "businessLegalName", label: "Company" },
        { key: "businessAddressLineOne", label: "Address Line One" },
        { key: "businessAddressLineTwo", label: "Address Line Two" },
        { key: "businessCity", label: "City" },
        { key: "businessZipcode", label: "Zipcode" },
        { key: "state", label: "State" },
      ],
      generateDocumentNumber(key, prefix) {
        const documentChars = "23456789ABCDEGHJKMNPQRSTUVWXYZ";
        const documentNumberArr = new Array(12).fill(null);
        const documentNumber = documentNumberArr
          .map(() => {
            return documentChars.charAt(
              Math.floor(Math.random() * documentChars.length),
            );
          })
          .join("");
        insuranceDocument.value[key] = `${prefix}${documentNumber}`;
      },
      defaultInputChange(changeKey) {
        if (changeKey === "startDate" && !insuranceDocument.value.endDate) {
          const startChosen = moment(
            insuranceDocument.value.startDate,
            "yyyy-MM-DD",
          );
          startChosen.add(1, "year");
          insuranceDocument.value.endDate = startChosen.format("yyyy-MM-DD");
        }
      },
      editComments,
      async saveInsuranceDocument() {
        try {
          toast("Saving insurance document.");
          insuranceDocument.value = await store.dispatch(
            "saveCompanyInsuranceDocument",
            {
              companyId: company.value.id,
              insuranceDocument: {
                ...insuranceDocument.value,
                id:
                  route.params.documentId === "new"
                    ? null
                    : route.params.documentId,
                filePath: selectedFilePath.value,
                episodeRate: company.value.episodePrice,
                startDate: moment(
                  insuranceDocument.value.startDate,
                  "yyyy-MM-DD",
                )
                  .utc()
                  .format(),
                endDate: moment(insuranceDocument.value.endDate, "yyyy-MM-DD")
                  .utc()
                  .format(),
                issueDate: moment(
                  insuranceDocument.value.issueDate,
                  "yyyy-MM-DD",
                )
                  .utc()
                  .format(),
                certificateDate: moment(
                  insuranceDocument.value.certificateDate,
                  "yyyy-MM-DD",
                )
                  .utc()
                  .format(),
              },
            },
          );
          insuranceDocument.value = {
            ...insuranceDocument.value,
            startDate: insuranceDocument.value.startDate
              ? moment
                  .utc(insuranceDocument.value.startDate)
                  .format("yyyy-MM-DD")
              : null,
            endDate: insuranceDocument.value.endDate
              ? moment.utc(insuranceDocument.value.endDate).format("yyyy-MM-DD")
              : null,
            issueDate: insuranceDocument.value.issueDate
              ? moment
                  .utc(insuranceDocument.value.issueDate)
                  .format("yyyy-MM-DD")
              : null,
            certificateDate: insuranceDocument.value.certificateDate
              ? moment
                  .utc(insuranceDocument.value.certificateDate)
                  .format("yyyy-MM-DD")
              : null,
          };
          toast.clear();
          toast("Insurance document saved.");
          if (route.params.documentId === "new") {
            router.replace({
              name: "InsuranceDocumentDetail",
              params: {
                companyId: company.value.id,
                documentId: insuranceDocument.value.id,
              },
            });
          }
        } catch (err) {
          toast.clear();
          toast("Failed to save insurance document.");
        }
      },
    };
  },
};
</script>
