<template>
  <div
    class="transition bg-white px-2 py-8 w-full md:w-2/3 lg:w-1/3 dark:bg-gray-600 rounded-2xl flex items-center flex-col shadow-lg hover:shadow-xl"
  >
    <div class="h-1/2 w-full flex items-center justify-center flex-col">
      <img
        class="w-2/3"
        src="@/assets/AddifyLogo_Transparent.png"
        alt="GetAddify Logo"
      />
      <span class="text-gray-500 font-bold mt-2 text-lg dark:text-gray-300">
        Paddocks
      </span>
    </div>
    <hr class="w-2/3 my-4 dark:bg-gray-300" />
    <div class="h-1/2 px-2">
      <button
        class="tracking-wide transform transition scale-100 w-full bg-lula-gradient dark:text-gray-100 text-white font-bold mb-2 hover:bg-lula-gradient-alt hover:scale-95"
        @click="login('Car Rental')"
      >
        Car Rental
      </button>
      <!-- <button
        class="tracking-wide transform transition scale-100 w-full bg-lula-gradient dark:text-gray-100 text-white font-bold mb-2 hover:bg-lula-gradient-alt hover:scale-95"
        @click="login('Trucking')"
      >
        Trucking
      </button> -->
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { useAuth0 } from "@auth0/auth0-vue";

export default {
  setup() {
    const { loginWithRedirect } = useAuth0();
    const store = useStore();
    return {
      login: (type) => {
        store.dispatch("setPaddocksType", { type });
        loginWithRedirect({
          authorizationParams: {
            tenant_id: "8941322c-37f5-4a3f-b49a-f9e06a226122", // Hardcoded Aster tenant id
          },
        });
      },
    };
  },
};
</script>
