<template>
  <div
    style="background-color: rgba(0, 0, 0, 0.5)"
    class="relative w-screen h-screen"
    v-if="sidebar"
  >
    <div
      class="w-1/3 h-full bg-white absolute right-0 shadow-2xl border-l dark:bg-gray-700 dark:border-lula"
    >
      <div class="flex items-center justify-between w-full p-4">
        <h2
          class="text-xl font-bold text-gray-800 tracking-wide lg:hidden dark:text-white"
        >
          {{ user.email }}
        </h2>
        <h2
          class="hidden text-xl font-bold text-gray-800 tracking-wide lg:block dark:text-white"
        >
          Logged in as {{ user.email }}
        </h2>
        <div class="cursor-pointer" @click="closeSidebar">
          <div
            class="transform rotate-45 translate-y-0.5 w-6 h-0.5 bg-gray-400"
          />
          <div class="transform -rotate-45 w-6 h-0.5 bg-gray-400" />
        </div>
      </div>
      <div
        class="w-full text-lg border-t border-b select-none cursor-pointer text-gray-600 dark:border-lula dark:text-white dark:hover:text-gray-700"
        @click="toggleTestMode"
      >
        <div
          class="w-full h-full transition-all border-l-0 border-lula p-4 hover:border-l-8 hover:bg-gray-100"
          :class="{ 'border-l-8': isTestMode }"
        >
          <span v-if="isTestMode">Disable Test Mode</span>
          <span v-else>Enable Test Mode</span>
        </div>
      </div>
      <div
        class="w-full text-lg border-b select-none cursor-pointer text-gray-600 dark:border-lula dark:text-white dark:hover:text-gray-700"
        @click="toggleDarkMode"
      >
        <div
          class="w-full h-full transition-all border-l-0 border-lula p-4 hover:border-l-8 hover:bg-gray-100"
          :class="{ 'border-l-8': isDarkMode }"
        >
          <span v-if="isDarkMode">Disable Dark Mode</span>
          <span v-else>Enable Dark Mode</span>
        </div>
      </div>
      <div
        class="w-full text-lg border-b select-none cursor-pointer text-gray-600 dark:border-lula dark:text-white dark:hover:text-gray-700"
        @click="systemSettings"
      >
        <div
          class="w-full h-full transition-all border-l-0 border-lula p-4 hover:bg-gray-100"
        >
          <span>System Settings</span>
        </div>
      </div>
      <div
        class="w-full text-lg border-b select-none cursor-pointer text-gray-600 dark:border-lula dark:text-white dark:hover:text-gray-700"
        @click="runInvoiceDryRun"
      >
        <div
          class="w-full h-full transition-all border-l-0 border-lula p-4 hover:bg-gray-100"
        >
          <span>Run Invoice Dry Run</span>
        </div>
      </div>
      <div
        class="w-full text-lg border-b select-none cursor-pointer text-gray-600 p-4 hover:bg-gray-100 dark:text-white dark:border-lula dark:hover:text-gray-700"
        @click="logout"
      >
        <span>Logout</span>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted } from "vue";
import { useStore } from "vuex";
import { useToast } from "vue-toastification";
import { useRouter } from "vue-router";
import { useAuth0 } from "@auth0/auth0-vue";

export default {
  setup() {
    const store = useStore();
    const router = useRouter();
    const toast = useToast();
    const { logout } = useAuth0();

    onMounted(() => {
      window.addEventListener("resize", () => {
        document.querySelector("body").style.overflowY = null;
        store.commit("setState", { key: "sidebar", value: false });
      });
    });

    function closeSidebar() {
      document.querySelector("body").style.overflowY = null;
      store.commit("setState", { key: "sidebar", value: false });
    }

    return {
      user: computed(() => store.state.user),
      sidebar: computed(() => store.state.sidebar),
      isTestMode: computed(() => store.state.testMode),
      isDarkMode: computed(() => store.state.darkMode),
      closeSidebar,
      toggleTestMode() {
        store.dispatch("toggleTestMode");
      },
      toggleDarkMode() {
        store.dispatch("toggleDarkMode");
      },
      systemSettings() {
        closeSidebar();
        router.push({ name: "SystemSettings" });
      },
      async runInvoiceDryRun() {
        if (confirm("Are you sure you want to dry run invoices?")) {
          toast("Attempting invoice dry run.");
          try {
            const run = await store.dispatch("runInvoiceDryRun");
            if (run.success) {
              toast("Running invoice dry run.");
            } else {
              toast("Failed to dry run invoices.");
            }
          } catch (err) {
            toast("Failed to dry run invoices.");
            throw err;
          }
        }
      },
      logout: () => {
        store.dispatch("setPaddocksType", { type: null });
        logout({ logoutParams: { returnTo: window.location.origin } });
      },
    };
  },
};
</script>
