<template>
  <div v-if="claimCompany">
    <input
      type="file"
      class="hidden"
      accept=".pdf,.jpeg,.jpg,.png"
      multiple="true"
      ref="attachmentsInput"
    />
    <div
      class="bg-white rounded-xl p-4 m-auto w-full md:mt-4 md:w-3/4"
      v-if="claim"
    >
      <div class="mb-4">
        <div class="flex justify-between items-center">
          <h1 class="font-bold text-3xl text-gray-800 mb-px">Claim</h1>
          <span
            class="font-bold bg-lula-alt text-white rounded-full px-4 select-none"
            v-if="claim.status"
          >
            {{ claim.status.toUpperCase() }}
          </span>
        </div>
        <div class="flex justify-between">
          <span class="font-bold text-gray-600 text-xl">{{
            claim.id.toUpperCase()
          }}</span>
          <span class="font-bold text-gray-600 text-xl">{{
            claimCreated
          }}</span>
        </div>
      </div>
      <div class="grid md:grid-cols-2">
        <div class="m-1">
          <label class="font-bold text-gray-800 font-lg">Company</label>
          <br />
          <router-link
            :to="{ name: 'Company', params: { id: claimCompany.id } }"
          >
            <input
              class="mt-1 cursor-pointer"
              type="text"
              :value="claimCompany.businessLegalName"
              disabled
            />
          </router-link>
        </div>
        <div
          class="m-1"
          v-if="isApiClaim || $store.state.type === truckingAccountType"
        >
          <label class="font-bold text-gray-800 font-lg"> Vehicle </label>
          <br />
          <router-link
            :to="{
              name: 'Vehicle',
              params: {
                companyId: claimCompany.id,
                vehicleId: claimVehicle.id,
              },
            }"
            v-if="claimVehicle"
          >
            <input
              class="mt-1 cursor-pointer"
              type="text"
              :value="claimVehicle.key || claimVehicle.vin"
              disabled
            />
          </router-link>
          <input
            placeholder="Select a Vehicle"
            list="company-vehicles"
            v-model="claim.vehicle"
            class="mt-1"
            v-else
          />
          <datalist id="company-vehicles">
            <option
              v-for="vehicle in companyVehicles"
              :value="vehicle.entityId"
              :key="vehicle.entityId"
            >
              <span v-if="vehicle.key">{{ vehicle.key }}</span>
              <span v-else>
                {{ vehicle.make }} {{ vehicle.model }} {{ vehicle.year }}
                {{ vehicle.vin }}
              </span>
            </option>
          </datalist>
          <div class="flex justify-between" v-if="claim.vehicle">
            <span />
            <span
              class="text-sm font-bold text-gray-800 cursor-pointer hover:underline"
              @click="claim.vehicle = null"
            >
              Edit vehicle
            </span>
          </div>
        </div>
        <div class="m-1" v-else>
          <label class="font-bold"> Vehicle </label>
          <router-link
            :to="{
              name: 'Vehicle',
              params: {
                companyId: claimCompany.id,
                vehicleId: claimVehicle.id,
              },
            }"
            v-if="claimVehicle"
          >
            <input
              class="mt-1 cursor-pointer"
              type="text"
              :value="claim.vin"
              disabled
            />
          </router-link>
          <input class="mt-1" type="text" :value="claim.vin" disabled v-else />
        </div>
        <div
          class="m-1"
          v-if="isApiClaim || $store.state.type === truckingAccountType"
        >
          <label class="font-bold text-gray-800 font-lg">Driver</label>
          <br />
          <router-link
            :to="{
              name: 'Driver',
              params: {
                companyId: claimCompany.id,
                driverId: claimDriver.id,
              },
            }"
            v-if="claimDriver"
          >
            <input
              class="mt-1 cursor-pointer"
              type="text"
              :value="`${claimDriver.firstName} ${claimDriver.lastName}`"
              disabled
            />
          </router-link>
          <input
            type="text"
            placeholder="Select a Driver"
            list="company-drivers"
            v-model="claim.driver"
            class="mt-1"
            v-else
          />
          <datalist id="company-drivers">
            <option
              v-for="driver in companyDrivers"
              :value="driver.entityId"
              :key="driver.entityId"
            >
              <span>{{ driver.firstName }} {{ driver.lastName }}</span>
            </option>
          </datalist>
          <div class="flex justify-between" v-if="claim.driver">
            <span />
            <span
              class="text-sm font-bold text-gray-800 cursor-pointer hover:underline"
              @click="claim.driver = null"
            >
              Edit driver
            </span>
          </div>
        </div>
        <div class="m-1" v-else>
          <label class="font-bold text-gray-800"> Driver </label>
          <input class="mt-1" type="text" v-model="claim.driverName" />
        </div>
        <div class="m-1" v-if="claim.location">
          <label class="font-bold text-gray-800 font-lg"> Location </label>
          <br />
          <input class="mt-1" type="text" v-model="claim.location" />
        </div>
        <div class="m-1" v-if="$store.state.type === truckingAccountType">
          <label class="font-bold text-gray-800 font-lg"> Injuries </label>
          <br />
          <select class="mt-1" v-model="claim.injuries">
            <option :value="false">No Injuries</option>
            <option :value="true">Injuries</option>
          </select>
        </div>
        <div class="m-1" v-if="$store.state.type === truckingAccountType">
          <label class="font-bold text-gray-800 font-lg"> Police </label>
          <br />
          <select class="mt-1" v-model="claim.police">
            <option :value="false">No police were present</option>
            <option :value="true">Police were Present</option>
          </select>
        </div>
        <div class="m-1" v-if="$store.state.type === truckingAccountType">
          <label class="font-bold text-gray-800 font-lg"> Passengers </label>
          <br />
          <select class="mt-1" v-model="claim.passengers">
            <option :value="false">Passengers were not involved</option>
            <option :value="true">Passengers were involved</option>
          </select>
        </div>
        <div class="m-1">
          <label class="font-bold text-gray-800 font-lg"> Incident Date </label>
          <br />
          <input type="datetime-local" v-model="claim.incidentDate" />
        </div>
        <div class="m-1">
          <label class="font-bold text-gray-800 font-lg">Status</label>
          <br />
          <select class="mt-1" v-model="claim.status">
            <option
              v-for="status in claimStatusList"
              :key="status"
              :value="status"
            >
              {{ status }}
            </option>
          </select>
        </div>
      </div>
      <div class="mt-1 p-1 mb-4" v-if="isApiClaim">
        <div class="mb-1">
          <label class="font-bold text-gray-800">Driver Injured</label>
          <br />
          <select v-model="claim.driverInjured">
            <option :value="false">Driver Was Not Injured</option>
            <option :value="true">Driver Was Injured</option>
          </select>
        </div>
        <div class="mb-1">
          <label class="font-bold text-gray-800">Description</label>
          <br />
          <textarea class="mt-1" v-model="claim.description" rows="5" />
        </div>
        <label class="font-bold text-gray-800"> Third Party </label>
        <div
          class="bg-gray-50 mt-2 rounded cursor-pointer hover:bg-gray-100"
          v-for="(tp, idx) in claim.thirdParty"
          :key="`${tp.firstName}_${idx}`"
          @click="
            tpExpand[`${tp.firstName}_${idx}`] =
              !tpExpand[`${tp.firstName}_${idx}`]
          "
        >
          <div class="p-4">
            <div class="flex justify-between text-gray-800">
              <span class="font-bold">
                {{ tp.firstName || "" }} {{ tp.lastName || "" }}
              </span>
              <span
                class="bg-lula-alt rounded-full px-4 text-white font-bold uppercase"
                v-if="tp.injured"
              >
                Injured
              </span>
              <span v-else />
            </div>
            <div v-if="tpExpand[`${tp.firstName}_${idx}`]">
              <div
                class="bg-gray-200 border-l-4 border-lula mt-2 rounded p-4 text-gray-800"
              >
                <span class="font-bold text-gray-500"> Contact </span>
                <br />
                <span v-if="tp.email">
                  {{ tp.email }}
                </span>
                <br v-if="tp.email" />
                <span v-if="tp.phone">
                  {{ tp.phone }}
                </span>
              </div>
              <div
                class="bg-gray-200 border-l-4 border-lula mt-2 rounded p-4 text-gray-800"
                v-if="tp.policeDepartment || tp.policeCaseNumber"
              >
                <span class="font-bold text-gray-500"> Police </span>
                <br />
                <span v-if="tp.policeDepartment">
                  {{ tp.policeDepartment }}
                </span>
                <br v-if="tp.policeDepartment" />
                <span v-if="tp.policeCaseNumber">
                  {{ tp.policeCaseNumber }}
                </span>
              </div>
              <div
                class="bg-gray-200 border-l-4 border-lula mt-2 rounded p-4 text-gray-800"
                v-if="tp.vehicleVin"
              >
                <span class="font-bold text-gray-500"> Vehicle </span>
                <br />
                <span v-if="tp.vehicleVin">
                  {{ tp.vehicleVin }}
                </span>
                <br v-if="tp.vehicleVin" />
                <span v-if="tp.vehicleVin">
                  {{ tp.vehicleYear }} {{ tp.vehicleMake }}
                  {{ tp.vehicleModel }}
                </span>
              </div>
              <div
                class="bg-gray-200 border-l-4 border-lula mt-2 rounded p-4 text-gray-800"
                v-if="tp.carrier || tp.policy"
              >
                <span class="font-bold text-gray-500"> Insurance </span>
                <br />
                <span v-if="tp.carrier">
                  {{ tp.carrier }}
                </span>
                <br v-if="tp.carrier" />
                <span v-if="tp.policy">
                  {{ tp.policy }}
                </span>
              </div>
              <div
                class="bg-gray-200 border-l-4 border-lula mt-2 rounded p-4 text-gray-800"
                v-if="tp.licenseNumber || tp.licenseAddressLineOne"
              >
                <span class="font-bold text-gray-500"> License </span>
                <br />
                <span v-if="tp.licenseNumber">
                  {{ tp.licenseNumber }}
                </span>
                <br v-if="tp.licenseNumber" />
                <span v-if="tp.licenseAddressLineOne">
                  {{ tp.licenseAddressLineOne }} {{ tp.licenseAddressLineTwo }}
                </span>
                <br v-if="tp.licenseAddressLineOne" />
                <span>
                  {{ tp.licenseCity }} {{ tp.licenseState }}
                  {{ tp.licenseZipcode }}
                </span>
              </div>
              <div
                class="bg-gray-200 border-l-4 border-lula mt-2 rounded p-4 text-gray-800"
                v-if="tp.description"
              >
                <span class="font-bold text-gray-500"> Description </span>
                <br />
                {{ tp.description }}
              </div>
            </div>
          </div>
          <div class="bg-lula-alt px-2 py-1 text-white font-bold rounded-b">
            {{ (tp.type || "").toUpperCase() }}
          </div>
        </div>
      </div>
      <div class="mt-1 p-1" v-else>
        <div class="mb-1">
          <label class="font-bold text-gray-800">Third Party</label>
          <br />
          <textarea class="mt-1" v-model="claim.thirdParty" rows="5" />
        </div>
        <div class="mb-1" v-if="$store.state.type === carAccountType">
          <label class="font-bold text-gray-800">Witness</label>
          <br />
          <textarea class="mt-1" v-model="claim.witness" rows="5" />
        </div>
        <div class="mb-1">
          <label class="font-bold text-gray-800">Description</label>
          <br />
          <textarea class="mt-1" v-model="claim.description" rows="5" />
        </div>
      </div>
      <div v-if="$store.state.type === truckingAccountType">
        <div class="bg-gray-50 rounded-lg p-4 my-2">
          <span class="text-gray-800 font-bold text-lg">
            Incident Location
          </span>
          <hr class="my-2" />
          <div class="grid grid-cols-1 md:grid-cols-3">
            <div class="md:mr-1 md:col-span-2">
              <label class="font-bold text-gray-800"> Address Line 1 </label>
              <br />
              <input type="text" v-model="claim.locationAddressLineOne" />
            </div>
            <div class="md:ml-1">
              <label class="font-bold text-gray-800"> Address Line 2 </label>
              <br />
              <input type="text" v-model="claim.locationAddressLineTwo" />
            </div>
            <div class="md:mr-1">
              <label class="font-bold text-gray-800"> City </label>
              <br />
              <input type="text" v-model="claim.locationCity" />
            </div>
            <div class="md:ml-1 md:mr-1">
              <label class="font-bold text-gray-800"> Zipcode </label>
              <br />
              <input type="text" v-model="claim.locationZipcode" />
            </div>
            <div class="md:ml-1">
              <label class="font-bold text-gray-800"> State </label>
              <br />
              <state-select v-model="claim.locationState" />
            </div>
          </div>
        </div>
      </div>
      <div class="my-2">
        <div class="border rounded-lg p-4">
          <h3 class="font-bold text-gray-800 text-lg">Admin Fields</h3>
          <hr class="my-2" />
          <div>
            <div class="mt-2" v-for="field in adminFields" :key="field.key">
              <label class="font-bold text-gray-800">
                {{ field.label }}
              </label>
              <br />
              <textarea
                rows="5"
                class="mt-1"
                v-model="claim[field.key]"
                v-if="field.type === 'textarea'"
              />
              <select
                v-model="claim[field.key]"
                v-else-if="field.key === 'amountPaid'"
              >
                <option :value="null">Select amount paid</option>
                <option value="paid">Paid</option>
                <option value="reserve">Reserve</option>
              </select>
              <input
                :type="field.type"
                class="mt-1"
                v-model="claim[field.key]"
                v-else
              />
            </div>
          </div>
        </div>
      </div>
      <div v-if="attachments.length > 0" class="mb-4">
        <label class="font-bold text-gray-800">Attachments</label>
        <div
          @click="viewAttachment(attachment)"
          v-for="attachment in attachments"
          :key="attachment.path"
          class="flex items-center justify-between p-4 bg-gray-100 rounded-2xl my-1 cursor-pointer hover:bg-gray-200"
        >
          <span class="font-bold text-gray-800">
            {{ attachment.name }}
          </span>
          <span
            class="p-2 rounded-full bg-white hover:bg-red-100"
            @click.stop="removeAttachment(attachment)"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="
                      M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2
                      2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0
                      00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
              />
            </svg>
          </span>
        </div>
        <div class="flex justify-between text-sm">
          <span />
          <span
            @click="attachmentsInput.click()"
            class="font-bold text-gray-800 cursor-pointer hover:underline"
          >
            Add attachments
          </span>
        </div>
      </div>
      <div class="flex justify-end flex-col mt-4 md:flex-row">
        <button
          @click="$router.go(-1)"
          class="bg-gray-100 hover:bg-gray-200 mb-1 md:mx-1"
        >
          Cancel
        </button>
        <button
          @click="saveClaim"
          class="text-white bg-lula-gradient hover:bg-lula-gradient-alt mb-1 md:mx-1"
        >
          Save
        </button>
      </div>
      <hr class="my-4" />
      <div>
        <h3 class="text-gray-800 text-xl mb-2 font-bold">Comments</h3>
        <textarea rows="5" :disabled="!editComments" v-model="claim.comments" />
        <div class="flex justify-between">
          <span />
          <span
            class="cursor-pointer text-gray-800 font-bold hover:underline"
            @click="saveComments"
            v-if="editComments"
          >
            Save comments
          </span>
          <span
            class="cursor-pointer text-gray-800 font-bold hover:underline"
            @click="editComments = true"
            v-else
          >
            Edit comments
          </span>
        </div>
      </div>
    </div>
    <div
      class="m-auto w-full md:w-3/4 flex justify-between"
      v-if="claimCompany.type === truckingAccountType"
    >
      <span />
      <span
        class="font-bold text-gray-800 cursor-pointer mx-1 hover:underline"
        @click="copyCustomerLink"
      >
        Copy customer link
      </span>
    </div>
    <div class="m-auto w-full md:mt-4 md:w-3/4">
      <div>
        <h4 class="text-gray-800 font-bold text-4xl mb-4 dark:text-white">
          Changelog
        </h4>
        <grid
          v-if="changelog.length > 0"
          :rows="changelog"
          :columns="changelogColumns"
        />
        <div
          class="bg-white rounded-lg text-center text-gray-600 p-4 select-none"
          v-else
        >
          {{
            changelogRefresh
              ? "Refreshing changelog..."
              : "No Claim Change History"
          }}
        </div>
        <div class="flex justify-end">
          <span
            class="font-bold text-gray-600 cursor-pointer hover:underline dark:text-white"
            @click="refreshChangelog"
          >
            Refresh Changelog
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useToast } from "vue-toastification";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { ref, computed, watch, onMounted } from "vue";
import { getChangelogDescription } from "@/utils";
import moment from "moment";

export default {
  setup() {
    const toast = useToast();
    const store = useStore();
    const route = useRoute();
    const claim = ref({
      ...store.state.claim.find(({ id }) => id === route.params.id),
    });
    const company = ref(
      {
        ...store.state.company.find(({ id }) => id === claim.value.companyId),
      } || {},
    );
    const attachments = ref([]);
    const attachmentsInput = ref(null);
    const changelogRefresh = ref(false);
    const changelog = ref([]);
    const companyVehicles = ref([]);

    const editComments = ref(false);

    async function refreshDocuments() {
      attachments.value = await store.dispatch("getClaimAttachmentsList", {
        companyId: company.value.id,
        claimId: claim.value.id,
      });
    }

    onMounted(() => store.dispatch("loadInitialResources", true));

    onMounted(async () => {
      claim.value = await store.dispatch("getClaim", {
        companyId: company.value.id,
        claimId: claim.value.id,
      });
      claim.value = {
        ...claim.value,
        incidentDate: moment
          .utc(claim.value.incidentDate)
          .local()
          .format("yyyy-MM-DDTHH:mm"),
      };

      changelog.value = await store.dispatch("getClaimChangelog", {
        companyId: company.value.id,
        claimId: claim.value.id,
      });

      const vehicles = await store.dispatch(
        "getVehiclesForCompany",
        company.value.id,
      );
      companyVehicles.value = vehicles.filter(({ companyId }) => {
        return companyId === company.value.id;
      });

      claim.value.companyId = company.value.id;
      refreshDocuments();
    });

    onMounted(() => {
      if (attachmentsInput.value) {
        attachmentsInput.value.addEventListener("change", async () => {
          const uploads = [];
          try {
            toast("Saving attachments.");
            Array.from(attachmentsInput.value.files).forEach((file) => {
              uploads.push(
                store.dispatch("saveClaimDocument", {
                  accountId: company.value.id,
                  claimId: claim.value.id,
                  file,
                }),
              );
            });
            await Promise.all(uploads);
            refreshDocuments();
            attachmentsInput.value.value = null;
            toast.clear();
            toast("Attachments saved.");
          } catch (err) {
            toast.clear();
            toast("Failed to save attachments.");
          }
        });
      }
    });

    watch(claim, () => {
      if (claim.value.companyId) {
        company.value =
          {
            ...store.state.company.find(
              ({ id }) => id === claim.value.companyId,
            ),
          } || {};
      }
    });

    const isApiClaim = computed(() => {
      return typeof claim.value.thirdParty === typeof [];
    });

    return {
      isApiClaim,
      carAccountType: process.env["VUE_APP_ACCOUNT_TYPE_CAR_RENTAL"],
      truckingAccountType: process.env["VUE_APP_ACCOUNT_TYPE_TRUCKING"],
      tpExpand: ref({}),
      attachments,
      attachmentsInput,
      adminFields: [
        { key: "tpaClaimId", label: "TPA Claim ID", type: "text" },
        { key: "claimAdjuster", label: "Claim Adjuster", type: "text" },
        { key: "amountSpent", label: "Amount Spent", type: "number" },
        { key: "amountPaid", label: "Amount Paid", type: "text" },
        { key: "resolution", label: "Resolution", type: "textarea" },
        { key: "lossType", label: "Type of Loss", type: "text" },
      ],
      changelog: computed(() => {
        return changelog.value
          .filter((change) => {
            if (change.fieldName === "thirdParty") {
              return !isApiClaim.value;
            }
            return true;
          })
          .map((change) => {
            return {
              id: change.id,
              change: getChangelogDescription(change),
              email: change.actor,
              created: moment(change.timestamp)
                .local()
                .format("yyyy-MM-DD h:mmA"),
            };
          });
      }),
      changelogRefresh,
      changelogColumns: [
        { name: "Change", id: "change" },
        { name: "Created By", id: "email" },
        { name: "Created", id: "created" },
      ],
      async refreshChangelog() {
        changelogRefresh.value = true;
        try {
          const logs = await store.dispatch("getClaimChangelog", {
            companyId: company.value.id,
            claimId: claim.value.id,
          });
          changelog.value = [];
          setTimeout(() => {
            changelog.value = logs;
            changelogRefresh.value = false;
          }, 1000);
        } catch (err) {
          toast("Failed to get changelogs.");
          setTimeout(() => (changelogRefresh.value = false), 1000);
        }
      },
      editComments,
      companyVehicles,
      companyDrivers: computed(() => {
        return store.state.driver.filter(({ companyId }) => {
          return companyId === company.value.id;
        });
      }),
      async saveComments() {
        toast("Saving comments.");
        try {
          claim.value = await store.dispatch("saveCommentsForClaim", {
            companyId: company.value.id,
            claimId: claim.value.id,
            comments: claim.value.comments,
          });
          toast.clear();
          toast("Comments saved.");
          editComments.value = false;
        } catch (err) {
          toast.clear();
          toast("Failed to save comments.");
        }
      },
      claimCreated: computed(() =>
        moment.utc(claim.value.created).format("yyyy-MM-DD"),
      ),
      claimCompany: company,
      claimStatusList: computed(() => {
        if (isApiClaim.value) {
          return ["Reported", "Open", "Closed", "Reopened"];
        }
        if (store.state.type === process.env["VUE_APP_ACCOUNT_TYPE_TRUCKING"]) {
          return ["Reported", "Open", "Closed"];
        }
        if (
          store.state.type === process.env["VUE_APP_ACCOUNT_TYPE_CAR_RENTAL"]
        ) {
          return ["Resolved", "Unresolved"];
        }
        return [];
      }),
      claimVehicle: computed(() => {
        if (claim.value.vehicle) {
          return companyVehicles.value.find(({ entityId }) => {
            return entityId === claim.value.vehicle;
          });
        }
        if (claim.value.vin) {
          return companyVehicles.value.find(({ companyId, vin }) => {
            return (
              companyId === company.value.id &&
              (vin || "").toLowerCase() ===
                (claim.value.vin || "").toLowerCase()
            );
          });
        }
        return null;
      }),
      claimDriver: computed(() => {
        if (claim.value.driver) {
          return store.state.driver.find(({ entityId }) => {
            return entityId === claim.value.driver;
          });
        }
        return null;
      }),
      claim,
      copyCustomerLink() {
        const hostname = window.location.origin.replace("paddocks", "app");
        navigator.clipboard.writeText(
          `${hostname}/insurance/claims/${claim.value.entityId}`,
        );
        toast("Customer vehicle link copied.");
      },
      saveClaim() {
        store
          .dispatch("saveClaim", {
            ...claim.value,
            incidentDate: moment(claim.value.incidentDate, "yyyy-MM-DDTHH:mm")
              .utc()
              .format(),
          })
          .then((res) => {
            toast("Claim saved");
            claim.value = { ...res, companyId: company.value.id };
            claim.value = {
              ...claim.value,
              incidentDate: moment
                .utc(claim.value.incidentDate)
                .local()
                .format("yyyy-MM-DDTHH:mm"),
            };
          })
          .catch(() => toast("Failed to save claim."));
      },
      async viewAttachment(attachment) {
        store.dispatch("logEvent", { name: "download_claim_document" });
        const url = await store.dispatch(
          "getFileDownloadUrl",
          attachment.fullPath,
        );
        window.open(url, "_blank");
      },
      async removeAttachment(attachment) {
        if (confirm("Are you sure you want to remove this attachment?")) {
          try {
            toast("Removing attachment.");
            await store.dispatch("removeDocument", {
              path: attachment.fullPath,
            });
            await refreshDocuments();
            toast.clear();
            toast("Attachment removed.");
          } catch (err) {
            toast.clear();
            toast("Failed to remove attachment.");
          }
        }
      },
    };
  },
};
</script>
