<template>
  <div class="w-full">
    <div class="w-full rounded-lg bg-white p-4 mt-8 m-auto md:w-1/2">
      <h2 class="text-gray-800 font-bold text-2xl">Open a new claim</h2>
      <form class="mt-2" @submit.prevent="createClaim">
        <div class="m-1">
          <label class="text-gray-800 font-bold"> Company </label>
          <br />
          <input
            :value="selectedCompany.businessLegalName"
            class="cursor-pointer"
            @click="selectedCompanyId = null"
            required
            v-if="selectedCompany"
          />
          <input
            list="company-list"
            v-model="selectedCompanyId"
            placeholder="Select a Company"
            required
            v-else
          />
          <datalist id="company-list">
            <option
              v-for="company in companies"
              :value="company.entityId"
              :key="company.entityId"
            >
              {{ company.businessLegalName }}
            </option>
          </datalist>
        </div>
        <div class="m-1" v-if="selectedCompany">
          <label class="text-gray-800 font-bold"> Vehicle </label>
          <br />
          <input
            :value="selectedVehicle.key || selectedVehicle.vin"
            class="cursor-pointer"
            @click="selectedVehicleId = null"
            v-if="selectedVehicle"
          />
          <input
            list="vehicle-list"
            v-model="selectedVehicleId"
            placeholder="Select a Vehicle"
            v-else
          />
          <datalist id="vehicle-list">
            <option
              v-for="vehicle in vehicles"
              :value="vehicle.entityId"
              :key="vehicle.entityId"
            >
              <span v-if="vehicle.key">
                {{ vehicle.key }}
              </span>
              <span v-else>
                {{ vehicle.vin }} {{ vehicle.year }} {{ vehicle.make }}
                {{ vehicle.model }}
              </span>
            </option>
          </datalist>
        </div>
        <div class="m-1" v-if="selectedCompany">
          <label class="text-gray-800 font-bold"> Driver </label>
          <br />
          <input
            :value="`${selectedDriver.firstName || ''} ${selectedDriver.lastName || ''}`"
            class="cursor-pointer"
            @click="selectedDriverId = null"
            v-if="selectedDriver"
          />
          <input
            list="driver-list"
            v-model="selectedDriverId"
            placeholder="Select a Driver"
            v-else
          />
          <datalist id="driver-list">
            <option
              v-for="driver in drivers"
              :value="driver.entityId"
              :key="driver.entityId"
            >
              {{ driver.firstName }} {{ driver.lastName }}
            </option>
          </datalist>
        </div>
        <div class="flex justify-end mt-2 flex-col md:flex-row">
          <button
            type="button"
            @click="$router.go(-1)"
            class="bg-gray-100 hover:bg-gray-200"
          >
            Cancel
          </button>
          <button
            type="submit"
            class="bg-lula-gradient text-white ml-0 mt-1 hover:bg-lula-gradient-alt md:ml-1 md:mt-0"
          >
            Create Claim
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";

export default {
  setup() {
    const store = useStore();
    const router = useRouter();
    const toast = useToast();

    const selectedCompanyId = ref(null);
    const selectedVehicleId = ref(null);
    const selectedDriverId = ref(null);

    const selectedCompany = computed(() => {
      return store.state.company.find(({ entityId }) => {
        return entityId === selectedCompanyId.value;
      });
    });

    const selectedDriver = computed(() => {
      return store.state.driver.find(({ entityId }) => {
        return entityId === selectedDriverId.value;
      });
    });

    const selectedVehicle = computed(() => {
      return store.state.vehicle.find(({ entityId }) => {
        return entityId === selectedVehicleId.value;
      });
    });

    return {
      selectedCompanyId,
      selectedCompany,
      companies: computed(() => {
        return store.state.company;
      }),

      selectedVehicleId,
      selectedVehicle,
      vehicles: computed(() => {
        return store.state.vehicle.filter(({ companyId }) => {
          return companyId === selectedCompany.value.id;
        });
      }),

      selectedDriverId,
      selectedDriver,
      drivers: computed(() => {
        return store.state.driver.filter(({ companyId }) => {
          return companyId === selectedCompany.value.id;
        });
      }),

      async createClaim() {
        toast("Creating claim.");
        try {
          const claim = await store.dispatch("saveClaim", {
            companyId: selectedCompany.value.id,
            vehicle: selectedVehicle.value.entityId,
            driver: selectedDriver.value.entityId,
            status: "Reported",
          });
          await store.dispatch("getClaims");
          toast.clear();
          toast("Claim created.");
          router.push({
            name: "Claim",
            params: { id: claim.id },
          });
        } catch (err) {
          toast.clear();
          toast("Failed to create claim.");
        }
      },
    };
  },
};
</script>
