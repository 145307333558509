import { initialize } from "launchdarkly-js-client-sdk";

export default {
  created() {
    this.initDarkly(process.env["VUE_APP_LAUNCHDARKLY_ID"]);
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  methods: {
    initDarkly(key) {
      if (this.$store.state.launchDarkly) {
        this.$store.state.launchDarkly.identify(this.getDarkUser());
      } else {
        this.$store.commit("setState", {
          key: "launchDarkly",
          value: initialize(key, this.getDarkUser()),
        });
      }
    },
    getDarkUser() {
      const sub = this.user["sub"];
      const email = this.user["email"];

      if (sub != null && email != null) {
        return {
          key: sub,
          email,
        };
      }
      return {
        anonymous: true,
      };
    },
    getLaunchDarklyFlag(flag) {
      return this.$store.state.launchDarkly?.variation(flag, false);
    },
  },
};
