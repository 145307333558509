<template>
  <div>
    <div ref="claimsFormRef" />
    <div
      :class="{ hidden: claimsFormLoaded }"
      class="bg-white rounded border p-4 m-8"
    >
      <div class="bg-gray-100 rounded h-screen animate-pulse" />
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { useToast } from "vue-toastification";
import { getAccessToken } from "@/firebase";
import {
  initializeShadowDom,
  fetchFrontend,
  mountFrontend,
} from "@getaddify/lula-components";

function getFormMode(type, isClaimTeamMember, policyId = "") {
  const prefix = isClaimTeamMember ? "" : "limited_";
  switch (type) {
    case process.env["VUE_APP_ACCOUNT_TYPE_TRUCKING"]: {
      return prefix + "admin_trucks";
    }
    case process.env["VUE_APP_ACCOUNT_TYPE_CAR_RENTAL"]:
    default: {
      if (policyId.includes("bbf38936-2383-4c2b-918a-3d8627e396b5"))
        return prefix + "admin_orp_cars";
      return prefix + "admin_cars";
    }
  }
}

function getAdjusterJson(type, settings = []) {
  switch (type) {
    case process.env["VUE_APP_ACCOUNT_TYPE_TRUCKING"]: {
      const setting = settings.find(
        (setting) => setting.key === "truckingadjusters",
      );
      return setting ? setting.value : null;
    }
    case process.env["VUE_APP_ACCOUNT_TYPE_CAR_RENTAL"]:
    default: {
      const setting = settings.find(
        (setting) => setting.key === "carsadjusters",
      );
      return setting ? setting.value : null;
    }
  }
}

function getAdjusterCodesJson(type, settings = []) {
  switch (type) {
    case process.env["VUE_APP_ACCOUNT_TYPE_TRUCKING"]: {
      const setting = settings.find(
        (setting) => setting.key === "truckingadjustercodes",
      );
      return setting ? setting.value : null;
    }
    case process.env["VUE_APP_ACCOUNT_TYPE_CAR_RENTAL"]:
    default: {
      const setting = settings.find(
        (setting) => setting.key === "carsadjustercodes",
      );
      return setting ? setting.value : null;
    }
  }
}

export default {
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const toast = useToast();

    const claimsFormLoaded = ref(false);
    const claimsFormRef = ref(null);

    onMounted(async () => {
      if (!claimsFormRef.value) {
        return;
      }
      const isClaimsAdmin = await store.dispatch(
        "getLaunchDarklyFlag",
        "admin.claims",
      );
      const company = await store.dispatch("getTenant", route.params.tenant);
      const accountRoute = router.resolve({
        name: "Company",
        params: { id: company.documentId },
      });
      const vehicles = await store.dispatch(
        "getVehiclesForCompany",
        company.documentId,
      );
      const drivers = await store.dispatch(
        "getDriversForCompany",
        company.documentId,
      );
      const settings = await store.dispatch("getSystemSettings");
      const [formShadow, formRoot] = initializeShadowDom(claimsFormRef.value);
      await fetchFrontend(formShadow, process.env["VUE_APP_CLAIMS_UI_URL"]);
      mountFrontend("claims-form", formRoot, {
        environment: process.env["VUE_APP_ENVIRONMENT"],
        userId: store.state.user?.sub,
        userEmail: store.state.user?.email,
        launchDarklyApiKey: process.env["VUE_APP_LAUNCHDARKLY_ID"],
        graphUrl: process.env["VUE_APP_CLAIMS_GRAPH"],
        graphHeaders: {
          Authorization: `Bearer ${await store.dispatch("getIdentityToken")}`,
        },
        mode: getFormMode(
          store.state.type,
          isClaimsAdmin,
          company.defaultPolicy || "",
        ),
        mapsApiKey: process.env["VUE_APP_GOOGLE_MAPS_APIKEY"],
        tenantId: route.params.tenant,
        claimId: route.params.id === "new" ? null : route.params.id,
        adjustersJson: getAdjusterJson(store.state.type, settings),
        adjusterCodesJson: getAdjusterCodesJson(store.state.type, settings),
        onMounted: () => (claimsFormLoaded.value = true),
        showMessage(message, error = false) {
          toast.clear();
          if (error) {
            toast.error(message);
          } else {
            toast(message);
          }
        },
        onClaimSubmit(claim) {
          router.replace({
            name: "Claim",
            params: {
              tenant: company.entityId,
              id: claim.id,
            },
          });
        },
        onViewVehicle(vehicle) {
          const vehicleLink = router.resolve({
            name: "Vehicle",
            params: {
              companyId: company.documentId,
              vehicleId: vehicle.id,
            },
          });
          window.open(vehicleLink.href, "_blank");
        },
        onPolicyClicked(policyId) {
          router.push({
            name: "Policy",
            params: { policyId: policyId },
          });
        },
        accountLink: accountRoute?.href,
        account: company,
        vehicles,
        drivers,
      });
    });

    return {
      claimsFormLoaded,
      claimsFormRef,
    };
  },
};
</script>
